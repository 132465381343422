import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styled, { useTheme } from 'styled-components'
import IFTypography from 'componentsV2/IFTypography'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Button, useMatchBreakpoints } from 'uikit'
import { CompanyContext } from 'contexts/CompanyContext'
import Backdrop from '@mui/material/Backdrop'

const ModalBox = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.modal.background};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 90vw;
  border-radius: 20px;
  box-sizing: border-box;
  max-height: 70vh;
  outline: none;
`

const ModalBoxMobile = styled.div`
  position: absolute;
  backdropfilter: 0;
  background: ${({ theme }) => theme.modal.background};
  top: auto;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: translateX(-50%)};
  width: 100%;
  height: 50%;
  border-radius: 20px 20px 0 0;
 padding: 30px 20px 30px 20px;
 outline: none;
`

const NodesBox = styled(Box)`
  background: ${({ theme }) => theme.colorsV2.dark};
  width: 100%;
  border-radius: 10px;
  padding: 20px;
`

const ModalContent = ({
  handleClose,
  isMobile,
  purchasedNodesCount,
  purchasedNodes,
}: {
  isMobile: boolean
  handleClose: () => void
  purchasedNodesCount: number
  purchasedNodes: any
}) => {
  const theme = useTheme()
  return (
    <Box margin={isMobile ? null : '30px 30px 40px 30px'}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IFTypography variant={isMobile ? 'h6' : 'h3'} fontWeight="bold">
          Purchased nodes
        </IFTypography>
        <IconButton variant="text" onClick={() => handleClose()}>
          <CloseIcon sx={{ fontSize: '34px' }} />
        </IconButton>
      </Box>

      <NodesBox
        sx={{
          mt: '16px',
          mb: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IFTypography fontSize={isMobile ? '12px' : '14px'} color={theme.colorsV2.textDisabled}>
          Total purchased
        </IFTypography>
        <IFTypography
          fontSize={isMobile ? '24px' : '32px'}
          color={purchasedNodesCount ? theme.colorsV2.text : theme.colorsV2.textDisabled}
        >
          {purchasedNodesCount ? purchasedNodesCount : '-'} Node&#40;s&#41;
        </IFTypography>
      </NodesBox>

      {!purchasedNodesCount ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <IFTypography fontSize="14px" color={theme.colorsV2.textDisabled}>
            Once you&apos;ve purchased a node, it will appear here.
          </IFTypography>
        </Box>
      ) : (
        <Box sx={{ overflowY: 'auto', maxHeight: isMobile ? '15vh' : '40vh' }}>
          {purchasedNodes.map((obj) => (
            <Box display="flex" justifyContent="space-between" alignItems="end" mb={5} px={5}>
              <IFTypography fontSize="14px" color={theme.colorsV2.text}>
                {obj.tier}
              </IFTypography>

              <IFTypography fontSize="14px" color={theme.colorsV2.text}>
                x{obj.amountPurchased}
              </IFTypography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
export default function ModalNodes({
  open,
  setOpenModalNodes,
  purchasedNodesCount,
  purchasedNodes,
}: {
  open: boolean
  setOpenModalNodes: (e: boolean) => void
  purchasedNodesCount: number
  purchasedNodes: any
}) {
  const { isJustSale } = useContext(CompanyContext)

  const handleClose = () => {
    setOpenModalNodes(false)
  }

  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false

  return (
    <Modal
      components={{ Backdrop }}
      componentsProps={{
        backdrop: {
          sx: {
            backgroundColor: isJustSale ? 'rgba(101,72,35, 0.35)' : '',
            backdropFilter: isJustSale ? 'blur(8px)' : '',
          },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      {isMobile ? (
        <ModalBoxMobile>
          <ModalContent
            handleClose={handleClose}
            isMobile={isMobile}
            purchasedNodesCount={purchasedNodesCount}
            purchasedNodes={purchasedNodes}
          />
        </ModalBoxMobile>
      ) : (
        <ModalBox>
          <ModalContent
            handleClose={handleClose}
            isMobile={isMobile}
            purchasedNodesCount={purchasedNodesCount}
            purchasedNodes={purchasedNodes}
          />
        </ModalBox>
      )}
    </Modal>
  )
}
