import { useTranslation } from 'contexts/Localization'
import { Box } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import styled from 'styled-components'
import { Chain } from 'config/constants/types'
import networkParams from 'config/constants/networkParams'
import { Brands, CompanyContext } from 'contexts/CompanyContext'
import { Flex, Checkbox } from 'uikit'

const Content = styled.div`
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries?.md} {
    margin-bottom: 84px;
  }
`

const ScrollableContent = styled.div`
  overflow-y: auto;
  max-height: calc(50vh - 103px);
  ${({ theme }) => theme.mediaQueries?.md} {
    max-height: calc(50vh - 107px);
  }
  margin-bottom: 20px;
`

const Modal = styled(ModalV2)`
  span {
    text-transform: unset;
  }

  & > div:last-child {
    overflow-y: auto;
    max-height: calc(80vh - 83px);

    ${({ theme }) => theme.mediaQueries?.md} {
      max-height: calc(80vh - 87px);
    }

    & > div:first-child {
      max-height: unset;
    }

    & > div:last-child {
      position: absolute;
      bottom: 0;

      padding: 40px 0;
      background: ${({ theme }) => theme.modal.background};
      width: calc(100% - 40px);

      ${({ theme }) => theme.mediaQueries?.md} {
        width: calc(100% - 80px);
      }
    }
  }
`

interface PurchaseCardDisclaimerProps {
  onDismiss?: VoidFunction
  purchaseHandler: VoidFunction
  saleID: string
  chainID: Chain
}

export const localStorageKey = (saleID: string) => `DISCLAIMER_AGREED_${saleID}`

const PurchaseCardDisclaimer: React.FC<PurchaseCardDisclaimerProps> = ({
  onDismiss,
  purchaseHandler,
  saleID,
  chainID,
}) => {
  const { t } = useTranslation()
  const { brand, mainUrl, isJustSale } = useContext(CompanyContext)
  const onCanfirm = useCallback(() => {
    localStorage.setItem(localStorageKey(saleID), 'true')
    purchaseHandler()
  }, [purchaseHandler, saleID])

  const isAgreed = localStorage.getItem(localStorageKey(saleID)) != null
  if (isAgreed) {
    purchaseHandler()
    return null
  }

  const [termsChecked, setTermsChecked] = useState(false)

  const chainName = networkParams[chainID]?.[0]?.chainName || 'chain'

  return (
    <Modal
      title={t(`${brand === Brands.MOONVEIL ? 'Moonveil Entertainment Node Purchase Disclaimer' : 'Disclaimer'}`)}
      confirmText={brand === Brands.SOPHON ? t('Continue') : t('Agree')}
      showConfirm
      onDismiss={onDismiss}
      onConfirm={onCanfirm}
      enableConfirm={brand === Brands.SOPHON ? termsChecked : true}
    >
      <Content>
        {brand === Brands.MOONVEIL && (
          <IFTypography variant="button" lineHeight="20px" ifcolor="text">
            PLEASE READ THIS DISCLAIMER CAREFULLY. BY ACCESSING MOONVEIL ENTERTAINMENT PLATFORM OR PURCHASING NODES, YOU
            ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH
            BELOW. THIS DISCLAIMER LIMITS THE LIABILITY OF MOONVEIL ENTERTAINMENT AND ITS AFFILIATES TO THE MAXIMUM
            EXTENT PERMITTED BY APPLICABLE LAW.
            <br></br>
            <br></br>
            1. General Information.
            <br></br>
            Moonveil Entertainment operates a decentralized platform that allows users to purchase and manage nodes,
            contributing to the functionality and expansion of the Moonveil Entertainment ecosystem. The nodes offered
            are based on open-source or source-available software, including smart contracts deployed on the relevant
            blockchain network (Polygon PoS and Arbitrum One). Your use of Moonveil Entertainment platform and the
            purchase of nodes involves inherent risks, including, but not limited to, the potential loss of your entire
            investment and significant fluctuations in the value of digital assets associated with the nodes.<br></br>
            <br></br>
            2. No Legal, Financial, or Tax Advice.<br></br>
            The content provided on Moonveil Entertainment platform is for informational purposes only and does not
            constitute legal, financial, business, or tax advice. You are strongly advised to consult with your own
            legal, financial, tax, or other professional advisors before engaging in any node purchase or related
            activities. Moonveil Entertainment and its affiliates shall not be held liable for any decisions you make
            based on the information provided on this platform.<br></br>
            <br></br>
            3.Investment Risks.<br></br>
            Purchasing nodes is highly speculative and involves substantial risks. There is no assurance that the nodes
            will retain their value, be liquid, or be tradable on any secondary market. Moonveil Entertainment makes no
            guarantees regarding the future performance, marketability, or value of any node. Factors beyond the control
            of Moonveil Entertainment, including market volatility and regulatory changes, may significantly impact the
            value of nodes.<br></br>
            <br></br>
            4. Regulatory Compliance. <br></br>
            It is solely your responsibility to ensure that your participation in node purchases complies with all
            applicable laws, regulations, and legal requirements in your jurisdiction. Moonveil Entertainment disclaims
            any liability arising from your failure to comply with such laws and regulations.<br></br>
            <br></br>
            5. No Warranty.<br></br>
            All nodes are provided by Moonveil Entertainment &ldquo;as-is&ldquo; and without any warranties, either
            express or implied. Moonveil Entertainment disclaims all liability for any losses or damages resulting from
            your use of the platform or your purchase of nodes. Moonveil Entertainment does not guarantee that the
            platform will be available, secure, or error-free at all times.<br></br>
            <br></br>
            6. Limitation of Liability.<br></br>
            To the fullest extent permitted by law, Moonveil Entertainment, its affiliates, and service providers shall
            not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from
            or related to your use of the platform, including but not limited to any loss of investment, profits, or
            data, even if Moonveil Entertainment has been advised of the possibility of such damages..<br></br>
            <br></br>
            7. Eligibility and Geographic Restrictions.<br></br>
            By accessing or using the Moonveil Entertainment platform and purchasing nodes, you represent and warrant
            that you are at least eighteen (18) years of age, and that you are not a resident, citizen, or national of
            any restricted or prohibited territory. Restricted and prohibited territories include, but are not limited
            to: the United States, Cuba, Iran, Syria, Crimea, PRC, DPRK, DPR, LPR, and any other regions, countries, or
            jurisdictions subject to international sanctions, restrictions, or export controls imposed by the United
            Nations, the United States Department of the Treasury{`'`}s Office of Foreign Assets Control (OFAC), the
            European Union, or other relevant governmental authorities. Any attempt to access or use the platform from a
            restricted or prohibited territory is strictly forbidden. This includes but is not limited to the use of
            technologies, such as virtual private networks (VPNs), proxy servers, or other methods intended to conceal
            or misrepresent your true location or identity.<br></br>
            <br></br>
            8. No Ownership Rights.<br></br>
            Nodes purchased on the Moonveil Entertainment platform do not confer any ownership rights, voting rights, or
            entitlements to dividends or other distributions from Moonveil Entertainment or its affiliates. Nodes are
            not securities, and purchasing nodes should not be construed as an investment in Moonveil Entertainment or
            any of its associated entities.<br></br>
            <br></br>
            9. Future Performance <br></br>
            Moonveil Entertainment makes no representations, warranties, or promises regarding the future performance,
            value, or utility of the nodes. The distribution or sale of nodes does not constitute an offer to sell
            securities or any form of investment.<br></br>
            <br></br>
            10. No Refunds.<br></br>
            All node purchases are final. No refunds, exchanges, or cancellations will be permitted once a transaction
            is completed.<br></br>
            <br></br>
            11. Languages. <br></br>
            This disclaimer may be translated into multiple languages for reference purposes. In the event of any
            conflict or ambiguity between the English version and any translated version, the English version shall
            prevail.<br></br>
            <br></br>
            12.No Distribution. <br></br>
            No part of this disclaimer or any content on the Moonveil Entertainment platform may be copied, reproduced,
            distributed, or disseminated in any way without the prior written consent of Moonveil Entertainment.
            <br></br>
            <br></br>
            <br></br>
            By proceeding with any node purchase with Moonveil Entertainment, you acknowledge that you have read,
            understood, and agreed to this disclaimer. If you do not agree with any part of this disclaimer, you should
            not use the platform or purchase nodes.
          </IFTypography>
        )}
        {brand === Brands.CARV && (
          <IFTypography variant="button" lineHeight="20px" ifcolor="text">
            Terms of Use (Node) Please read this Node Software Agreement (this “Agreement”) carefully before using the
            Carv network (the “Network”) and its checker node (the “Node”), which comprises of the distributed software
            needed to help run the Network (“Node Software”) and the node key (“Key”), as further defined below. By
            accessing, operating, or otherwise using (collectively, “use” or “using”) the Node Software or purchasing,
            accessing or using the Node or the Key, you agree that you have read, understood and accepted all of the
            terms and conditions contained in this Agreement by and between you and Gemi Foundation Ltd (the “Company”
            or “we”). If you do not agree with all or any part of the terms and conditions of this Agreement, please do
            not use the Node Software. THE NODE SOFTWARE IS NOT AVAILABLE FOR ACCESS OR USE, AND THE KEY AND NODE
            SOFTWARE ARE NOT AVAILABLE FOR PURCHASE, ACCESS OR USE, BY ANY RESIDENT OF OR ANY PERSON LOCATED OR
            DOMICILED IN THE UNITED STATES, NORTH KOREA, IRAN, VENEZUELA, OR ANY OTHER JURISDICTIONS SANCTIONED BY THE
            U.S. DEPARTMENT OF THE TREASURY’S OFFICE OF FOREIGN ASSETS CONTROL (“OFAC”). PLEASE BE AWARE THAT SECTION 8
            (DISPUTE RESOLUTION; AGREEMENT TO ARBITRATE) GOVERNS THE RESOLUTION OF DISPUTES BETWEEN YOU AND THE
            FOUNDATION. SECTION 8 INCLUDES AN AGREEMENT TO ARBITRATE WHICH REQUIRES, WITH LIMITED EXCEPTIONS, THAT ALL
            DISPUTES BETWEEN YOU AND US SHALL BE RESOLVED BY BINDING AND FINAL ARBITRATION. SECTION 8 ALSO CONTAINS A
            CLASS ACTION AND JURY TRIAL WAIVER. PLEASE READ SECTION 8 CAREFULLY. THIS AGREEMENT MAY BE AMENDED FROM TIME
            TO TIME IN ACCORDANCE WITH SECTION 10.3 AND IF AT ANY TIME YOU DO NOT AGREE WITH ALL OR ANY PART OF THE THEN
            EFFECTIVE TERMS AND CONDITIONS, YOUR SOLE RECOURSE IS TO STOP USING THE NODE. Eligibility Requirements.
            General. To be eligible to access or use the Node Software or purchase, access or use a Node, you must
            satisfy each of the following eligibility requirements: 1.1.1 You are at least eighteen (18) years of age,
            or are the legal age for entering legally binding agreements under applicable law; 1.1.2 You are not, nor
            are you an entity that is, or an entity owned or controlled by any person or entity that is, or conducting
            any activities itself or on behalf of any person or entity that is: (i) a natural person resident in the
            United States; (ii) a partnership or corporation organised or incorporated under the laws of the United
            States; or (iii) otherwise a “U.S. person” as defined in Rule 902(k)(2) of Regulation S under the Securities
            Act of 1933 (each, a “U.S. Person”); 1.1.3 You are not, nor are you an entity that is, or an entity owned or
            controlled by any person or entity that is, or conducting any activities itself or on behalf of any person
            or entity that is: (x) the subject of any sanctions administered or enforced by OFAC (including, for the
            avoidance of doubt, any individual or entity included on OFAC’s Specially Designated Nationals and Blocked
            Persons List), the U.S. Department of State or any other governmental authority with jurisdiction over the
            party; (y) identified on the Denied Persons, Entity or Unverified Lists of the U.S. Department of Commerce’s
            Bureau of Industry and Security; or located, organized or (z) resident in a country or territory that is, or
            whose government is, the subject of economic sanctions, including, without limitation, Russia, Cuba, Iran,
            North Korea Syria or the Crimea, Donetsk People’s Republic, and Luhansk People’s Republic regions of Ukraine
            (each, a “Restricted Person”); and 1.1.4 You are not purchasing, obtaining, accessing or using the Node
            Software on behalf of a U.S. Person or Restricted Person. 1.2 Identity Verification. You will be required to
            provide information to us for the purposes of identity verification before you will be able to claim and
            take possession of any Network cryptographic tokens, i.e., “ATH tokens” (“Tokens”) earned as “Network
            Rewards” (defined below) in connection with your use of the Node. If we are unable to verify your identity
            and confirm that you are eligible to use the Node Software, you will not be permitted to use the Node
            Software, including to earn any Network Rewards. You agree to provide us with the information we request for
            purposes of identity verification and permit us to keep a record of such information. You further represent
            and agree that all such information is complete and accurate and that you will immediately notify the
            Foundation in the event that any information provided to the Foundation during this process is no longer
            complete or accurate. Node Software. 2.1 Use of Node. Subject to the terms and conditions of this Agreement,
            you may use the Node Software to operate a Network Node on one or more machines that you own or control. The
            Network is a network that operates on top of a blockchain network. As a “Node Operator,” you shall help
            ensure the integrity and performance of the “Containers” in the Network. A “Container” is a virtual endpoint
            performing tasks such as validating transactions and rendering content. You hereby acknowledge and agree
            that: the Foundation has no control over your activities and all validation activities are done solely in
            your own discretion as a node operator for the benefit of the Network and its users and participants; Any
            transactions that are confirmed via validation activities are completed exclusively under the Network’s
            decentralized consensus mechanism, and that there are no guarantees or protections with regards to the
            likelihood of any transaction being verified or confirmed on the Network; The Network is decentralized and
            neither you nor any other party has unilateral control over the Network and the transactions conducted
            thereon; You shall be solely responsible for comply with all applicable laws in connection with your role as
            a Network Node operator. 2.2 Node Key Requirement. You must acquire a Node Key from the Foundation In order
            to run a Checker Node and earn Network Rewards (defined below). The Node is a non-transferable non-fungible
            token. You will be required to provide information to the Foundation for purposes of verifying your identity
            and confirming that you are eligible to earn Network Rewards in order to claim and take possession of any
            Tokens earned as Network Rewards. 2.3 Connecting a Digital Wallet. In order to operate a Node using the Node
            Software, you will need to connect a compatible third-party digital wallet (“Wallet”) compatible with the
            Ethereum Virtual Machine (“EVM”). You are solely responsible for maintaining the security of your Wallet,
            including any associated credentials, private key and seed phrase. The Foundation does not offer Wallet
            software or custody Tokens (or any other crypto assets) on behalf of Node Software users. Your use of a
            third-party Wallet is subject to separate terms and conditions established by the relevant Wallet provider.
            the Foundation shall not be liable for any acts or omissions by you in connection with your Wallet or as a
            result of any data breach, cyber incident, or security vulnerability that impacts your Wallet or your
            hardware and software used to access the same. 2.4 Network Rewards. You may receive units of Tokens for
            running the Nodes to contribute to the integrity and performance of the Containers in the Network (“Network
            Rewards”). All such Network Rewards, including their rate of accrual and any award of bonus rewards, are
            programmatically set and distributed to Nodes by the Network. The Foundation is not responsible or liable
            for any Network Rewards (if applicable) that result from your use of the Node Software. 2.5 Expiration of
            Network Rewards. Subject to the terms set forth herein, Network Rewards can be claimed at any time. You will
            be required to provide information to the Foundation for purposes of verifying your identity and confirming
            your eligibility to earn Network Rewards before you will be permitted to claim and take possession of any
            ATH Tokens earned as Network Rewards. Carv may impose an expiration to the tokens whereby you will have a
            certain number of days from the date that you earn a Network Reward to claim the ATH tokens earned as part
            of the Network Reward before the Network Reward expires, and any such expiration may be communicated to you
            via electronic transmission, by posting on the Website or through an amendment or modification of these
            Terms. You should check these Terms from time to time for any changes to these terms. 2.6 Node Software
            Updates. The Node Software may automatically download or prompt you to download and install updates onto
            your machine from time to time. By accessing or using the Node Software, you agree that the Foundation may
            download and install automatic Node Software updates onto your machine. You may opt out of automatic updates
            by adjusting your Node Software settings. License Uses and Restrictions. 3.1 Node Software License. Subject
            to the terms and conditions of this Agreement, the Foundation hereby grants to you a non-exclusive,
            revocable, non-transferable, non-sublicensable, royalty-free, limited license to install, use and run the
            Node Software on one or more machines that you own or control. Except for the license expressly granted to
            you hereunder, no other license is granted and no other use is permitted. 3.2 Third-Party Licenses.
            Notwithstanding anything to the contrary in this Agreement, the Node Software may contain software
            components released under separate open-source or business-source license terms, in which case those license
            terms will govern such software components. 3.3 Feedback. With respect to any feedback you provide to the
            Foundation (whether orally or in writing) concerning the Node, including any features or functionalities
            thereof, and including identifying potential errors and improvements (“Feedback”), you hereby grant to the
            Foundation an exclusive, worldwide, perpetual, fully paid-up, royalty free, fully-sublicensable (through
            multiple tiers of sublicensees) and freely assignable and transferable license to use any Feedback for any
            purpose without payment or restriction. It is further understood that the Foundation’s use of Feedback, if
            any, may be made by the Foundation in its sole discretion, and that the Foundation shall in no way be
            obliged to make any payment to you for or make use of any kind of the Feedback or part thereof. 3.4 Use of
            Trademarks and Other Marks or Rights. You may not use any of our trademarks, trade names, service marks,
            copyrights, or logos, or our partners’, affiliated entities’, licensors’, or their licensors’ trademarks,
            trade names, service marks, copyrights, or logos, including but not limited to “Carv”, in any manner which
            creates the impression that such items (i) belong to or are associated with you or indicate the sponsorship
            or approval of us, our licensors, any partners, affiliates, or their licensors; or (ii) except as otherwise
            provided herein, are used with our licensors’, partners’, affiliates’, or their licensors’ consent, and you
            acknowledge that you have no ownership rights in or to any such items. Prohibited Activities. You shall not
            engage in any activities that negatively affect the technical performance of the Node Software and/or
            Network, bypass or circumvent security features of the Node Software and/or Network, or otherwise disrupt or
            interfere with the functioning of the Node Software and/or Network. You shall not violate or attempt to
            violate the security of the Node Software and/or Network or otherwise misuse the foregoing, including by,
            (i) accessing data not intended for you or logging onto a server or an account which you are not authorized
            to access; (ii) disabling, removing, defeating or avoiding any security device or system; (iii) attempting
            to probe, scan or test the vulnerability of the Node Software and/or Network or to breach security or
            authentication measures without proper authorization; (iv) attempting to interfere with service to any
            sentry node operator, host or network, including, but not limited to, via means of submitting any malware or
            computer programming routines that may damage, disrupt or interfere with, intercept or expropriate any
            system or data, overloading, “flooding,” “spamming,” “mailbombing” or “crashing” the Node Software and/or
            Network; (v) forging any transmission control protocol/Internet protocol packet header or any part of the
            header information in any email or posting; (vi) using the Node Software and/or Network in a manner that
            exceeds reasonable request volume or constitutes excessive or abusive usage; or (vii) providing false,
            misleading or inaccurate information to the Network. 3.5 You shall not, directly or indirectly: (i) use the
            Node Software or any portion thereof to create any service, software, product, platform, documentation or
            data that is similar to, in whole or in part, any aspect of the services or products offered by the
            Foundation, including without limitation, the Node Software or Network; (ii) disassemble, decompile, reverse
            engineer or use any other means to attempt to discover any source code of the Node Software, or the
            underlying ideas, file formats, algorithms or trade secrets therein; (iii) encumber, sublicense, transfer,
            rent, lease, time-share or use the Node Software in any service bureau arrangement or otherwise for the
            benefit of any third party; (iv) copy, distribute, manufacture, adapt, create derivative works of,
            translate, localize, port or otherwise modify any software code or documentation for the Node Software; (v)
            use or allow the transmission, transfer, export, re-export or other transfer of any product, technology or
            information it obtains or learns pursuant to this Agreement (or any direct product thereof) in violation of
            any export control or other laws and regulations of any relevant jurisdiction; (vi) knowingly introduce into
            the Node Software and/or Network any malicious code, computer virus, spyware, scareware, Trojan horses,
            worms, malware or any other similar harmful, malicious or hidden programs or data; (vii) remove or modify
            any proprietary markings or restrictive legends placed on the Node Software; (viii) use the Node Software to
            infringe upon, violate or misappropriate any third party’s intellectual property rights, violating any law
            or regulation or being defamatory, trade libelous, threatening or harassing; or (ix) authorize or permit any
            third party to engage in any of the foregoing proscribed acts. For the avoidance of doubt, the restrictions
            set forth in this Section are in addition to, and in no way limit, any other restrictions or obligations
            applicable to you set forth in this Agreement. 3.6 You shall not use the Node Software and/or Network to
            engage in illegal activity of any kind, including, without limitation, any activity that would violate, or
            assist in violation of, any law, statute, ordinance, regulation or sanctions programs administered under any
            applicable law, including but not limited to the U.S. Department of Treasury’s Office of Foreign Assets
            Control or which would involve proceeds of any unlawful activity. Risks. You acknowledge that the Node
            Software and Network incorporate experimental and novel technology and that the use of such technology
            involves a high degree of risk. For example, there are numerous reasons the Node Software or Network could
            fail in an unexpected way, resulting in the total and absolute loss of your Tokens. You hereby agree that
            you assume all risks in connection with your use of the Node Software and Network and expressly waive and
            release the Foundation from any and all liability, claims, causes of action or damages arising out of or in
            any way relating to you obtaining or using Node Software and Network. You understand and accept the risk of
            operational challenges related to the Node Software and Network. For example, the Network may experience
            cyber-attacks, unexpected surges in transaction volume or activity or other operational or technical
            difficulties or vulnerabilities that may cause interruptions related to your use of the Node Software or
            Network. You agree to accept the risk of Node Software or Network failure resulting from unanticipated or
            heightened technical difficulties or vulnerabilities, including those resulting from cyber-attacks. You
            agree not to hold the Foundation accountable for any claims, damages, liabilities, costs, and expenses you
            may incur, directly or indirectly, in connection with or related to your use of the Node. You agree that the
            Foundation is not responsible for any Tokens or other crypto assets that you receive, transfer, hold, stake,
            lose or otherwise use or misuse in connection with the Node Software or the Network. Legal and regulatory
            requirements applicable to use of the Node Software may vary from jurisdiction to jurisdiction. You
            acknowledge and agree that you are solely responsible for evaluating the legality of using the Node Software
            in your jurisdiction. The Foundation is not responsible for determining whether or which laws and
            regulations may apply to you or your use of the Node Software. 4.1 Disclaimer of Warranties; Limitation of
            Liability. EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE NODE SOFTWARE AND NETWORK ARE ISSUED ON AN “AS-IS” AND
            “AS AVAILABLE” BASIS AND the Foundation DOES NOT MAKE ANY WARRANTIES WITH RESPECT TO SUCH “AS-IS” AND “AS
            AVAILABLE” BASIS OR OTHERWISE IN CONNECTION WITH THIS AGREEMENT (EXCEPT AS EXPRESSLY PROVIDED HEREIN) AND
            the Foundation HEREBY DISCLAIMS ANY AND ALL EXPRESS, IMPLIED OR STATUTORY WARRANTIES AND CONDITIONS,
            INCLUDING ANY WARRANTIES OR CONDITIONS OF NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, AVAILABILITY, ERROR-FREE OR UNINTERRUPTED OPERATION, AND ANY WARRANTIES ARISING FROM A COURSE OF
            DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE. TO THE EXTENT THAT the Foundation MAY NOT, AS A MATTER OF
            APPLICABLE LAW, DISCLAIM ANY IMPLIED WARRANTY OR CONDITION, THE SCOPE AND DURATION OF SUCH WARRANTY OR
            CONDITION SHALL BE APPLIED TO THE MINIMUM EXTENT PERMITTED UNDER SUCH APPLICABLE LAW. IN NO EVENT SHALL the
            Foundation BE LIABLE TO YOU FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL OR SPECIAL DAMAGES OF ANY TYPE OR
            NATURE HOWEVER ARISING, INCLUDING, WITHOUT LIMITATION, EXEMPLARY OR PUNITIVE DAMAGES, LOST PROFITS OR
            REVENUES OR DIMINUTION IN VALUE, ARISING OUT OF OR RELATING TO THE NODE SOFTWARE OR THE NETWORK, WHETHER OR
            NOT THE POSSIBILITY OF SUCH DAMAGES HAS BEEN DISCLOSED TO OR COULD HAVE BEEN REASONABLY FORESEEN BY YOU,
            REGARDLESS OF THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS BASED. IN
            ADDITION, UNDER NO CIRCUMSTANCES SHALL THE FOUNDATION’S AGGREGATE LIABILITY UNDER THE AGREEMENT EXCEED
            ONE-HUNDRED U.S. DOLLARS ($100.00). SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
            THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.ACCORDINGLY, SOME OF THE
            LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU.IF YOU ARE DISSATISFIED WITH THE NODE SOFTWARE, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE NODE SOFTWARE. 4.2Indemnification. You agree, at your own
            expense, to indemnify, defend and hold harmless the Foundation and its partners and affiliates and their
            respective owners, members, agents, directors, officers, employees, representatives, affiliates, successors
            and assigns against any claim, suit, action, or other proceeding from and against any and all claims,
            damages, liabilities, costs, and expenses, including reasonable attorneys’ and experts’ fees, arising out of
            or in connection with your breach of the Agreement, your violation of any law or regulation or your use of
            the Node Software. You agree to pay any and all costs, damages and expenses, including but not limited to
            reasonable attorneys’ fees and costs awarded against or otherwise incurred by or in connection with or
            arising from any such claim, suit, action, or proceeding attributable to any such claim. the Foundation
            reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise
            subject to indemnification by you, in which event you will fully cooperate with the Foundation in asserting
            any available defense. If you have a dispute with one or more users of the Node Software or other third
            parties, you release the Foundation (and its affiliates and service providers, and each of their officers,
            directors, agents, joint ventures, employees and representatives) from all claims, demands and damages
            (actual and consequential) of every kind and nature arising out of or in any way connected with such
            disputes. Dispute Resolution; Agreement to Arbitrate. 5.1 All disputes, claims and controversies, whether
            based on past, present or future events, arising out of or relating to statutory or common law claims, the
            breach, termination, enforcement, interpretation or validity of any provision of the Agreement, and the
            determination of the scope or applicability of your agreement to arbitrate any dispute, claim or controversy
            originating from the Agreement, will be determined by binding arbitration in Singapore pursuant to the
            Arbitration Rules of the Singapore International Arbitration Centre (“SIAC Rules”) for the time being in
            force, which rules are deemed to be incorporated by reference in this clause, before a single arbitrator.
            The language of the arbitration shall be English. 5.2 The arbitrator will apply the substantive law of the
            Singapore, excluding its conflict or choice of law rules. 5.3 Nothing in the Agreement will preclude the
            parties from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction.
            5.4 A party must notify the other party of its intent to commence arbitration prior to commencing
            arbitration. The notice must specify the date on which the arbitration demand is intended to be filed, which
            must be at least thirty (30) days after the date of the notice. During this time period, the parties will
            meet for the purpose of resolving the dispute prior to commencing arbitration. 5.5 Subject to Section 8.4,
            each party may commence arbitration by providing to the Singapore International Arbitration Centre and the
            other party to the dispute a written demand for arbitration, stating the subject of the dispute and the
            relief requested. 5.6 Subject to the disclaimers and limitations of liability stated in the Agreement, the
            appointed arbitrators may award monetary damages and any other remedies allowed by the laws of the Singapore
            (including the SIAC Rules). In making a determination, the arbitrator will not have the authority to modify
            any term of the Agreement. The arbitrator will deliver a reasoned, written decision with respect to the
            dispute to each party, who will promptly act in accordance with the arbitrator’s decision. The decision of
            the arbitrator will be final and binding on the parties, and will not be subject to appeal or review. 5.7
            The party initiating the arbitration is responsible for paying the applicable filing fee. Each party will
            advance one-half of the fees and expenses of the arbitrator, the costs of the attendance of the arbitration
            reporter at the arbitration hearing and the costs of the arbitration facility. In any arbitration arising
            out of or relating to the Agreement, the arbitrator will award to the prevailing party, if any, the costs
            and attorneys’ fees reasonably incurred by the prevailing party in connection with those aspects of its
            claims or defenses on which it prevails, and any opposing awards of costs and legal fees awards will be
            offset. 5.8 The parties will keep confidential the existence of the arbitration, the arbitration proceeding,
            the hearing and the arbitrator’s decision, except (a) as necessary to prepare for and conduct the
            arbitration hearing on the merits; (b) in connection with a court application for a preliminary remedy, or
            confirmation of an arbitrator’s decision or its enforcement; (c) the Foundation may disclose the
            arbitrator’s decision in confidential settlement negotiations; (d) each party may disclose as necessary to
            professional advisors that are subject to a strict duty of confidentiality; and (e) as applicable law
            otherwise requires. The parties, witnesses and arbitrator will treat as confidential and will not disclose
            to any third person (other than witnesses or experts) any documentary or other evidence produced in any
            arbitration, except as applicable so requires or if the evidence was obtained from the public domain or was
            otherwise obtained independently from the arbitration. 5.9 In the case of a conflict between the provisions
            of this Section 8 and the SIAC Rules, the provisions of this Section 8 shall prevail. 5.10 To the extent
            permitted by applicable law, any dispute arising out of or relating to the Agreement, whether in arbitration
            or in court, shall be conducted only on an individual basis and not in a class, consolidated or
            representative action. Notwithstanding any other provision of the Agreement or the SIAC Rules, disputes
            regarding the interpretation, applicability or enforceability of this className waiver may be resolved only
            by a court and not by an arbitrator. If this waiver of className or consolidated actions is deemed invalid
            or unenforceable, neither party is entitled to arbitration. 5.11 If for any reason a claim or dispute
            proceeds in court rather than through arbitration, each party knowingly and irrevocably waives any right to
            trial by jury in any action, proceeding or counterclaim arising out of or relating to the Agreement. Term;
            Termination. 6.1. The Agreement is effective beginning when you accept the Agreement or first access or use
            the Node Software and ending when terminated as set forth in Section 9.2. 6.2. We reserve the right to
            restrict, ban, or blacklist your use or your ability to claim Network Rewards (any such event, a
            “Termination”) for any reason, including, but not limited to, in the case of your dishonest or fraudulent
            submission of your information for the purposes of identity verification or engage in prohibited activities
            or otherwise violate the good faith use of the Node and Network. Your right to use and access the Node
            Software will automatically terminate upon any Termination. Termination will be effective without notice.
            6.3. Upon termination of the Agreement, you must immediately cease all use of the Node Software and destroy
            all copies, full or partial, of the Node Software. Sections 4, 5, 6, 7, 8, 9 and 10 of this Agreement shall
            survive any such termination. General Provisions. 6.1 Electronic Communications. By purchasing, obtaining,
            accessing or using the Node Software, you consent to receive electronic communications. 6.2 Notices. the
            Foundation may provide you with notice and other communications via electronic communications as permitted
            by Section 10.1. You may provide us with notice by sending an email address to support@carv.io. All notices
            will be deemed effective upon dispatch. 6.3 Amendments. The Agreement may be modified or revised at any
            time, with or without prior notice to you. The most current version of the Agreement will be posted on a
            website to be determined by the Foundation (the “Website”) with the “Last Revised” date at the top. Any
            modifications or revisions will be effective immediately upon posting the modifications or revisions to the
            Website. You shall be responsible for reviewing and becoming familiar with any modifications or revisions.
            You waive any right you may have to receive specific notice of such modifications or revisions. Purchasing,
            obtaining, accessing or using the Node Software constitutes your acceptance of the Agreement as modified or
            revised. If you do not agree to the Agreement then in effect, you must immediately discontinue access to,
            and use of, the Node Software. 6.4 Waivers. For a waiver to be deemed effective, a waiver must be in a
            writing signed by the waiving party. The failure of either party to enforce any provision of the Agreement
            will not constitute a waiver of that party’s rights to subsequently enforce the provision. 6.5 Cumulative
            Rights; Injunctions. The rights and remedies of the parties under the Agreement are cumulative, and each
            party may exercise any of its rights and enforce any of its remedies under the Agreement, along with all
            other rights and remedies available to it at law, in equity or otherwise. Any material breach by a party of
            the Agreement could cause the non-breaching party irreparable harm for which the non-breaching party has no
            adequate remedies at law. Accordingly, the non-breaching party is entitled to seek specific performance or
            injunctive relief for any such breach. 6.6 Severability. If any provision of these the Agreement is declared
            to be invalid, illegal or unenforceable by a court of competent jurisdiction, then the validity, legality
            and enforceability of the remaining provisions contained herein shall not be affected thereby and the
            remainder of the provisions of the Agreement shall remain valid, legal and enforceable to the fullest extent
            permitted by law. 6.7 Force Majeure. the Foundation shall have no liability for any failure or delay
            resulting from any condition beyond our reasonable control, including but not limited to governmental action
            or acts of terrorism, earthquake, fire, flood, or other acts of God, labor conditions, power failures,
            equipment failures and Internet or blockchain network disturbances. 6.8 Successors and Assigns. You may not
            transfer or assign the Agreement or any rights or obligations hereunder, by operation of law or otherwise
            and any such attempted assignment shall be void. the Foundation reserves the right to freely transfer or
            assign the Agreement and the rights and obligations hereunder to any third party at any time without your
            consent and prior notice to you. If you object to any such transfer or assignment, you may stop using the
            Node Software. 6.9 Relationship of the Parties. Nothing contained in the Agreement shall constitute you and
            the Foundation as members of any partnership, joint venture, association, syndicate, unincorporated business
            or similar assignment as a result of or by virtue of the relationship established by the Agreement. 6.10
            Governing Law. The Agreement shall be solely and exclusively governed, construed and enforced in accordance
            with the laws of the SINGAPORE without giving effect to conflict of law rules or principles that would cause
            the application of the laws of any other jurisdiction. 6.11 Entire Agreement. The Agreement constitutes the
            entire agreement and understanding between you and the Foundation, and supersedes all previous
            communications, representations or agreements, whether written or oral, with respect to the subject matter
            hereof. 6.12 No Third-Party Beneficiaries. The Agreement is not intended and shall not be construed to
            create any rights or remedies in any parties other than you and the Foundation and other Foundation
            affiliates, which each shall be a third-party beneficiary of the Agreement, and no other person shall assert
            any rights as a third-party beneficiary hereunder. 6.13 Subject to Change. You understand that these Terms
            of Use are subject to change without notice and you are required to check these Terms from time to time for
            any such changes. LEGAL DISCLAIMER PLEASE READ THE ENTIRETY OF THIS {'"'}LEGAL DISCLAIMER{'"'} SECTION
            CAREFULLY. NOTHING HEREIN CONSTITUTES LEGAL, FINANCIAL, BUSINESS OR TAX ADVICE AND YOU ARE STRONGLY ADVISED
            TO CONSULT YOUR OWN LEGAL, FINANCIAL, TAX OR OTHER PROFESSIONAL ADVISOR(S) BEFORE ENGAGING IN ANY ACTIVITY
            IN CONNECTION HEREWITH. NEITHER GEMI FOUNDATION LTD. (THE COMPANY), ANY OF THE PROJECT CONTRIBUTORS (THE
            CARV TEAM) WHO HAVE WORKED ON CARV (AS DEFINED HEREIN) OR PROJECT TO DEVELOP CARV IN ANY WAY WHATSOEVER, ANY
            DISTRIBUTOR AND/OR VENDOR OF NODE LICENSE (OR SUCH OTHER RE-NAMED OR SUCCESSOR TICKER CODE OR NAME OF SUCH
            TOKENS) (THE DISTRIBUTOR), NOR ANY SERVICE PROVIDER SHALL BE LIABLE FOR ANY KIND OF DIRECT OR INDIRECT
            DAMAGE OR LOSS WHATSOEVER WHICH YOU MAY SUFFER IN CONNECTION WITH ACCESSING THE PAPER, DECK OR MATERIAL
            RELATING TO NODE LICENSE NFT (THE TOKEN DOCUMENTATION) AVAILABLE ON THE WEBSITE AT HTTPS://CARV.IO/ (THE
            WEBSITE, INCLUDING ANY SUB-DOMAINS THEREON) OR ANY OTHER WEBSITES OR MATERIALS PUBLISHED OR COMMUNICATED BY
            THE COMPANY OR ITS REPRESENTATIVES FROM TIME TO TIME. Project purpose: You agree that you are acquiring Node
            License NFT to participate in CARV and to obtain services on the ecosystem thereon. The Company, the
            Distributor and their respective affiliates would develop and contribute to the underlying source code for
            CARV. The Company is acting solely as an arms’ length third party in relation to the Node License NFT
            distribution, and not in the capacity as a financial advisor or fiduciary of any person with regard to the
            distribution of Node License NFT. Eligibility: To be eligible to use the Website (including services
            thereon) or participate in the Node License NFT distribution you must be at least eighteen (18) years of age
            or older. The Website, interface and services thereon is strictly NOT offered to persons or entities who
            reside in, are citizens of, are incorporated in, or have a registered office in any Restricted Territory, as
            defined below (any such person or entity from a Restricted Territory shall be a Restricted Person). If you
            are a Restricted Person, then do not attempt to access or use the Website. Use of a virtual private network
            (e.g., a VPN) or other means by Restricted Persons to access or use the Website, interface or services is
            prohibited. For the purpose of these Terms, Restricted Territory shall mean the United States, China, North
            Korea, Pakistan, Russia, Ukraine, Serbia, Tunisia, Uganda, Turkey, Iran, Iraq, Liberia, Libya, Syrian Arab
            Republic, Tajikistan, Uzbekistan, Yemen, Sri Lanka, Belarus, Venezuela, or any other state, country or
            region that is subject to sanctions enforced by the United States, the United Kingdom or the European Union.
            Validity of Token Documentation and Website: Nothing in the Token Documentation or the Website constitutes
            any offer by the Company, the Distributor, or the CARV team to sell any Node License NFT (as defined herein)
            nor shall it or any part of it nor the fact of its presentation form the basis of, or be relied upon in
            connection with, any contract or investment decision. Nothing contained in the Token Documentation or the
            Website is or may be relied upon as a promise, representation or undertaking as to the future performance of
            CARV. The agreement between the Distributor (or any third party) and you, in relation to any distribution or
            transfer of Node License NFT, is to be governed only by the separate terms and conditions of such agreement.
            Deemed Representations and Warranties: By accessing the Token Documentation or the Website (or any part
            thereof), you shall be deemed to represent and warrant to the Company, the Distributor, their respective
            affiliates, and the CARV team as follows: in any decision to acquire any Node License NFT, you have not
            relied and shall not rely on any statement set out in the Token Documentation or the Website; you shall at
            your own expense ensure compliance with all laws, regulatory requirements and restrictions applicable to you
            (as the case may be); you acknowledge, understand and agree that Node License NFT may have no value, there
            is no guarantee or representation of value or liquidity for Node License NFT, and Node License NFT is not an
            investment product nor is it intended for any speculative investment whatsoever; and none of the Company,
            the Distributor, their respective affiliates, and/or the CARV team shall be responsible for or liable for
            the value of Node License NFT, the transferability and/or liquidity of Node License NFT and/or the
            availability of any market for Node License NFT through third parties or otherwise. The Company, the
            Distributor and the CARV team do not and do not purport to make, and hereby disclaims, all representations,
            warranties or undertaking to any entity or person (including without limitation warranties as to the
            accuracy, completeness, timeliness, or reliability of the contents of the Token Documentation or the
            Website, or any other materials published by the Company or the Distributor). To the maximum extent
            permitted by law, the Company, the Distributor, their respective affiliates and service providers shall not
            be liable for any indirect, special, incidental, consequential or other losses of any kind, in tort,
            contract or otherwise (including, without limitation, any liability arising from default or negligence on
            the part of any of them, or any loss of revenue, income or profits, and loss of use or data) arising from
            the use of the Token Documentation or the Website, or any other materials published, or its contents
            (including without limitation any errors or omissions) or otherwise arising in connection with the same.
            Prospective acquirors of Node License NFT should carefully consider and evaluate all risks and uncertainties
            (including financial and legal risks and uncertainties) associated with the distribution of Node License
            NFT, the Company, the Distributor and the CARV team. Node License NFT: Node License NFT are designed to be
            utilised, and that is the goal of the Node License NFT distribution. In particular, it is highlighted that
            Node License NFT: does not have any tangible or physical manifestation, and does not have any intrinsic
            value/pricing (nor does any person make any representation or give any commitment as to its value); is
            non-refundable, not redeemable for any assets of any entity or organisation, and cannot be exchanged for
            cash (or its equivalent value in any other digital asset) or any payment obligation by the Company, the
            Distributor or any of their respective affiliates; does not represent or confer on the token holder any
            right of any form with respect to the Company, the Distributor (or any of their respective affiliates), or
            their revenues or assets, including without limitation any right to receive future dividends, revenue,
            shares, ownership right or stake, share or security, any voting, distribution, redemption, liquidation,
            proprietary (including all forms of intellectual property or licence rights), right to receive accounts,
            financial statements or other financial data, the right to requisition or participate in shareholder
            meetings, the right to nominate a director, or other financial or legal rights or equivalent rights, or
            intellectual property rights or any other form of participation in or relating to CARV, the Company, the
            Distributor and/or their service providers; is not intended to represent any rights under a contract for
            differences or under any other contract the purpose or intended purpose of which is to secure a profit or
            avoid a loss; is not intended to be a representation of money (including electronic money), payment
            instrument, security, commodity, bond, debt instrument, unit in a collective investment or managed
            investment scheme or any other kind of financial instrument or investment; is not a loan to the Company, the
            Distributor or any of their respective affiliates, is not intended to represent a debt owed by the Company,
            the Distributor or any of their respective affiliates, and there is no expectation of profit nor interest
            payment; and does not provide the token holder with any ownership or other interest in the Company, the
            Distributor or any of their respective affiliates. Notwithstanding the Node License NFT distribution, users
            have no economic or legal right over or beneficial interest in the assets of the Company, the Distributor,
            or any of their affiliates after the token distribution. To the extent a secondary market or exchange for
            trading Node License NFT does develop, it would be run and operated wholly independently of the Company, the
            Distributor, the distribution of Node License NFT and CARV. Neither the Company nor the Distributor will
            create such secondary markets nor will either entity act as an exchange for Node License NFT. English
            language: The Token Documentation and the Website may be translated into a language other than English for
            reference purpose only and in the event of conflict or ambiguity between the English language version and
            translated versions of the Token Documentation or the Website, the English language versions shall prevail.
            You acknowledge that you have read and understood the English language version of the Token Documentation
            and the Website. No Distribution: No part of the Token Documentation or the Website is to be copied,
            reproduced, distributed or disseminated in any way without the prior written consent of the Company or the
            Distributor. By attending any presentation on this Token Documentation or by accepting any hard or soft copy
            of the Token Documentation, you agree to be bound by the foregoing limitations.
          </IFTypography>
        )}
        {brand === Brands.SOPHON && (
          <>
            <ScrollableContent>
              <IFTypography variant="button" lineHeight="20px" ifcolor="text">
                This page (the “Page”) is published by the Rollup Chains Ltd. (“Sophon”) and is intended to be used only
                for the purchase of checker (validator) nodes (“Nodes”) on the Sophon Hyperchain (“Sophon Network”).
                This Page has been prepared by third-party service providers for and on behalf of Sophon, and is
                currently managed by the same third-party service providers. Although Sophon has reviewed and approved
                the contents of this Page, Sophon does not exercise direct control over the source code underlying the
                Page. In connection with your access to this Page, you may from time to time gain access to
                decentralized open-source smart contracts deployed on public blockchains. The Page is distinct and
                separate from any of the smart contracts that may be accessed through it, and is merely one of multiple
                means of accessing such smart contracts. Sophon is not responsible for any loss experienced by you as a
                result of your access or interaction with such smart contracts through this Page. To use parts of this
                Page, a user may need to use a third-party, non-custodial wallet software that allows the user to
                interact with public smart contracts and blockchains. Sophon does not operate, maintain, or provide any
                wallets or wallet services. Sophon will at no time have any custody or control of any crypto-assets that
                any user is interacting with. Sophon is not a wallet provider, exchange, broker, lender or borrower.
                Sophon is not responsible for any loss experienced by you as a result of your use of such wallet
                software through this Page. You acknowledge that the Nodes and the Sophon Network incorporate
                experimental and novel technology and that the use of such technology involves a high degree of risk.
                You hereby agree that you assume all risks in connection with your purchase or use of the Nodes. You
                understand and accept the risk of operational challenges related to the Nodes. You agree to accept the
                risk of a failure of the Nodes, whether resulting from unanticipated or heightened technical
                difficulties or vulnerabilities, including those resulting from cyber-attacks, or otherwise. You
                understand that, as of the date of the sale of the Nodes, the Sophon Network remains under development
                and that there is a risk that Sophon Network will not launch at all. Legal and regulatory requirements
                applicable to the purchase and/or use of the Nodes may vary from jurisdiction to jurisdiction. You
                acknowledge and agree that you are solely responsible for evaluating the legality of purchasing and/or
                using the Nodes in your jurisdiction. Sophon is not responsible for determining whether or which laws
                and regulations may apply to you or your purchase or use of the Nodes. You expressly agree that your
                purchase and use of the Nodes and of the Sophon Network is at your sole risk. The Nodes and the Sophon
                Network, and all software, information, content, materials, and products included in or otherwise made
                available to you through the Nodes and the Sophon Network, are each provided by Sophon on an “as is” and
                “as available” basis. To the fullest extent permitted by applicable law, Sophon disclaims all warranties
                of any kind, whether express or implied, statutory or otherwise, including, without limitation any
                warranties of merchantability, non-infringement, and fitness for particular purpose and any warranties
                arising from a course of dealing, course of performance, or usage of trade. Without limiting the
                foregoing, neither Sophon nor anyone associated with Sophon represents or warrants that the Nodes, the
                Sophon Network, or electronic communications sent from Sophon regarding the Nodes or the Sophon Network
                will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that the
                foregoing will be free of viruses or other harmful components, or that the Nodes or the Sophon Network
                will otherwise meet your needs or expectations. You acknowledge and expressly agree that your purchase
                of the Nodes is conditional upon your completion of requisite know-your-customer (“KYC”) checks to the
                satisfaction of Sophon and in accordance with applicable anti-money laundering (“AML”) and counter
                terrorist financing (“CTF”) regulations. To the extent that you are unable or unwilling to complete such
                KYC checks in accordance with applicable AML and CTF regulations, or if you are a “Restricted Person” as
                defined in the Node Sale Terms and Conditions, Sophon reserves the right to cancel your purchase of the
                Nodes and refund your payment to you without recourse to Sophon. By clicking Agree and continuing, you
                hereby confirm that you have read, understood, and accepted the foregoing policies and disclaimers and
                expressly agree to, and consent to be bound by, the{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://sophon.xyz/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
                , the{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://sophon.xyz/privacypolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                , and the{' '}
                <a
                  style={{ textDecoration: 'underline' }}
                  href="https://sophon.xyz/node-sale-terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Node Sale Terms and Conditions
                </a>
                , hereby incorporated by reference, and all of the terms and conditions contained therein. If you do not
                agree and adhere to all of the terms and conditions therein, you may not proceed to the Page.
              </IFTypography>
            </ScrollableContent>
            <Flex flexDirection="row" alignItems="flex-start" padding="5px 0px">
              <Checkbox
                name="confirmedLaws"
                type="checkbox"
                style={{ width: '36px' }}
                checked={termsChecked}
                onChange={() => {
                  setTermsChecked(!termsChecked)
                }}
                scale="sm"
              />
              <Box display="flex" columnGap="2px">
                <IFTypography
                  fontSize="14px"
                  lineHeight="20px"
                  align="left"
                  paddingLeft="16px"
                  margin="auto 0px"
                  ifcolor="textSecondary"
                >
                  I agree with the{' '}
                  <a
                    style={{ textDecoration: 'underline' }}
                    href="https://sophon.xyz/node-sale-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Node Sale Terms
                  </a>
                  ,{' '}
                  <a
                    style={{ textDecoration: 'underline' }}
                    href="https://sophon.xyz/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sophon Terms{' '}
                  </a>
                  and{' '}
                  <a
                    style={{ textDecoration: 'underline' }}
                    href="https://sophon.xyz/privacypolicy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </IFTypography>
              </Box>
            </Flex>
          </>
        )}

        {brand === Brands.AETHIR && (
          <>
            <IFTypography variant="button" lineHeight="20px" ifcolor="text">
              Terms of Use (Node) Please read this Node Software Agreement (this “Agreement”) carefully before using the
              Aethir network (the “Network”) and its checker node (the “Node”), which comprises of the distributed
              software needed to help run the Network (“Node Software”) and the node key (“Key”), as further defined
              below. By accessing, operating, or otherwise using (collectively, “use” or “using”) the Node Software or
              purchasing, accessing or using the Node or the Key, you agree that you have read, understood and accepted
              all of the terms and conditions contained in this Agreement by and between you and DCI Foundation, a
              Panama private interest foundation (the “Company” or “we”). If you do not agree with all or any part of
              the terms and conditions of this Agreement, please do not use the Node Software. THE NODE SOFTWARE IS NOT
              AVAILABLE FOR ACCESS OR USE, AND THE KEY AND NODE SOFTWARE ARE NOT AVAILABLE FOR PURCHASE, ACCESS OR USE,
              BY ANY RESIDENT OF OR ANY PERSON LOCATED OR DOMICILED IN THE UNITED STATES, NORTH KOREA, IRAN, VENEZUELA,
              OR ANY OTHER JURISDICTIONS SANCTIONED BY THE U.S. DEPARTMENT OF THE TREASURY’S OFFICE OF FOREIGN ASSETS
              CONTROL (“OFAC”). PLEASE BE AWARE THAT SECTION 8 (DISPUTE RESOLUTION; AGREEMENT TO ARBITRATE) GOVERNS THE
              RESOLUTION OF DISPUTES BETWEEN YOU AND the Foundation. SECTION 8 INCLUDES AN AGREEMENT TO ARBITRATE WHICH
              REQUIRES, WITH LIMITED EXCEPTIONS, THAT ALL DISPUTES BETWEEN YOU AND US SHALL BE RESOLVED BY BINDING AND
              FINAL ARBITRATION. SECTION 8 ALSO CONTAINS A CLASS ACTION AND JURY TRIAL WAIVER. PLEASE READ SECTION 8
              CAREFULLY. THIS AGREEMENT MAY BE AMENDED FROM TIME TO TIME IN ACCORDANCE WITH SECTION 10.3 AND IF AT ANY
              TIME YOU DO NOT AGREE WITH ALL OR ANY PART OF THE THEN EFFECTIVE TERMS AND CONDITIONS, YOUR SOLE RECOURSE
              IS TO STOP USING THE NODE. Eligibility Requirements. General. To be eligible to access or use the Node
              Software or purchase, access or use a Node, you must satisfy each of the following eligibility
              requirements: 1.1.1 You are at least eighteen (18) years of age, or are the legal age for entering legally
              binding agreements under applicable law; 1.1.2 You are not, nor are you an entity that is, or an entity
              owned or controlled by any person or entity that is, or conducting any activities itself or on behalf of
              any person or entity that is: (i) a natural person resident in the United States; (ii) a partnership or
              corporation organised or incorporated under the laws of the United States; or (iii) otherwise a “U.S.
              person” as defined in Rule 902(k)(2) of Regulation S under the Securities Act of 1933 (each, a “U.S.
              Person”); 1.1.3 You are not, nor are you an entity that is, or an entity owned or controlled by any person
              or entity that is, or conducting any activities itself or on behalf of any person or entity that is: (x)
              the subject of any sanctions administered or enforced by OFAC (including, for the avoidance of doubt, any
              individual or entity included on OFAC’s Specially Designated Nationals and Blocked Persons List), the U.S.
              Department of State or any other governmental authority with jurisdiction over the party; (y) identified
              on the Denied Persons, Entity or Unverified Lists of the U.S. Department of Commerce’s Bureau of Industry
              and Security; or located, organized or (z) resident in a country or territory that is, or whose government
              is, the subject of economic sanctions, including, without limitation, Russia, Cuba, Iran, North Korea
              Syria or the Crimea, Donetsk People’s Republic, and Luhansk People’s Republic regions of Ukraine (each, a
              “Restricted Person”); and 1.1.4 You are not purchasing, obtaining, accessing or using the Node Software on
              behalf of a U.S. Person or Restricted Person. 1.2 Identity Verification. You will be required to provide
              information to us for the purposes of identity verification before you will be able to claim and take
              possession of any Network cryptographic tokens, i.e., “ATH tokens” (“Tokens”) earned as “Network Rewards”
              (defined below) in connection with your use of the Node. If we are unable to verify your identity and
              confirm that you are eligible to use the Node Software, you will not be permitted to use the Node
              Software, including to earn any Network Rewards. You agree to provide us with the information we request
              for purposes of identity verification and permit us to keep a record of such information. You further
              represent and agree that all such information is complete and accurate and that you will immediately
              notify the Foundation in the event that any information provided to the Foundation during this process is
              no longer complete or accurate. Node Software. 2.1 Use of Node. Subject to the terms and conditions of
              this Agreement, you may use the Node Software to operate a Network Node on one or more machines that you
              own or control. The Network is a network that operates on top of a blockchain network. As a “Node
              Operator,” you shall help ensure the integrity and performance of the “Containers” in the Network. A
              “Container” is a virtual endpoint performing tasks such as validating transactions and rendering content.
              You hereby acknowledge and agree that: the Foundation has no control over your activities and all
              validation activities are done solely in your own discretion as a node operator for the benefit of the
              Network and its users and participants; Any transactions that are confirmed via validation activities are
              completed exclusively under the Network’s decentralized consensus mechanism, and that there are no
              guarantees or protections with regards to the likelihood of any transaction being verified or confirmed on
              the Network; The Network is decentralized and neither you nor any other party has unilateral control over
              the Network and the transactions conducted thereon; You shall be solely responsible for comply with all
              applicable laws in connection with your role as a Network Node operator. 2.2 Node Key Requirement. You
              must acquire a Node Key from the Foundation I n order to run a Checker Node and earn Network Rewards
              (defined below). The Node is a non-transferable non-fungible token. You will be required to provide
              information to the Foundation for purposes of verifying your identity and confirming that you are eligible
              to earn Network Rewards in order to claim and take possession of any Tokens earned as Network Rewards. 2.3
              Connecting a Digital Wallet. In order to operate a Node using the Node Software, you will need to connect
              a compatible third-party digital wallet (“Wallet”) compatible with the Ethereum Virtual Machine (“EVM”).
              You are solely responsible for maintaining the security of your Wallet, including any associated
              credentials, private key and seed phrase. The Foundation does not offer Wallet software or custody Tokens
              (or any other crypto assets) on behalf of Node Software users. Your use of a third-party Wallet is subject
              to separate terms and conditions established by the relevant Wallet provider. the Foundation shall not be
              liable for any acts or omissions by you in connection with your Wallet or as a result of any data breach,
              cyber incident, or security vulnerability that impacts your Wallet or your hardware and software used to
              access the same. 2.4 Network Rewards. You may receive units of Tokens for running the Nodes to contribute
              to the integrity and performance of the Containers in the Network (“Network Rewards”). All such Network
              Rewards, including their rate of accrual and any award of bonus rewards, are programmatically set and
              distributed to Nodes by the Network. The Foundation is not responsible or liable for any Network Rewards
              (if applicable) that result from your use of the Node Software. 2.5 Expiration of Network Rewards. Subject
              to the terms set forth herein, Network Rewards can be claimed at any time. You will be required to provide
              information to the Foundation for purposes of verifying your identity and confirming your eligibility to
              earn Network Rewards before you will be permitted to claim and take possession of any ATH Tokens earned as
              Network Rewards. Aethir may impose an expiration to the tokens whereby you will have a certain number of
              days from the date that you earn a Network Reward to claim the ATH tokens earned as part of the Network
              Reward before the Network Reward expires, and any such expiration may be communicated to you via
              electronic transmission, by posting on the Website or through an amendment or modification of these Terms.
              You should check these Terms from time to time for any changes to these terms. 2.6 Node Software Updates.
              The Node Software may automatically download or prompt you to download and install updates onto your
              machine from time to time. By accessing or using the Node Software, you agree that the Foundation may
              download and install automatic Node Software updates onto your machine. You may opt out of automatic
              updates by adjusting your Node Software settings. License Uses and Restrictions. 3.1 Node Software
              License. Subject to the terms and conditions of this Agreement, the Foundation hereby grants to you a
              non-exclusive, revocable, non-transferable, non-sublicensable, royalty-free, limited license to install,
              use and run the Node Software on one or more machines that you own or control. Except for the license
              expressly granted to you hereunder, no other license is granted and no other use is permitted. 3.2
              Third-Party Licenses. Notwithstanding anything to the contrary in this Agreement, the Node Software may
              contain software components released under separate open-source or business-source license terms, in which
              case those license terms will govern such software components. 3.3 Feedback. With respect to any feedback
              you provide to the Foundation (whether orally or in writing) concerning the Node, including any features
              or functionalities thereof, and including identifying potential errors and improvements (“Feedback”), you
              hereby grant to the Foundation an exclusive, worldwide, perpetual, fully paid-up, royalty free,
              fully-sublicensable (through multiple tiers of sublicensees) and freely assignable and transferable
              license to use any Feedback for any purpose without payment or restriction. It is further understood that
              the Foundation{`'`} s use of Feedback, if any, may be made by the Foundation in its sole discretion, and
              that the Foundation shall in no way be obliged to make any payment to you for or make use of any kind of
              the Feedback or part thereof. 3.4 Use of Trademarks and Other Marks or Rights. You may not use any of our
              trademarks, trade names, service marks, copyrights, or logos, or our partners’, affiliated entities’,
              licensors’, or their licensors’ trademarks, trade names, service marks, copyrights, or logos, including
              but not limited to “Aethir”, in any manner which creates the impression that such items (i) belong to or
              are associated with you or indicate the sponsorship or approval of us, our licensors, any partners,
              affiliates, or their licensors; or (ii) except as otherwise provided herein, are used with our licensors’,
              partners’, affiliates’, or their licensors’ consent, and you acknowledge that you have no ownership rights
              in or to any such items. Prohibited Activities. You shall not engage in any activities that negatively
              affect the technical performance of the Node Software and/or Network, bypass or circumvent security
              features of the Node Software and/or Network, or otherwise disrupt or interfere with the functioning of
              the Node Software and/or Network. You shall not violate or attempt to violate the security of the Node
              Software and/or Network or otherwise misuse the foregoing, including by, (i) accessing data not intended
              for you or logging onto a server or an account which you are not authorized to access; (ii) disabling,
              removing, defeating or avoiding any security device or system; (iii) attempting to probe, scan or test the
              vulnerability of the Node Software and/or Network or to breach security or authentication measures without
              proper authorization; (iv) attempting to interfere with service to any sentry node operator, host or
              network, including, but not limited to, via means of submitting any malware or computer programming
              routines that may damage, disrupt or interfere with, intercept or expropriate any system or data,
              overloading, “flooding,” “spamming,” “mailbombing” or “crashing” the Node Software and/or Network; (v)
              forging any transmission control protocol/Internet protocol packet header or any part of the header
              information in any email or posting; (vi) using the Node Software and/or Network in a manner that exceeds
              reasonable request volume or constitutes excessive or abusive usage; or (vii) providing false, misleading
              or inaccurate information to the Network. 4.2 You shall not, directly or indirectly: (i) use the Node
              Software or any portion thereof to create any service, software, product, platform, documentation or data
              that is similar to, in whole or in part, any aspect of the services or products offered by the Foundation,
              including without limitation, the Node Software or Network; (ii) disassemble, decompile, reverse engineer
              or use any other means to attempt to discover any source code of the Node Software, or the underlying
              ideas, file formats, algorithms or trade secrets therein; (iii) encumber, sublicense, transfer, rent,
              lease, time-share or use the Node Software in any service bureau arrangement or otherwise for the benefit
              of any third party; (iv) copy, distribute, manufacture, adapt, create derivative works of, translate,
              localize, port or otherwise modify any software code or documentation for the Node Software; (v) use or
              allow the transmission, transfer, export, re-export or other transfer of any product, technology or
              information it obtains or learns pursuant to this Agreement (or any direct product thereof) in violation
              of any export control or other laws and regulations of any relevant jurisdiction; (vi) knowingly introduce
              into the Node Software and/or Network any malicious code, computer virus, spyware, scareware, Trojan
              horses, worms, malware or any other similar harmful, malicious or hidden programs or data; (vii) remove or
              modify any proprietary markings or restrictive legends placed on the Node Software; (viii) use the Node
              Software to infringe upon, violate or misappropriate any third party{`'`} s intellectual property rights,
              violating any law or regulation or being defamatory, trade libelous, threatening or harassing; or(ix)
              authorize or permit any third party to engage in any of the foregoing proscribed acts.For the avoidance of
              doubt, the restrictions set forth in this Section are in addition to, and in no way limit, any other
              restrictions or obligations applicable to you set forth in this Agreement. 4.3 You shall not use the Node
              Software and/or Network to engage in illegal activity of any kind, including, without limitation, any
              activity that would violate, or assist in violation of, any law, statute, ordinance, regulation or
              sanctions programs administered under any applicable law, including but not limited to the U.S. Department
              of Treasury{`'`} s Office of Foreign Assets Control or which would involve proceeds of any unlawful
              activity. Risks. You acknowledge that the Node Software and Network incorporate experimental and novel
              technology and that the use of such technology involves a high degree of risk. For example, there are
              numerous reasons the Node Software or Network could fail in an unexpected way, resulting in the total and
              absolute loss of your Tokens. You hereby agree that you assume all risks in connection with your use of
              the Node Software and Network and expressly waive and release the Foundation from any and all liability,
              claims, causes of action or damages arising out of or in any way relating to you obtaining or using Node
              Software and Network. You understand and accept the risk of operational challenges related to the Node
              Software and Network. For example, the Network may experience cyber-attacks, unexpected surges in
              transaction volume or activity or other operational or technical difficulties or vulnerabilities that may
              cause interruptions related to your use of the Node Software or Network. You agree to accept the risk of
              Node Software or Network failure resulting from unanticipated or heightened technical difficulties or
              vulnerabilities, including those resulting from cyber-attacks. You agree not to hold the Foundation
              accountable for any claims, damages, liabilities, costs, and expenses you may incur, directly or
              indirectly, in connection with or related to your use of the Node. You agree that the Foundation is not
              responsible for any Tokens or other crypto assets that you receive, transfer, hold, stake, lose or
              otherwise use or misuse in connection with the Node Software or the Network. Legal and regulatory
              requirements applicable to use of the Node Software may vary from jurisdiction to jurisdiction. You
              acknowledge and agree that you are solely responsible for evaluating the legality of using the Node
              Software in your jurisdiction. the Foundation is not responsible for determining whether or which laws and
              regulations may apply to you or your use of the Node Software. Disclaimer of Warranties; Limitation of
              Liability. EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE NODE SOFTWARE AND NETWORK ARE ISSUED ON AN “AS-IS”
              AND “AS AVAILABLE” BASIS AND the Foundation DOES NOT MAKE ANY WARRANTIES WITH RESPECT TO SUCH “AS-IS” AND
              “AS AVAILABLE” BASIS OR OTHERWISE IN CONNECTION WITH THIS AGREEMENT (EXCEPT AS EXPRESSLY PROVIDED HEREIN)
              AND the Foundation HEREBY DISCLAIMS ANY AND ALL EXPRESS, IMPLIED OR STATUTORY WARRANTIES AND CONDITIONS,
              INCLUDING ANY WARRANTIES OR CONDITIONS OF NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, AVAILABILITY, ERROR-FREE OR UNINTERRUPTED OPERATION, AND ANY WARRANTIES ARISING FROM A COURSE OF
              DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE. TO THE EXTENT THAT the Foundation MAY NOT, AS A MATTER
              OF APPLICABLE LAW, DISCLAIM ANY IMPLIED WARRANTY OR CONDITION, THE SCOPE AND DURATION OF SUCH WARRANTY OR
              CONDITION SHALL BE APPLIED TO THE MINIMUM EXTENT PERMITTED UNDER SUCH APPLICABLE LAW. IN NO EVENT SHALL
              the Foundation BE LIABLE TO YOU FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL OR SPECIAL DAMAGES OF ANY TYPE
              OR NATURE HOWEVER ARISING, INCLUDING, WITHOUT LIMITATION, EXEMPLARY OR PUNITIVE DAMAGES, LOST PROFITS OR
              REVENUES OR DIMINUTION IN VALUE, ARISING OUT OF OR RELATING TO THE NODE SOFTWARE OR THE NETWORK, WHETHER
              OR NOT THE POSSIBILITY OF SUCH DAMAGES HAS BEEN DISCLOSED TO OR COULD HAVE BEEN REASONABLY FORESEEN BY
              YOU, REGARDLESS OF THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS
              BASED. IN ADDITION, UNDER NO CIRCUMSTANCES SHALL the Foundation{`'`}S AGGREGATE LIABILITY UNDER THE
              AGREEMENT EXCEED ONE-HUNDRED U.S. DOLLARS ($100.00). SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
              CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
              DAMAGES.ACCORDINGLY, SOME OF THE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU.IF YOU ARE DISSATISFIED
              WITH THE NODE SOFTWARE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE NODE SOFTWARE.
              Indemnification. You agree, at your own expense, to indemnify, defend and hold harmless the Foundation and
              its partners and affiliates and their respective owners, members, agents, directors, officers, employees,
              representatives, affiliates, successors and assigns against any claim, suit, action, or other proceeding
              from and against any and all claims, damages, liabilities, costs, and expenses, including reasonable
              attorneys’ and experts’ fees, arising out of or in connection with your breach of the Agreement, your
              violation of any law or regulation or your use of the Node Software. You agree to pay any and all costs,
              damages and expenses, including but not limited to reasonable attorneys’ fees and costs awarded against or
              otherwise incurred by or in connection with or arising from any such claim, suit, action, or proceeding
              attributable to any such claim. the Foundation reserves the right, at its own expense, to assume the
              exclusive defense and control of any matter otherwise subject to indemnification by you, in which event
              you will fully cooperate with the Foundation in asserting any available defense. If you have a dispute
              with one or more users of the Node Software or other third parties, you release the Foundation (and its
              affiliates and service providers, and each of their officers, directors, agents, joint ventures, employees
              and representatives) from all claims, demands and damages (actual and consequential) of every kind and
              nature arising out of or in any way connected with such disputes. Dispute Resolution; Agreement to
              Arbitrate. 8.1 All disputes, claims and controversies, whether based on past, present or future events,
              arising out of or relating to statutory or common law claims, the breach, termination, enforcement,
              interpretation or validity of any provision of the Agreement, and the determination of the scope or
              applicability of your agreement to arbitrate any dispute, claim or controversy originating from the
              Agreement, will be determined by binding arbitration in Tortola, the British Virgin Islands (“BVI”)
              pursuant to the BVI IAC Arbitration Rules, before a single arbitrator. 8.2 The arbitrator will apply the
              substantive law of the BVI, excluding its conflict or choice of law rules. 8.3 Nothing in the Agreement
              will preclude the parties from seeking provisional remedies in aid of arbitration from a court of
              appropriate jurisdiction. 8.4 A party must notify the other party of its intent to commence arbitration
              prior to commencing arbitration. The notice must specify the date on which the arbitration demand is
              intended to be filed, which must be at least thirty (30) days after the date of the notice. During this
              time period, the parties will meet for the purpose of resolving the dispute prior to commencing
              arbitration. 8.5 Subject to Section 8.4, each party may commence arbitration by providing to the BVI
              International Arbitration Centre and the other party to the dispute a written demand for arbitration,
              stating the subject of the dispute and the relief requested. 8.6 Subject to the disclaimers and
              limitations of liability stated in the Agreement, the appointed arbitrators may award monetary damages and
              any other remedies allowed by the laws of the BVI (including the BVI IAC Arbitration Rules). In making a
              determination, the arbitrator will not have the authority to modify any term of the Agreement. The
              arbitrator will deliver a reasoned, written decision with respect to the dispute to each party, who will
              promptly act in accordance with the arbitrator’s decision. Any award (including interim or final remedies)
              may be confirmed in or enforced by a state or federal court located in the BVI. The decision of the
              arbitrator will be final and binding on the parties, and will not be subject to appeal or review. 8.7 The
              party initiating the arbitration is responsible for paying the applicable filing fee. Each party will
              advance one-half of the fees and expenses of the arbitrator, the costs of the attendance of the
              arbitration reporter at the arbitration hearing and the costs of the arbitration facility. In any
              arbitration arising out of or relating to the Agreement, the arbitrator will award to the prevailing
              party, if any, the costs and attorneys’ fees reasonably incurred by the prevailing party in connection
              with those aspects of its claims or defenses on which it prevails, and any opposing awards of costs and
              legal fees awards will be offset. 8.8 The parties will keep confidential the existence of the arbitration,
              the arbitration proceeding, the hearing and the arbitrator’s decision, except (a) as necessary to prepare
              for and conduct the arbitration hearing on the merits; (b) in connection with a court application for a
              preliminary remedy, or confirmation of an arbitrator’s decision or its enforcement; (c) the Foundation may
              disclose the arbitrator’s decision in confidential settlement negotiations; (d) each party may disclose as
              necessary to professional advisors that are subject to a strict duty of confidentiality; and (e) as
              applicable law otherwise requires. The parties, witnesses and arbitrator will treat as confidential and
              will not disclose to any third person (other than witnesses or experts) any documentary or other evidence
              produced in any arbitration, except as applicable so requires or if the evidence was obtained from the
              public domain or was otherwise obtained independently from the arbitration. 8.9 In the case of a conflict
              between the provisions of this Section 8 and the BVI IAC Arbitration Rules, the provisions of this Section
              8 shall prevail. 8.10 To the extent permitted by applicable law, any dispute arising out of or relating to
              the Agreement, whether in arbitration or in court, shall be conducted only on an individual basis and not
              in a class, consolidated or representative action. Notwithstanding any other provision of the Agreement or
              the BVI IAC Arbitration Rules, disputes regarding the interpretation, applicability or enforceability of
              this className waiver may be resolved only by a court and not by an arbitrator. If this waiver of
              className or consolidated actions is deemed invalid or unenforceable, neither party is entitled to
              arbitration. 8.11 If for any reason a claim or dispute proceeds in court rather than through arbitration,
              each party knowingly and irrevocably waives any right to trial by jury in any action, proceeding or
              counterclaim arising out of or relating to the Agreement. Term; Termination. 9.1. The Agreement is
              effective beginning when you accept the Agreement or first access or use the Node Software and ending when
              terminated as set forth in Section 9.2. 9.2. We reserve the right to restrict, ban, or blacklist your use
              or your ability to claim Network Rewards (any such event, a “Termination”) for any reason, including, but
              not limited to, in the case of your dishonest or fraudulent submission of your information for the
              purposes of identity verification or engage in prohibited activities or otherwise violate the good faith
              use of the Node and Network. Your right to use and access the Node Software will automatically terminate
              upon any Termination. Termination will be effective without notice. 9.3. Upon termination of the
              Agreement, you must immediately cease all use of the Node Software and destroy all copies, full or
              partial, of the Node Software. Sections 4, 5, 6, 7, 8, 9 and 10 of this Agreement shall survive any such
              termination. General Provisions. 10.1 Electronic Communications. By purchasing, obtaining, accessing or
              using the Node Software, you consent to receive electronic communications. 10.2 Notices. the Foundation
              may provide you with notice and other communications via electronic communications as permitted by Section
              10.1. You may provide us with notice by sending an email address to support@aethir.com. All notices will
              be deemed effective upon dispatch. 10.3 Amendments. The Agreement may be modified or revised at any time,
              with or without prior notice to you. The most current version of the Agreement will be posted on a website
              to be determined by the Foundation (the “Website”) with the “Last Revised” date at the top. Any
              modifications or revisions will be effective immediately upon posting the modifications or revisions to
              the Website. You shall be responsible for reviewing and becoming familiar with any modifications or
              revisions. You waive any right you may have to receive specific notice of such modifications or revisions.
              Purchasing, obtaining, accessing or using the Node Software constitutes your acceptance of the Agreement
              as modified or revised. If you do not agree to the Agreement then in effect, you must immediately
              discontinue access to, and use of, the Node Software. 10.4 Waivers. For a waiver to be deemed effective, a
              waiver must be in a writing signed by the waiving party. The failure of either party to enforce any
              provision of the Agreement will not constitute a waiver of that party’s rights to subsequently enforce the
              provision. 10.5 Cumulative Rights; Injunctions. The rights and remedies of the parties under the Agreement
              are cumulative, and each party may exercise any of its rights and enforce any of its remedies under the
              Agreement, along with all other rights and remedies available to it at law, in equity or otherwise. Any
              material breach by a party of the Agreement could cause the non-breaching party irreparable harm for which
              the non-breaching party has no adequate remedies at law. Accordingly, the non-breaching party is entitled
              to seek specific performance or injunctive relief for any such breach. 10.6 Severability. If any provision
              of these the Agreement is declared to be invalid, illegal or unenforceable by a court of competent
              jurisdiction, then the validity, legality and enforceability of the remaining provisions contained herein
              shall not be affected thereby and the remainder of the provisions of the Agreement shall remain valid,
              legal and enforceable to the fullest extent permitted by law. 10.7 Force Majeure. the Foundation shall
              have no liability for any failure or delay resulting from any condition beyond our reasonable control,
              including but not limited to governmental action or acts of terrorism, earthquake, fire, flood, or other
              acts of God, labor conditions, power failures, equipment failures and Internet or blockchain network
              disturbances. 10.8 Successors and Assigns. You may not transfer or assign the Agreement or any rights or
              obligations hereunder, by operation of law or otherwise and any such attempted assignment shall be void.
              the Foundation reserves the right to freely transfer or assign the Agreement and the rights and
              obligations hereunder to any third party at any time without your consent and prior notice to you. If you
              object to any such transfer or assignment, you may stop using the Node Software. 10.9 Relationship of the
              Parties. Nothing contained in the Agreement shall constitute you and the Foundation as members of any
              partnership, joint venture, association, syndicate, unincorporated business or similar assignment as a
              result of or by virtue of the relationship established by the Agreement. 10.10 Governing Law. The
              Agreement shall be solely and exclusively governed, construed and enforced in accordance with the laws of
              the BVI without giving effect to conflict of law rules or principles that would cause the application of
              the laws of any other jurisdiction. 10.11 Entire Agreement. The Agreement constitutes the entire agreement
              and understanding between you and the Foundation, and supersedes all previous communications,
              representations or agreements, whether written or oral, with respect to the subject matter hereof. 10.12
              No Third-Party Beneficiaries. The Agreement is not intended and shall not be construed to create any
              rights or remedies in any parties other than you and the Foundation and other Foundation affiliates, which
              each shall be a third-party beneficiary of the Agreement, and no other person shall assert any rights as a
              third-party beneficiary hereunder. 10.13 Subject to Change. You understand that these Terms of Use are
              subject to change without notice and you are required to check these Terms from time to time for any such
              changes.
            </IFTypography>
          </>
        )}
        <>
          {!isJustSale && (
            <>
              {brand === Brands.IF ? (
                <IFTypography variant="button" lineHeight="20px" ifcolor="text">
                  {t(
                    `Impossible Finance is a decentralized peer-to-peer protocol that people can use to create liquidity and trade tokens. There are multiple versions of the Impossible Finance protocol, each of which is made up of free, public, open-source or source-available software including a set of smart contracts that are deployed on %chainName%. Your use of the Impossible Finance protocol involves various risks, including, but not limited to, losses while digital assets are being supplied to the Impossible Finance protocol and losses due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Impossible Finance protocol, you should review the relevant documentation to make sure you understand how the Impossible Finance protocol works. Additionally, just as you can access email protocols, such as SMTP, through multiple email clients, you can access the Impossible Finance protocol through multiple web and mobile interfaces. You are responsible for conducting your own diligence on those interfaces to understand the fees and risks involved.`,
                    {
                      chainName,
                    },
                  )}
                </IFTypography>
              ) : (
                <IFTypography sx={{ fontWeight: '400' }} variant="button" lineHeight="20px" ifcolor="text">
                  {t(
                    `The service on %mainUrl% (“Service”) is provided by Impossible Finance, which is a decentralized peer-to-peer protocol that people can use to create liquidity and trade tokens. There are multiple versions of the Impossible Finance protocol, each of which is made up of free, public, open-source or source-available software including a set of smart contracts that are deployed on %chainName%. Your use of the Service involves various risks, including, but not limited to, losses while digital assets are being supplied to the Services or the Impossible Finance protocol and losses due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Service or Impossible Finance protocol, you should review the relevant documentation to make sure you understand how the Service and the Impossible Finance protocol work. Additionally, just as you can access email protocols, such as SMTP, through multiple email clients, you can access the Service and the Impossible Finance protocol through multiple web and mobile interfaces. You are responsible for conducting your own diligence on those interfaces to understand the fees and risks involved.`,
                    {
                      mainUrl,
                      chainName,
                    },
                  )}
                </IFTypography>
              )}

              <Box marginTop="16px" />
              {brand === Brands.IF ? (
                <IFTypography variant="button" lineHeight="20px" ifcolor="text">
                  {t(
                    `THE IMPOSSIBLE FINANCE PROTOCOL IS PROVIDED ”AS IS” AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. The Impossible Finance protocol is run by smart contracts deployed on the %chainName%. Upgrades and modifications to the protocol are managed in a community-driven way by holders of the Impossible Finance  governance token. No developer or entity involved in creating the Impossible Finance protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of the Impossible Finance protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.`,
                    {
                      chainName,
                    },
                  )}
                </IFTypography>
              ) : (
                <IFTypography variant="button" lineHeight="20px" ifcolor="text">
                  {t(
                    `THE SERVICES AND THE IMPOSSIBLE FINANCE PROTOCOL ARE PROVIDED ”AS IS” AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. The Services and the Impossible Finance protocol are run by smart contracts deployed on the %chainName%. Upgrades and modifications to the protocol are managed in a community-driven way by holders of the Impossible Finance governance token. No developer or entity involved in creating the Services or the Impossible Finance protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of the Services or the Impossible Finance protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.`,
                    {
                      chainName,
                    },
                  )}
                </IFTypography>
              )}
            </>
          )}
        </>
      </Content>
    </Modal>
  )
}

export default PurchaseCardDisclaimer
