import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { LinearVestableIDO, PurchasableIDO, PurchasePeriod, SubscribeableIDO } from 'state/v2_types'
import { ClaimEndCard, ClaimStartCard } from 'componentsV2/Card'
import { convertFromWei } from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import { useIsSaleUserDataFetched, useSaleUserData } from 'state/idos/hooks'
import { isPurchaseableIDO, isSubscribeableIDO } from 'state/idos/saleUtil'
import { useAccount } from 'wagmi'
import LoadingCard from 'componentsV2/Card/LoadingCard/LoadingCard'
import LinearVestingClaimCard from 'componentsV2/Card/LinearVestingClaim'
import { useGetAllocation } from 'hooks/useIFASale'

const LinearVestClaiming: React.FC<{ sale: LinearVestableIDO }> = ({ sale }) => {
  const userData = useSaleUserData(sale.id)
  const { address: account } = useAccount()
  const isSaleUserDataFetched = useIsSaleUserDataFetched()
  const { allo: userAllocationInWei, isLoading } = useGetAllocation(sale.id, sale.isPrivate)

  const getClaimingState = useCallback(() => {
    const now = new Date()
    const {
      linearVestInfo,
      token,
      saleAddress,
      chainId,
      saleChainId = chainId,
      saleAmount,
      whitelistedUrl,
      stepRequirement,
      saleId,
    } = sale
    const { purchasePeriod } = sale as any as PurchasableIDO

    let purchaserCount = purchasePeriod?.purchaserCount || 0
    let finalSalePrice = 0

    const { startTime, endTime } = linearVestInfo

    let finalTotalPaymentReceived = saleAmount
    const { symbol, image, address, decimals } = token

    const paymentReceivedInWei = (userData && userData.paymentReceivedInWei) || new BigNumber(0)
    const paymentReceivedNumber = convertFromWei(paymentReceivedInWei)
    const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), decimals)
    const userAllocationNumber = convertFromWei(new BigNumber(userAllocationInWei), decimals)

    // If the sale is not purchaseable, then the user's allocation is the total amount of tokens they can claim
    // If the sale is purchaseable, then the user's allocation is the payment token received
    const firstTimeClaimableTotal =
      (sale as any as PurchasableIDO).paymentToken?.symbol !== undefined ? paymentReceivedNumber : userAllocationNumber
    // current time is the min of now and the end time of the vesting period
    const currentTime = Math.min(Date.parse(new Date().toISOString()), Date.parse(linearVestInfo.endTime))
    // Calculate first time claimable amount based on the time passed in the vesting period, bounded by the end time
    const firstTimeClaimable = firstTimeClaimableTotal
      .multipliedBy(currentTime - Date.parse(linearVestInfo.startTime))
      .dividedBy(Date.parse(linearVestInfo.endTime) - Date.parse(linearVestInfo.startTime))

    const claimableTokenInWei = userData?.currentClaimableTokenInWei
    const claimableToken =
      userData.hasWithdrawn === true ? convertFromWei(claimableTokenInWei, token.decimals) : firstTimeClaimable

    let fullDisplayshares = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

    if (isPurchaseableIDO(sale as any as PurchasableIDO)) {
      const { paymentToken, purchasePeriod } = sale as any as PurchasableIDO
      const { salePrice, totalPaymentReceived } = purchasePeriod
      finalTotalPaymentReceived = totalPaymentReceived
      finalSalePrice = salePrice
      fullDisplayshares = convertFromWei(new BigNumber(paymentReceivedInWei), paymentToken.decimals).dividedBy(
        finalSalePrice,
      )
    }

    if (isSubscribeableIDO(sale as any as SubscribeableIDO)) {
      const { subscribePeriod } = sale as any as SubscribeableIDO
      const { numTrackStakers = 0 } = subscribePeriod
      purchaserCount = Math.max(purchaserCount, numTrackStakers) || 0
    }
    if (fullDisplayshares.isGreaterThan(0)) {
      if (startTime && new Date(startTime) > now) {
        return (
          <ClaimStartCard
            stepRequirement={stepRequirement}
            claimDate={startTime}
            tokenIcon={image}
            tokenSymbol={symbol}
            tokenAddress={address}
            tokenDecimals={decimals}
            purchasedAmount={fullDisplayshares}
            chainId={saleChainId}
          />
        )
      }
      // in between vesting period or still has claimable token
      if (
        (startTime && endTime && new Date(startTime) < now && new Date(endTime) > now) ||
        claimableToken.isGreaterThan(0)
      ) {
        return (
          <LinearVestingClaimCard
            stepRequirement={stepRequirement}
            tokenIcon={image}
            tokenSymbol={symbol}
            tokenAddress={address}
            tokenDecimals={decimals}
            purchasedAmount={fullDisplayshares}
            saleAddress={saleAddress}
            chainId={saleChainId}
            salePrice={finalSalePrice}
            whitelistedUrl={whitelistedUrl}
            claimableToken={claimableToken}
            userAllocation={userAllocationInWei}
            saleId={saleId}
          />
        )
      }
    }

    return (
      <ClaimEndCard
        tokenIcon={image}
        claimedShare={fullDisplayshares}
        participants={purchaserCount}
        totalSales={finalTotalPaymentReceived}
        chainId={chainId}
      />
    )
  }, [userData, sale, userAllocationInWei])

  if ((account && !isSaleUserDataFetched) || isLoading) {
    return <LoadingCard />
  }
  return (
    <>
      <Box>{getClaimingState()}</Box>
    </>
  )
}

export default LinearVestClaiming
