import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { splitSignature } from '@ethersproject/bytes'
import { Contract } from '@ethersproject/contracts'
import { TransactionResponse } from '@ethersproject/providers'
import {
  Button,
  Text,
  AddIcon,
  ArrowDownIcon,
  CardBody,
  Slider,
  Box,
  Flex,
  useModal,
  useMatchBreakpoints,
  CheckmarkIcon,
  PolygonRightIcon,
} from 'uikit'
import { Box as MaterialBox } from '@mui/material'
import { RouteComponentProps } from 'react-router'
import { BigNumber } from '@ethersproject/bignumber'
import { useTranslation } from 'contexts/Localization'
import { Percent } from 'swap-sdk/entities/fractions/percent'
import { getRouterAddress } from 'utils/addressHelpers'
import { AutoColumn, ColumnCenter } from 'componentsV2/layout/Column'
import { RowBetween, RowFixed } from 'componentsV2/layout/Row'
import { Currency } from 'swap-sdk/entities/currency'
import { currencyEquals } from 'swap-sdk/entities/token'
import getGasToken from 'utils/getGasToken'
import useTheme from 'hooks/useTheme'
import { useAccount, useNetwork, useSignTypedData } from 'wagmi'
import Page from 'componentsV2/layout/Page'
import StyledInternalLink from 'componentsV2/Links'
import useDebouncedChangeHandler from 'hooks/useDebouncedChangeHandler'
import { ArrowWrapper, TruncatedText } from 'views/Swap/components/styleds'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { Plus } from 'react-feather'
import WhiteCircleLoader from 'componentsV2/Loader/WhiteCircleLoader'
import { RoundContainer } from 'views/Swap'
import gasTokens from 'config/constants/gasTokens'
import Switcher from 'componentsV2/Switcher'
import { MainTab } from 'componentsV2'
import { SWAP_TABS_MENU } from 'config/menus/tabs'

import useSwapAvailabilityCheck from 'hooks/useSwapAvailabilityCheck'

import TransactionConfirmationModal, { ConfirmationModalContent } from '../../componentsV2/TransactionConfirmationModal'
import { MinimalPositionCard } from '../../componentsV2/PositionCard'
import { AppHeader, AppBody } from '../../componentsV2/App'
import ConnectWalletButton from '../../componentsV2/ConnectWalletButton/ConnectWalletButton'
import CurrencyInputPanelV2 from '../../componentsV2/CurrencyInputPanel/CurrencyInputPanel'

import { CurrencyLogo, DoubleCurrencyLogo } from '../../componentsV2/Logo'
import { useCurrency } from '../../hooks/Tokens'
import { usePairContract, useRouterContract } from '../../hooks/useContract'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { calculateGasMargin, calculateSlippageAmount } from '../../utils'
import { currencyId } from '../../utils/currencyId'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { useBurnActionHandlers, useDerivedBurnInfo, useBurnState } from '../../state/burn/hooks'

import { Field } from '../../state/burn/actions'
import { Chain } from 'config/constants/types'

const BorderCard = styled.div<{ radius?: number }>`
  border: solid 1px ${({ theme }) => theme.colorsV2?.light};
  border-radius: ${({ radius }) => radius ?? 16}px;
  padding: 16px;
`

const StyledRowBetween = styled(RowBetween)`
  background: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.dark2)};
  padding: 10px;
  border-radius: 5px;
`

export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  useSwapAvailabilityCheck()

  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const [currencyA, currencyB] = [
    useCurrency(currencyIdA, chainId) ?? undefined,
    useCurrency(currencyIdB, chainId) ?? undefined,
  ]

  const isHMND = currencyA?.symbol === 'WeHMND' || currencyB?.symbol === 'WeHMND'
  const [tokenA, tokenB] = useMemo(
    () => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)],
    [currencyA, currencyB, chainId],
  )

  const { t } = useTranslation()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [detailedTab, setDetailedTab] = useState<number>(0)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const deadline = useTransactionDeadline()
  const allowedSlippage = 100

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
      ? '<1'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)

  const { signTypedDataAsync } = useSignTypedData()

  // allowance handling
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], getRouterAddress(chainId))

  const onAttemptToApprove = async () => {
    if (!pairContract || !pair || !deadline) throw new Error('missing dependencies')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    // try to gather a signature for permission
    const nonce = await pairContract.nonces(account)

    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' },
    ]
    const domain = {
      name: pair?.liquidityToken.name,
      version: '1',
      chainId,
      verifyingContract: pair.liquidityToken.address,
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
      { name: 'value', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'deadline', type: 'uint256' },
    ]
    const message = {
      owner: account,
      spender: getRouterAddress(chainId),
      value: liquidityAmount.raw.toString(),
      nonce: nonce.toHexString(),
      deadline: deadline.toNumber(),
    }
    const data = JSON.stringify({
      types: {
        EIP712Domain,
        Permit,
      },
      domain,
      primaryType: 'Permit',
      message,
    })

    approveCallback().catch((err) => {
      console.log('Err', err)
    })
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, value: string) => {
      setSignatureData(null)
      return _onUserInput(field, value)
    },
    [_onUserInput],
  )

  const onLiquidityInput = useCallback((value: string): void => onUserInput(Field.LIQUIDITY, value), [onUserInput])
  const onCurrencyAInput = useCallback((value: string): void => onUserInput(Field.CURRENCY_A, value), [onUserInput])
  const onCurrencyBInput = useCallback((value: string): void => onUserInput(Field.CURRENCY_B, value), [onUserInput])

  // tx sending
  const addTransaction = useTransactionAdder()
  const router = useRouterContract(chainId)

  const onRemove = useCallback(async () => {
    if (!chainId || !account || !deadline || !router) throw new Error('missing dependencies')
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0],
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    const currencyBIsETH = currencyB.isNative
    const oneCurrencyIsETH = currencyA.isNative || currencyBIsETH

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    let methodNames: string[]
    let args: Array<string | string[] | number | boolean>
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETH', 'removeLiquidityETHSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          deadline.toHexString(),
        ]
      }
      // removeLiquidity
      else {
        methodNames = ['removeLiquidity']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadline.toHexString(),
        ]
      }
    }
    // we have a signature, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = ['removeLiquidityETHWithPermit', 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens']
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(),
          amountsMin[currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ['removeLiquidityWithPermit']
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.raw.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s,
        ]
      }
    } else {
      throw new Error('Attempting to confirm without approval or a signature. Please contact support.')
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map((methodName) =>
        router.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch((err) => {
            console.error(`estimateGas failed`, methodName, args, err)
            return undefined
          }),
      ),
    )

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex((safeGasEstimate) =>
      BigNumber.isBigNumber(safeGasEstimate),
    )

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.')
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation]
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

      setAttemptingTxn(true)
      await router[methodName](...args, {
        gasLimit: safeGasEstimate,
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Remove ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencyA?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencyB?.symbol}`,
          })

          setTxHash(response.hash)
        })
        .catch((err: Error) => {
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(err)
        })
    }
  }, [
    account,
    addTransaction,
    allowedSlippage,
    approval,
    chainId,
    currencyA,
    currencyB,
    deadline,
    parsedAmounts,
    router,
    signatureData,
    tokenA,
    tokenB,
  ])

  const modalHeader = useCallback(() => {
    return (
      <AutoColumn gap="md">
        <StyledRowBetween align="flex-end">
          <RowFixed gap="0px">
            <CurrencyLogo currency={currencyA} size="24px" style={{ marginRight: '12px' }} />
            <TruncatedText fontSize="24px">{currencyA?.symbol}</TruncatedText>
          </RowFixed>
          <RowFixed gap="0px">
            <Text fontFamily="Acre" fontSize="24px" ml="10px" color="text">
              {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </StyledRowBetween>
        <AutoColumn justify="center">
          <Plus size="20" />
        </AutoColumn>
        <StyledRowBetween align="flex-end">
          <RowFixed gap="0px">
            <CurrencyLogo currency={currencyB} size="32px" style={{ marginRight: '12px' }} />
            <TruncatedText fontSize="24px">{currencyB?.symbol}</TruncatedText>
          </RowFixed>
          <RowFixed gap="0px">
            <Text fontFamily="Acre" fontSize="24px" ml="10px" color="text">
              {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </StyledRowBetween>

        <Text small textAlign="left" pt="12px">
          {t(
            'Amount received is estimated. If the price changes by more than %slippage%% your transaction will revert.',
            {
              slippage: allowedSlippage / 100,
            },
          )}
        </Text>
      </AutoColumn>
    )
  }, [allowedSlippage, currencyA, currencyB, parsedAmounts, t])

  const modalBottom = useCallback(() => {
    return (
      <AutoColumn gap="10px">
        <RowBetween marginTop="30px">
          <Text fontSize="14px">
            {t('%assetA%/%assetB% Burned', { assetA: currencyA?.symbol ?? '', assetB: currencyB?.symbol ?? '' })}
          </Text>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} margin />
            <Text fontSize="14px">{parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}</Text>
          </RowFixed>
        </RowBetween>
        {pair && (
          <>
            <RowBetween>
              <Text fontSize="14px">{t('Price')}</Text>
              <Text fontSize="14px">
                1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <div />
              <Text fontSize="14px">
                1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
              </Text>
            </RowBetween>
          </>
        )}
        <Button disabled={!(approval === ApprovalState.APPROVED || signatureData !== null)} onClick={onRemove}>
          {t('Confirm')}
        </Button>
      </AutoColumn>
    )
  }, [approval, currencyA, currencyB, onRemove, pair, parsedAmounts, signatureData, t, tokenA, tokenB])

  const pendingText = t('Removing %amountA% %symbolA% and %amountB% %symbolB%', {
    amountA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    symbolA: currencyA?.symbol ?? '',
    amountB: parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
    symbolB: currencyB?.symbol ?? '',
  })

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput],
  )

  const WGAS = getGasToken(chainId)
  const oneCurrencyIsETH = currencyA?.isNative || currencyB?.isNative
  const oneCurrencyIsWETH = Boolean(
    chainId && ((currencyA && currencyEquals(WGAS, currencyA)) || (currencyB && currencyEquals(WGAS, currencyB))),
  )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/liquidity/remove/${currencyId(currency)}/${currencyIdA}`)
      } else {
        history.push(`/liquidity/remove/${currencyId(currency)}/${currencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, history],
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/liquidity/remove/${currencyIdB}/${currencyId(currency)}`)
      } else {
        history.push(`/liquidity/remove/${currencyIdA}/${currencyId(currency)}`)
      }
    },
    [currencyIdA, currencyIdB, history],
  )

  const handleDismissConfirmation = useCallback(() => {
    setSignatureData(null) // important that we clear signature data to avoid bad sigs
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback,
  )

  const { theme } = useTheme()

  const modal = useMemo(() => {
    return (
      <TransactionConfirmationModal
        title={t('You will receive')}
        customOnDismiss={handleDismissConfirmation}
        hash={txHash || ''}
        attemptingTxn={attemptingTxn}
        pendingText={pendingText}
        content={() => <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />}
      />
    )
  }, [attemptingTxn, handleDismissConfirmation, txHash, pendingText, modalBottom, modalHeader, t])

  const [onPresentRemoveLiquidity] = useModal(modal, true, true, 'removeLiquidityModal')
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  return (
    <>
      <MainTab menu={SWAP_TABS_MENU} />
      <Page>
        <AppBody maxWidth="500px">
          <AppHeader
            backTo="/pool"
            title={t('Remove liquidity', {
              assetA: currencyA?.symbol ?? '',
              assetB: currencyB?.symbol ?? '',
            })}
            noConfig
          />
          <CardBody p={isMobile ? '20px 19px 40px' : '20px 55px 40px'}>
            <AutoColumn gap="20px">
              {detailedTab === 0 && (
                <BorderCard radius={10}>
                  <RowBetween>
                    <Text color="textSecondary">{t('Amount')}</Text>
                    <Switcher
                      options={['Simple', 'Detailed']}
                      value={detailedTab}
                      onChange={setDetailedTab}
                      size="sm"
                    />
                  </RowBetween>

                  <Text fontSize="24px" bold style={{ lineHeight: 1, marginTop: 20 }}>
                    {formattedAmounts[Field.LIQUIDITY]}
                  </Text>
                  <Slider
                    name="lp-amount"
                    min={0}
                    max={100}
                    value={innerLiquidityPercentage}
                    onValueChanged={(value) => setInnerLiquidityPercentage(Math.ceil(value))}
                  />
                  <MaterialBox
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    gap={isMobile ? '7px' : '20px'}
                  >
                    <Button
                      variant={innerLiquidityPercentage === 25 ? 'radioSelected' : 'dark2'}
                      scale="sm"
                      onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')}
                    >
                      25%
                    </Button>
                    <Button
                      variant={innerLiquidityPercentage === 50 ? 'radioSelected' : 'dark2'}
                      scale="sm"
                      onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')}
                    >
                      50%
                    </Button>
                    <Button
                      variant={innerLiquidityPercentage === 75 ? 'radioSelected' : 'dark2'}
                      scale="sm"
                      onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')}
                    >
                      75%
                    </Button>
                    <Button
                      variant={innerLiquidityPercentage === 100 ? 'radioSelected' : 'dark2'}
                      scale="sm"
                      onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                    >
                      Max
                    </Button>
                  </MaterialBox>
                </BorderCard>
              )}
            </AutoColumn>
            {detailedTab === 0 && (
              <>
                <ColumnCenter>
                  <Flex my="21px">
                    <ArrowWrapper clickable={false}>
                      <ArrowDownIcon width="24px" my="16px" />
                    </ArrowWrapper>
                  </Flex>
                </ColumnCenter>
                <AutoColumn gap="10px">
                  <BorderCard radius={10}>
                    <Flex justifyContent="space-between" mb="8px">
                      <Flex>
                        <CurrencyLogo currency={currencyA} size="20px" />
                        <Text small color="textSubtle" id="remove-liquidity-tokena-symbol" ml="10px">
                          {currencyA?.symbol}
                        </Text>
                      </Flex>
                      <Text small>{formattedAmounts[Field.CURRENCY_A] || '-'}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Flex>
                        <CurrencyLogo currency={currencyB} size="20px" />
                        <Text small color="textSubtle" id="remove-liquidity-tokenb-symbol" ml="10px">
                          {currencyB?.symbol}
                        </Text>
                      </Flex>
                      <Text small>{formattedAmounts[Field.CURRENCY_B] || '-'}</Text>
                    </Flex>
                    {chainId &&
                    (oneCurrencyIsWETH || oneCurrencyIsETH) &&
                    chainId !== Chain.HUMANODE_TESTNET &&
                    chainId !== Chain.HUMANODE_MAINNET ? (
                      <RowBetween style={{ justifyContent: 'flex-end', fontSize: '14px' }}>
                        {oneCurrencyIsETH ? (
                          <StyledInternalLink
                            to={`/liquidity/remove/${currencyA.isNative ? WGAS.address : currencyIdA}/${
                              currencyB.isNative ? WGAS.address : currencyIdB
                            }`}
                          >
                            {t(`Receive ${WGAS.symbol}`)}
                          </StyledInternalLink>
                        ) : oneCurrencyIsWETH ? (
                          <StyledInternalLink
                            to={`/liquidity/remove/${
                              currencyA && currencyEquals(currencyA, WGAS) ? gasTokens[chainId] : currencyIdA
                            }/${currencyB && currencyEquals(currencyB, WGAS) ? gasTokens[chainId] : currencyIdB}`}
                          >
                            {t(`Receive ${gasTokens[chainId]}`)}
                          </StyledInternalLink>
                        ) : null}
                      </RowBetween>
                    ) : null}
                  </BorderCard>
                </AutoColumn>
              </>
            )}

            {detailedTab === 1 && (
              <Box my="16px">
                <RowBetween marginBottom={2}>
                  <Text>{t('Amount')}</Text>
                  <Switcher options={['Simple', 'Detailed']} value={detailedTab} onChange={setDetailedTab} size="sm" />
                </RowBetween>

                <MaterialBox textAlign="center" my={2}>
                  <Text fontWeight="bold" fontSize="42px">
                    {innerLiquidityPercentage}%
                  </Text>
                </MaterialBox>

                <CurrencyInputPanelV2
                  value={formattedAmounts[Field.LIQUIDITY]}
                  onUserInput={onLiquidityInput}
                  onMaxClicked={() => {
                    onUserInput(Field.LIQUIDITY_PERCENT, '100')
                  }}
                  hideMaxButton={!!atMaxAmount}
                  enableCurrencySelect={false}
                  currency={pair?.liquidityToken}
                  onCurrencySelect={() => null}
                  label={t('Input')}
                  isLP
                  LPcurrency0={pair?.token0}
                  LPcurrency1={pair?.token1}
                />
                <ColumnCenter>
                  <Flex my="21px">
                    <ArrowWrapper clickable={false}>
                      <ArrowDownIcon width="24px" my="16px" />
                    </ArrowWrapper>
                  </Flex>
                </ColumnCenter>
                <CurrencyInputPanelV2
                  value={formattedAmounts[Field.CURRENCY_A]}
                  onUserInput={onCurrencyAInput}
                  onMaxClicked={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                  hideMaxButton={!!atMaxAmount}
                  currency={currencyA}
                  label={t('Output')}
                  onCurrencySelect={handleSelectCurrencyA}
                />
                <ColumnCenter>
                  <ArrowWrapper clickable={false}>
                    <AddIcon width="16px" my="16px" />
                  </ArrowWrapper>
                </ColumnCenter>
                <CurrencyInputPanelV2
                  value={formattedAmounts[Field.CURRENCY_B]}
                  onUserInput={onCurrencyBInput}
                  onMaxClicked={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                  hideMaxButton={!!atMaxAmount}
                  currency={currencyB}
                  label={t('Output')}
                  onCurrencySelect={handleSelectCurrencyB}
                />
              </Box>
            )}
            {pair && (
              <AutoColumn gap="10px" style={{ marginTop: '16px', padding: detailedTab === 1 ? '20px' : '16px' }}>
                <Flex justifyContent="space-between">
                  <IFTypography ifcolor="textSecondary" variant="body2">
                    {t('Prices')}
                  </IFTypography>
                  <Box>
                    <Flex justifyContent="right">
                      <IFTypography ifcolor="textSecondary" variant="body2">
                        1 {currencyA?.symbol} =
                      </IFTypography>
                      <IFTypography ifcolor="textSecondary" variant="body2" style={{ whiteSpace: 'pre' }}>
                        {tokenA ? ` ${pair.priceOf(tokenA).toSignificant(6)}` : ' -'} {currencyB?.symbol}
                      </IFTypography>
                    </Flex>
                    <Flex justifyContent="right">
                      <IFTypography ifcolor="textSecondary" variant="body2">
                        1 {currencyB?.symbol} =
                      </IFTypography>
                      <IFTypography ifcolor="textSecondary" variant="body2" style={{ whiteSpace: 'pre' }}>
                        {tokenB ? ` ${pair.priceOf(tokenB).toSignificant(6)}` : ' -'} {currencyA?.symbol}
                      </IFTypography>
                    </Flex>
                  </Box>
                </Flex>
              </AutoColumn>
            )}
            <Box position="relative" mt="16px">
              {!account ? (
                <ConnectWalletButton />
              ) : (
                <Flex flexDirection={isMobile ? 'column' : 'row'}>
                  <Button
                    variant={approval === ApprovalState.APPROVED || signatureData !== null ? 'success' : 'primary'}
                    onClick={onAttemptToApprove}
                    isLoading={approval === ApprovalState.PENDING}
                    disabled={approval !== ApprovalState.NOT_APPROVED || signatureData !== null}
                    width="100%"
                    startIcon={
                      approval === ApprovalState.PENDING ? (
                        <WhiteCircleLoader size="24px" />
                      ) : (
                        <RoundContainer
                          disabled={approval !== ApprovalState.NOT_APPROVED}
                          filled={approval === ApprovalState.APPROVED}
                        >
                          {approval === ApprovalState.APPROVED ? (
                            <CheckmarkIcon width="14px" color={theme.colorsV2?.textDisabled} />
                          ) : (
                            '1'
                          )}
                        </RoundContainer>
                      )
                    }
                  >
                    {approval === ApprovalState.PENDING
                      ? t('Approving')
                      : approval === ApprovalState.APPROVED
                      ? t('Approved')
                      : t('Approve')}
                  </Button>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    px="8px"
                    py="3px"
                    style={{ transform: isMobile && 'rotate(90deg)' }}
                  >
                    <PolygonRightIcon color={theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.textDisabled} />
                  </Flex>
                  <Button
                    startIcon={
                      <RoundContainer
                        disabled={
                          !isValid || approval !== ApprovalState.APPROVED || formattedAmounts[Field.LIQUIDITY] === '0'
                        }
                      >
                        2
                      </RoundContainer>
                    }
                    variant={
                      !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                        ? 'danger'
                        : 'primary'
                    }
                    onClick={() => {
                      onPresentRemoveLiquidity()
                    }}
                    width="100%"
                    id="swap-button"
                    disabled={
                      !isValid ||
                      (signatureData === null && approval !== ApprovalState.APPROVED) ||
                      formattedAmounts[Field.LIQUIDITY] === '0'
                    }
                  >
                    {error || t('Remove')}
                  </Button>
                </Flex>
              )}
            </Box>
          </CardBody>
        </AppBody>

        {pair ? (
          <AutoColumn
            style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', margin: 'auto', marginTop: '1rem' }}
          >
            <MinimalPositionCard
              showUnwrapped={oneCurrencyIsWETH && ![Chain.HUMANODE_TESTNET, Chain.HUMANODE_MAINNET].includes(chainId)}
              pair={pair}
            />
          </AutoColumn>
        ) : null}
      </Page>
    </>
  )
}
