import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import IFTypography from 'componentsV2/IFTypography'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from 'uikit'
import { CompanyContext } from 'contexts/CompanyContext'
import Backdrop from '@mui/material/Backdrop'
import Logo from './Logo'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

const ModalBox = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.modal.background};
  right: 0;
  width: 60vw;
  height: 100%;
  outline: none;
  padding: 30px 20px 30px 20px;
  backdropfilter: 0;
`

const NodesBox = styled(Box)`
  background: ${({ theme }) => theme.colorsV2.dark};
  width: 100%;
  border-radius: 10px;
  padding: 10px;
`

interface SidebarMenuTypes {
  open: boolean
  purchasedNodesCount: number
  setShowSidebarMenu: (e: boolean) => void
  setOpenModalNodes: (e: boolean) => void
}

export default function SidebarMenu({
  open,
  setShowSidebarMenu,
  purchasedNodesCount,
  setOpenModalNodes,
}: SidebarMenuTypes) {
  const { isJustSale } = useContext(CompanyContext)
  const { isDark, theme } = useTheme()
  const location = useLocation()
  const history = useHistory()

  const handleClose = () => {
    setShowSidebarMenu(false)
  }

  return (
    <>
      <Modal
        components={{ Backdrop }}
        componentsProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(101,72,35, 0.35)',
              backdropFilter: 'blur(8px)',
            },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <ModalBox style={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="start">
              <Logo isDark={isDark} isMobile={true} href="/" />
              <IconButton variant="text" onClick={() => handleClose()}>
                <CloseIcon sx={{ fontSize: '24px' }} />
              </IconButton>
            </Box>

            <NodesBox
              onClick={() => {
                setOpenModalNodes(true)
                handleClose()
              }}
              sx={{
                my: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IFTypography fontSize="12px" color={theme.colorsV2.textDisabled}>
                Total purchased
              </IFTypography>
              <IFTypography
                fontSize="18px"
                color={purchasedNodesCount ? theme.colorsV2.text : theme.colorsV2.textDisabled}
              >
                {purchasedNodesCount ? purchasedNodesCount : '-'} Node&#40;s&#41;
              </IFTypography>
            </NodesBox>

            {!purchasedNodesCount && (
              <Box display="flex" justifyContent="center" alignItems="center" textAlign="center" px={5}>
                <IFTypography fontSize="9px" color={theme.colorsV2.textDisabled}>
                  Once you&apos;ve purchased a node, it will appear here.
                </IFTypography>
              </Box>
            )}
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="start" my={10} gap={6}>
            <IFTypography
              onClick={() => {
                history.push('/')
                handleClose()
              }}
              fontWeight="bold"
              fontSize="18px"
              color={location.pathname === '/' ? theme.colorsV2.textThirdly : theme.colorsV2.textDisabled}
            >
              Node Sale
            </IFTypography>

            <IFTypography
              onClick={() => {
                history.push('/referral')
                handleClose()
              }}
              fontWeight="bold"
              fontSize="18px"
              color={location.pathname === '/referral' ? theme.colorsV2.textThirdly : theme.colorsV2.textDisabled}
            >
              Referral Rewards
            </IFTypography>
          </Box>
        </ModalBox>
      </Modal>
    </>
  )
}
