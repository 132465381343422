import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styled, { useTheme } from 'styled-components'
import IFTypography from 'componentsV2/IFTypography'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Button, useMatchBreakpoints } from 'uikit'
import { Input } from '@mui/material'
import { useAccount } from 'wagmi'
import { CompanyContext } from 'contexts/CompanyContext'
import { useFetchPromoCode } from 'hooks/useFetchPromoCode'
import { PROMO_CODES, AETHIR_CODES } from '../consts'
import Backdrop from '@mui/material/Backdrop'

const ModalBox = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.modal.background};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 90vw;
  border-radius: 20px;
  box-sizing: border-box;
`
const InputDiv = styled(Input)`
  background: ${({ theme }) => theme.colorsV2.dark};
  color: #fafafa;
  flex-shrink: 0;
  padding: 10px 20px;
  display: flex;
  width: 100%;
  max-width: 428px;
  opacity: 0.8;
  align-items: center;
  border: 0.5px solid ${({ theme }) => theme.colorsV2.light};
  margin-bottom: 10px;
  border-radius: 20px !important;
`

const PromoButton = styled(Button)<{ isDirty: boolean; isJustSale: boolean }>`
  color: '#ffffff';
  width: 140px;
  ${({ theme }) => theme.mediaQueries?.md} {
    width: 190px;
  }
  height: 44px;
  padding: 10.5px 10px 8.5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
  background: ${({ isDirty, theme, isJustSale }) =>
    isJustSale
      ? theme.colorsV2.button.primaryBg
      : isDirty
      ? theme.colorsV2.gradients.blue2indigo
      : theme.colorsV2.light};
`

const CancelButton = styled(Button)<{ isDirty: boolean; isJustSale: boolean }>`
  color: ${({ theme, isJustSale }) => (isJustSale ? theme.colorsV2.roiCalculator.footerHeaderText : '#ffffff')};
  width: 140px;
  ${({ theme }) => theme.mediaQueries?.md} {
    width: 190px;
  }
  height: 44px;
  padding: 10.5px 10px 8.5px 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme, isJustSale }) => isJustSale && theme.colorsV2.roiCalculator.footerHeaderText};
  border-radius: 21px;
  font-weight: 700;
  background: ${({ theme, isJustSale }) => (isJustSale ? theme.colorsV2.main : theme.colorsV2.textDisabled)};
`
interface ModalPromoTypes {
  saleAddress: string
  open: boolean
  setOpenModalPromo: (e: boolean) => void
  setDiscountActive: (e: boolean) => void
  setPromoCode: (e: string) => void
  setPromoRes: any
}
export default function ModalPromo({
  open,
  setOpenModalPromo,
  setDiscountActive,
  setPromoCode: setPromoCodeParent,
  saleAddress,
  setPromoRes,
}: ModalPromoTypes) {
  const theme = useTheme()
  const [promoCode, setPromoCode] = useState('')
  const { address } = useAccount()
  const [errorMessage, setErrorMessage] = useState('')
  const { fetchPromo } = useFetchPromoCode(saleAddress)
  const { isJustSale, isHardCodedRef, brand } = useContext(CompanyContext)
  const promoCodeList = PROMO_CODES?.[brand] || AETHIR_CODES

  const handleInputPromoCode = (e) => setPromoCode(e.target.value ? e.target.value : '')
  const handleClose = () => {
    setPromoCode('')
    setErrorMessage('')
    setOpenModalPromo(false)
  }

  const handleApplyPromo = async () => {
    const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/
    const sameAddress = address.toLowerCase() === promoCode.toLowerCase()
    const isValidAddress = !sameAddress && ethereumAddressRegex.test(promoCode)

    if (sameAddress) {
      setErrorMessage('Cannot use your own address.')
      return
    }

    const promo = await fetchPromo(promoCode)

    if ((promoCodeList[promoCode.toLowerCase()] && isHardCodedRef && isValidAddress) || promo) {
      setDiscountActive(true)
      setPromoCodeParent(promoCode.toLowerCase())
      setPromoRes(promo)
      handleClose()
    } else {
      setErrorMessage('Invalid promo code.')
    }
  }

  const isDirty = promoCode.length >= 1
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false

  return (
    <Modal
      open={open}
      onClose={handleClose}
      components={{ Backdrop }}
      componentsProps={{
        backdrop: {
          sx: {
            backgroundColor: isJustSale ? 'rgba(101,72,35, 0.35)' : '',
            backdropFilter: isJustSale ? 'blur(8px)' : '',
          },
        },
      }}
    >
      <ModalBox>
        <Box margin={isMobile ? '20px 12px 20px 16px' : '30px 30px 10px 40px'}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IFTypography variant={isMobile ? 'h6' : 'h3'} fontWeight="bold">
              Add promo code
            </IFTypography>
            <IconButton variant="text" onClick={() => handleClose()}>
              <CloseIcon sx={{ fontSize: '34px' }} />
            </IconButton>
          </Box>
        </Box>
        {!process.env.REACT_APP_ENABLE_TIERED_CONTRACT && (
          <Box margin={isMobile ? '20px 12px 20px 16px' : '30px 30px 10px 40px'}>
            <IFTypography variant={'body2'} ifcolor="textSecondary">
              Referral code (wallet address format) will not be automatically applied. Rebate will only be approved and
              airdropped by XX upon confirmation referrer has purchased a node.
            </IFTypography>
          </Box>
        )}
        <Box display="flex" padding={isMobile ? '0 16px' : '20px 36px'}>
          <InputDiv
            sx={{ color: theme.isDark ? theme.colorsV2.text : '#5A5A5A' }}
            disableUnderline
            placeholder="Enter code"
            value={promoCode}
            onChange={handleInputPromoCode}
          />
        </Box>
        <Box style={{ visibility: errorMessage?.length > 0 ? 'visible' : 'hidden' }} ml="40px">
          <IFTypography variant="body2" ifcolor="danger">
            {errorMessage}
          </IFTypography>
        </Box>

        <Box
          display="flex"
          gap="20px"
          alignItems="center"
          justifyContent="center"
          margin={isMobile ? '10px 30px 30px 30px' : '40px 50px 30px 50px'}
        >
          <CancelButton onClick={handleClose} isJustSale={isJustSale}>
            Cancel
          </CancelButton>
          <PromoButton isDirty={isDirty} onClick={handleApplyPromo} isJustSale={isJustSale} disabled={!isDirty}>
            Apply
          </PromoButton>
        </Box>
      </ModalBox>
    </Modal>
  )
}
