import React, { useContext, useState, useMemo } from 'react'
import styled from 'styled-components'
import { DownIcon } from 'uikit/components/Svg/index'
import { useAccountSidebar } from 'uikit/widgets/AccountSidebar/AccountSidebarContext'
import { Flex } from 'uikit'
import { walletLocalStorageKey } from 'uikit/widgets/WalletModal/config'
import { Box } from '@mui/material'
import { NetworkSelector } from 'componentsV2/NetworkSelector'
import ConnectWalletButton from 'componentsV2/ConnectWalletButton/ConnectWalletButton'

import { WalletFullWhiteIcon } from '../icons'
import Button from '../../../components/Button/Button'
import { Login, TokenBalance, KycInfo, Network } from '../../WalletModal/types'
import RoninWalletIcon from '../icons/RoninWalletIcon'
import { Brands, CompanyContext } from 'contexts/CompanyContext'
import useTheme from 'hooks/useTheme'
import ModalNodes from 'componentsV2/Card/PurchaseCard/components/ModalNodes'
import SidebarMenu from './SidebarMenu'
import { useSaleUserDataMap } from 'state/idos/hooks'
interface Props {
  account?: string
  useGasBalance?: () => string
  gasToken?: string
  login: Login
  logout: () => void
  isMobile: boolean
  balances?: TokenBalance[]
  kycInfo?: KycInfo
  networks?: Network[]
  isNetworkUnavailable?: boolean
  showNetworks?: boolean
  showAccount?: boolean
}

const UserBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0 8px;
`

const NetworkButton = styled(Button)<{ backgroundColor: string }>`
  background: ${({ theme, backgroundColor }) =>
    theme.isDark ? `${backgroundColor}99` : `${backgroundColor}80`} !important;
  padding: 0 6px 0 12px;
  border-radius: 47px 0px 0px 47px;
  color: ${({ theme }) => theme.colorsV2?.text};
  margin-right: 4px;
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: center;
  @media (max-width: 850px) {
    padding: 0px 6px 0px 10px;
  }
`

/* background: linear-gradient(90deg, #3580fe -2.21%, #a633fe 100%); */
const AccountWrapper = styled.div<{ isRonin: boolean }>`
  background: ${({ theme }) => theme.colorsV2.gradients.walletMenuBg};
  border-radius: 12px;
  padding: 11px;
  display: flex;
  height: ${({ isRonin }) => (isRonin ? '42px' : '44px')};
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const BnbBalance = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 5px;
  ${({ theme }) => theme.mediaQueries?.sm} {
    padding: 0 9px 0 5px;
  }
  font-weight: 700;
`

const IconWrapper = styled(Flex)`
  color: ${({ theme }) => theme.colorsV2?.text};
  margin-right: 6px;
`

const selectorOptions = {
  desktopSelectorStyle: {
    containerWidth: '380px',
  },
}

const UserBlock: React.FC<Props> = ({
  account,
  useGasBalance,
  gasToken,
  login,
  logout,
  isMobile,
  balances = [],
  kycInfo,
  networks = [],
  isNetworkUnavailable,
  showNetworks,
  showAccount,
}) => {
  const walletID = window.localStorage.getItem(walletLocalStorageKey)
  // eslint-disable-next-line
  const gasBalance = useGasBalance && useGasBalance()
  const currentNetwork = networks.find((network) => network.isCurrent)
  const { openAccountSidebar } = useAccountSidebar()
  const { brand } = useContext(CompanyContext)
  const isRonin = brand === Brands.RONIN
  const { theme } = useTheme()
  const [openModalNodes, setOpenModalNodes] = useState(false)
  const [showSidebarMenu, setShowSidebarMenu] = useState(false)
  const saleUserDataMap = useSaleUserDataMap()

  const { purchasedNodes, purchasedNodesCount } = useMemo(() => {
    const purchasedNodes = []

    Object.values(saleUserDataMap).forEach((obj: any) => {
      const amountPurchased = Number(obj.amountPurchasedByTier.amount)
      if (amountPurchased > 0) {
        purchasedNodes.push({
          amountPurchased,
          tier: obj.amountPurchasedByTier.tier,
        })
      }
    })

    const purchasedNodesCount = purchasedNodes.reduce((a: number, b: any) => a + b.amountPurchased, 0)

    return { purchasedNodes, purchasedNodesCount }
  }, [saleUserDataMap, account])

  const renderButton = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        {account && !isMobile && brand === Brands.MOONVEIL && (
          <Button
            onClick={() => {
              setOpenModalNodes(true)
            }}
            disabled={!purchasedNodesCount}
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '12px',
              color: purchasedNodesCount ? theme.colorsV2.text : theme.colorsV2.textDisabled,
              justifyContent: 'space-between',
              backgroundColor: theme.colorsV2.dark,
              gap: '6px',
              padding: '10px',
              fontSize: '16px',
              height: '32px',
            }}
          >
            <span>{purchasedNodesCount ? purchasedNodesCount : '-'} Node&#40;s&#41;</span>
            <IconWrapper>
              <DownIcon color={purchasedNodesCount ? theme.colorsV2.text : theme.colorsV2.textDisabled} />
            </IconWrapper>
          </Button>
        )}

        <ConnectWalletButton showWalletIcon />

        {account && showAccount && (
          <UserBlockWrapper>
            {showNetworks && currentNetwork && (
              <NetworkSelector currentNetwork={currentNetwork} options={selectorOptions}>
                <NetworkButton backgroundColor={currentNetwork.backgroundColor}>
                  <Flex flexDirection="row" alignItems="center">
                    <img style={{ maxWidth: 'inherit' }} width="20px" alt="NetworkIcon" src={currentNetwork.iconSrc} />
                    <BnbBalance>
                      {!isMobile && gasBalance} {gasToken}
                    </BnbBalance>
                    {!isMobile && (
                      <IconWrapper>
                        <DownIcon color={purchasedNodesCount ? theme.colorsV2.text : theme.colorsV2.textDisabled} />
                      </IconWrapper>
                    )}
                  </Flex>
                </NetworkButton>
              </NetworkSelector>
            )}
            <AccountWrapper isRonin={isRonin} onClick={openAccountSidebar}>
              <Button
                scale="sm"
                style={{
                  padding: 0,
                  background: 'transparent',
                }}
              >
                {isRonin || brand === Brands.BEAM ? <RoninWalletIcon /> : <WalletFullWhiteIcon />}
              </Button>
            </AccountWrapper>
          </UserBlockWrapper>
        )}
        {isMobile && brand === Brands.MOONVEIL && (
          <>
            <SidebarMenu
              purchasedNodesCount={purchasedNodesCount}
              setOpenModalNodes={setOpenModalNodes}
              open={showSidebarMenu}
              setShowSidebarMenu={setShowSidebarMenu}
            />
            <svg
              onClick={() => setShowSidebarMenu(true)}
              width="22"
              height="16"
              viewBox="0 0 22 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.6 1.95122H1.4C0.760002 1.95122 0.333336 1.56098 0.333336 0.97561C0.333336 0.390244 0.760002 0 1.4 0H20.6C21.24 0 21.6667 0.390244 21.6667 0.97561C21.6667 1.56098 21.24 1.95122 20.6 1.95122Z"
                fill="#F6F6F6"
              />
              <path
                d="M20.6 8.97563H1.4C0.760002 8.97563 0.333336 8.58539 0.333336 8.00002C0.333336 7.41466 0.760002 7.02441 1.4 7.02441H20.6C21.24 7.02441 21.6667 7.41466 21.6667 8.00002C21.6667 8.58539 21.24 8.97563 20.6 8.97563Z"
                fill="#F6F6F6"
              />
              <path
                d="M20.6 15.9991H1.4C0.760002 15.9991 0.333336 15.6088 0.333336 15.0235C0.333336 14.4381 0.760002 14.0479 1.4 14.0479H20.6C21.24 14.0479 21.6667 14.4381 21.6667 15.0235C21.6667 15.6088 21.24 15.9991 20.6 15.9991Z"
                fill="#F6F6F6"
              />
            </svg>
          </>
        )}
      </Box>
    )
  }

  return (
    <div>
      <ModalNodes
        open={openModalNodes}
        setOpenModalNodes={setOpenModalNodes}
        purchasedNodesCount={purchasedNodesCount}
        purchasedNodes={purchasedNodes}
      />
      {renderButton()}
    </div>
  )
}

export default React.memo(
  UserBlock,
  (prevProps, nextProps) => prevProps.account === nextProps.account && prevProps.balances === nextProps.balances,
)
