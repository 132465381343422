import React, { useContext, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import styled, { useTheme } from 'styled-components'
import { Button } from 'uikit'
import CircleLoader from 'componentsV2/Loader/CircleLoader'
import { useMatchBreakpoints } from 'uikit/hooks'

import getThemeValue from '../../util/getThemeValue'
import { ModalBody, ModalHeader, ModalTitle, ModalContainer, ModalCloseButton, ModalBackButton } from './styles'
import { ModalV2Props } from './types'
import { CompanyContext, Brands } from 'contexts/CompanyContext'

export const ModalTitleText = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: ${({ theme }) => theme.colorsV2?.text};
  ${({ theme }) => theme.mediaQueries?.sm} {
    font-size: 24px;
  }
`
const ModalBodyBox = styled(Box)<{ $disableScroll?: boolean; $fixHeaderMobile?: boolean; padding: string[] }>`
  padding: ${({ padding }) => padding[0]};
  ${({ $disableScroll, $fixHeaderMobile }) =>
    !$disableScroll &&
    $fixHeaderMobile &&
    `
      max-height: calc(80vh - 85px);
      overflow-y: auto
    `};

  ${({ theme }) => theme.mediaQueries?.sm} {
    padding: ${({ padding }) => padding[1]};
    max-height: calc(90vh - 170px);
    ${({ $disableScroll }) => !$disableScroll && `overflow-y: auto;`}
  }
`

const ModalBodyDescription = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colorsV2?.textSecondary};
`

const ModalBodySubtitle = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
  line-height: 20px;
  color: ${({ theme }) => theme.colorsV2?.text};
  ${({ theme }) => theme.mediaQueries?.sm} {
    font-weight: 600;
  }
`

const ModalBodyImage = styled.div<{ margin?: string }>`
  text-align: center;
  ${({ margin }) => margin && `margin: ${margin};`}
`

const ModalBodyTitle = styled.div<{ isJustSale?: boolean; isRonin?: boolean }>`
  font-weight: ${({ isJustSale, isRonin }) => (isJustSale || isRonin ? '700' : '600')};
  font-size: ${({ isJustSale, isRonin }) => (isJustSale || isRonin ? '24px' : '18px')};
  text-align: center;
  margin-top: 12px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colorsV2?.text};
  font-family: ${({ isJustSale, isRonin }) => (isJustSale || isRonin ? 'Roboto' : 'Acre')};
  ${({ theme }) => theme.mediaQueries?.sm} {
    font-size: 24px;
  }
`

const ModalBodyText = styled(ModalBody)`
  color: ${({ theme }) => theme.colorsV2?.text};
  overflow: hidden;
`

export const ModalBackdrop = styled.div<{ isNodeSale: boolean }>`
  background-color: rgba(101, 72, 35, 0.35);
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndices.modal - 1};
`

const ModalV2: React.FC<ModalV2Props> = ({
  title = ' ',
  confirmText = 'Confirm',
  cancellText = 'Cancel',
  showCancel,
  showConfirm,
  enableConfirm = true,
  bodyImageSrc,
  showLoader,
  bodyImage,
  bodyTitle,
  bodySubtitle,
  bodyDescription,
  onConfirm,
  onDismiss,
  onBack,
  children,
  overflowMobile = 'auto',
  hideCloseButton = false,
  bodyPadding = '24px',
  modalBodyPadding = ['0 20px 60px 20px', '0 40px 40px 40px'],
  headerBackground = 'transparent',
  minContainerWidth = '500px',
  maxHeight,
  fixHeaderMobile = true,
  bodyImageWidth = 140,
  bodyImageMargin = null,
  bodyMargin = null,
  disableScroll,
  maxButtonWidth,
  headerStyle = null,
  buttonStyle = null,
  confirmStyle,
  onCancel,
  ...props
}) => {
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'hidden'
    return () => {
      body.style.removeProperty('overflow')
    }
  }, [])
  const theme = useTheme()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  const showButtons = showCancel || showConfirm
  const { isJustSale, brand, isNodeSale } = useContext(CompanyContext)
  const isRonin = brand === Brands.RONIN

  const modalRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const path = e.composedPath()
      if (modalRef.current && !path.includes(modalRef.current)) {
        onDismiss()
      }
    }

    if (isNodeSale) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      if (isNodeSale) {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [onDismiss])

  return (
    <>
      {isNodeSale && <ModalBackdrop isNodeSale={isNodeSale} />}
      <ModalContainer
        ref={modalRef}
        minContainerWidth={minContainerWidth}
        maxHeight={maxHeight}
        overflowMobile={overflowMobile}
        $fixHeaderMobile={fixHeaderMobile}
        {...props}
      >
        <ModalHeader
          background={getThemeValue(`colors.${headerBackground}`, headerBackground)(theme)}
          style={headerStyle}
        >
          <ModalTitle>
            {onBack && <ModalBackButton onBack={onBack} />}
            <ModalTitleText>{title}</ModalTitleText>
          </ModalTitle>

          {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
        </ModalHeader>
        <ModalBodyBox $disableScroll={disableScroll} $fixHeaderMobile={fixHeaderMobile} padding={modalBodyPadding}>
          {showLoader && (
            <ModalBodyImage margin={bodyImageMargin}>
              <CircleLoader size="36px" />
            </ModalBodyImage>
          )}
          {bodyImageSrc && (
            <ModalBodyImage margin={bodyImageMargin}>
              <img width={bodyImageWidth} src={bodyImageSrc} alt="bdyImage" />
            </ModalBodyImage>
          )}
          {bodyImage && <ModalBodyImage margin={bodyImageMargin}>{bodyImage}</ModalBodyImage>}
          {bodyTitle && (
            <ModalBodyTitle isJustSale={isJustSale} isRonin={isRonin}>
              {bodyTitle}
            </ModalBodyTitle>
          )}
          {bodySubtitle && <ModalBodySubtitle>{bodySubtitle}</ModalBodySubtitle>}
          {bodyDescription && <ModalBodyDescription>{bodyDescription}</ModalBodyDescription>}

          {children && (
            <ModalBodyText p={bodyPadding} margin={bodyMargin}>
              {children}
            </ModalBodyText>
          )}
          {showButtons && (
            <Box display="flex" justifyContent="center" columnGap={isMobile ? '15px' : '20px'} style={buttonStyle}>
              {showCancel && (
                <Button
                  variant="secondary"
                  scale="lg"
                  onClick={() => {
                    onCancel?.()
                    onDismiss()
                  }}
                  fullWidth
                  maxWidth={maxButtonWidth}
                >
                  {cancellText}
                </Button>
              )}
              {showConfirm && (
                <Button
                  variant="primary"
                  scale="lg"
                  onClick={onConfirm}
                  fullWidth
                  maxWidth={maxButtonWidth}
                  disabled={!enableConfirm}
                  style={confirmStyle}
                >
                  {confirmText}
                </Button>
              )}
            </Box>
          )}
        </ModalBodyBox>
      </ModalContainer>
    </>
  )
}

export default ModalV2
