import { Brands } from 'contexts/CompanyContext'

export const AETHIR_CODES = {
  '393938': true,
  '@liaoblove': true,
  '02han': true,
  '0xaur': true,
  '0xcdresearch': true,
  '0xgpu': true,
  '0xhobo_aethir': true,
  '0xmrdq': true,
  '1000iq': true,
  '10offaethir': true,
  '1min': true,
  '1minute': true,
  '2tictac2': true,
  '3nyoomkongz': true,
  '3poch2025': true,
  '7708youko': true,
  '98kdao': true,
  'a-z lowercase': true,
  a2finance: true,
  a2pad: true,
  a8_18: true,
  aa12345678: true,
  abcde1234: true,
  abel: true,
  abhi: true,
  abruzy: true,
  achen: true,
  acquirefi: true,
  adm: true,
  aethermoon: true,
  aethir_nodes_purechaincapital: true,
  'aethir.ers.crypto': true,
  aethir2moon: true,
  aethirnode: true,
  aethirnodos: true,
  afwg1114: true,
  ag: true,
  aiapaekkongz: true,
  aidenjohnson: true,
  airdropcrypto: true,
  airdroplist: true,
  airdrops: true,
  ak: true,
  al: true,
  alanrog: true,
  alekseipetrov: true,
  alevator: true,
  alexcrypto: true,
  alexeiivanov: true,
  alexeitanaka: true,
  alexzhang: true,
  alfadao: true,
  alienswap2024: true,
  alphatrace: true,
  alphax: true,
  altcoindaily: true,
  altcryptogems: true,
  altology: true,
  ameercoinstore11: true,
  aminlams: true,
  anderson2024: true,
  andreiivanov: true,
  andy: true,
  angelo: true,
  animocaventures: true,
  anson666: true,
  ap3rture: true,
  apac_partner: true,
  apaccn: true,
  apacjp: true,
  apackr: true,
  apacwink: true,
  aphexkongz: true,
  apple: true,
  arc: true,
  arndxt: true,
  arthurg: true,
  arturguimaraesp: true,
  arturkongz: true,
  as: true,
  ashcrypto: true,
  astronaut: true,
  at: true,
  ath2: true,
  atm: true,
  aussiecapital: true,
  avachen: true,
  aw: true,
  axcrypto: true,
  axel: true,
  jacob: true,
  ayasuzuki: true,
  aylo: true,
  az: true,
  aza: true,
  b2188: true,
  baby: true,
  bacon: true,
  baekho: true,
  baeko: true,
  baiye9958: true,
  bamil: true,
  basedvc: true,
  bbh: true,
  bblock: true,
  bci: true,
  bcinnovations: true,
  bcspc: true,
  bde: true,
  bdweb3: true,
  beanstock: true,
  belabs: true,
  ben_g: true,
  beriheng168: true,
  berkut: true,
  bhmain20: true,
  bhventure: true,
  bigmferkongz: true,
  binanciansvn: true,
  bipzy: true,
  bitaxis: true,
  bitcoach: true,
  bitcoinaddict: true,
  bitedge: true,
  bitgenius: true,
  bitlogic: true,
  bitmatrix: true,
  bitnest: true,
  bitnova: true,
  bitpan: true,
  bitpulse: true,
  bitquest: true,
  bitsecure: true,
  bittrust: true,
  bitworks: true,
  blackbeard: true,
  blackdragon: true,
  blaze420_nodes: true,
  blg: true,
  blockcore: true,
  blockfund: true,
  blockgenius: true,
  blockhead: true,
  blocklogic: true,
  blockmatrix: true,
  blocknova: true,
  blockprime: true,
  blockpulse: true,
  blocksecure: true,
  blocktech: true,
  blocktvbee: true,
  blockwave: true,
  blockwise: true,
  blokdrops: true,
  bluezulukongz: true,
  bmg: true,
  bmt: true,
  bobkonczak: true,
  bojiwkongz: true,
  bojoalpha: true,
  bolt: true,
  bossmj: true,
  boxmining: true,
  bpexchange: true,
  brianhuang: true,
  broleonaus: true,
  bryanross: true,
  bryanrosswins: true,
  bslabs: true,
  btc: true,
  btc100k: true,
  btcapital: true,
  btcdayu2024: true,
  btr: true,
  buildervc: true,
  bullrun: true,
  butyobutyobutyo: true,
  bwlabs: true,
  bz: true,
  c4: true,
  cagy: true,
  calman: true,
  capta: true,
  cath: true,
  cci: true,
  ccl: true,
  cct: true,
  cctamil100: true,
  cek: true,
  celabs: true,
  cen_1234: true,
  central: true,
  cerberus: true,
  cgdoge: true,
  cgtech: true,
  chacha: true,
  chain_broker: true,
  chainbolt: true,
  chaincore: true,
  chainedge: true,
  chaingen: true,
  chainglobe: true,
  chainkey: true,
  chainmatrix: true,
  chainprime: true,
  chainpulse: true,
  chainsecure: true,
  chainspark: true,
  chainvault: true,
  chainworks: true,
  chargedcrypto: true,
  charlesaethirnode: true,
  chase: true,
  cheese: true,
  cheongchun: true,
  chicokongz: true,
  chinn13991: true,
  chloechen: true,
  chloewu: true,
  choonsik: true,
  chosun: true,
  chukapi: true,
  churin: true,
  cindywu: true,
  cishanjia: true,
  ck: true,
  cktajith: true,
  claske: true,
  clinnovations: true,
  cllabs: true,
  'clube das alts': true,
  clubedasalts: true,
  clube_das_alts: true,
  clubehope: true,
  cmgroup: true,
  cmsolutions: true,
  cnexchange: true,
  cntech: true,
  coach: true,
  coachk: true,
  cobacknam: true,
  cobraty: true,
  coffeekongz: true,
  coinbolt: true,
  coinchain: true,
  coinedge: true,
  coinglobe: true,
  coinlogic: true,
  coinm: true,
  coinnest: true,
  coinp: true,
  coinquest: true,
  coinspark: true,
  cointech: true,
  cointrust: true,
  cointv: true,
  coinvault: true,
  coinwise: true,
  coinworks: true,
  cookies: true,
  corgil: true,
  cornethaethirnode: true,
  coso: true,
  cpexchange: true,
  cpi_17: true,
  crescendo: true,
  criptomind: true,
  crocy: true,
  cruzcontrol: true,
  crypto_a2z: true,
  crypto_earn_important: true,
  cryptobanter: true,
  cryptobolt: true,
  cryptochasers: true,
  cryptodep: true,
  cryptodiffer: true,
  cryptodifferua: true,
  cryptofundamentals: true,
  cryptofundraisingua: true,
  cryptogen: true,
  cryptogenius: true,
  cryptohashkongz: true,
  cryptohe: true,
  cryptokid: true,
  cryptolaxy: true,
  cryptoleads: true,
  cryptolink: true,
  cryptologic: true,
  cryptomakase: true,
  cryptomatrix: true,
  cryptomedic4_gpu_nodes: true,
  cryptomocho: true,
  crypton: true,
  cryptonauts: true,
  cryptonick: true,
  cryptonova: true,
  cryptoogs: true,
  cryptoonline: true,
  cryptoowl: true,
  cryptopia_group: true,
  cryptoprime: true,
  cryptoquest: true,
  cryptorand: true,
  cryptospark: true,
  cryptostache: true,
  cryptotalks: true,
  cryptotelugu: true,
  cryptotimes: true,
  cryptotony: true,
  cryptotrust: true,
  cryptourist: true,
  cryptoverse: true,
  cryptovibe: true,
  cryptovietinfo: true,
  cryptowise: true,
  csexchange: true,
  cspdao: true,
  cstech: true,
  ctcapital: true,
  cvtech: true,
  cwlabs: true,
  cwsolutions: true,
  cwtech: true,
  cyberkongz: true,
  cygnoides: true,
  d7: true,
  dahua: true,
  dahuzi: true,
  daichiyamamoto: true,
  dailybear: true,
  daku: true,
  dan: true,
  daniel: true,
  davidportnoy: true,
  davidwang: true,
  daxigua: true,
  dc: true,
  dca: true,
  dci2024: true,
  dd: true,
  ddadon: true,
  deaflow: true,
  'defi.enigma1': true,
  defigen: true,
  defiprime: true,
  defisaint: true,
  defiscamcheck: true,
  degen: true,
  degenclan: true,
  degenlegends: true,
  delarg0: true,
  denischubchik: true,
  dewhalescapital: true,
  dexcheck_ai: true,
  dextforce: true,
  dg: true,
  digitalaxis: true,
  digitalbolt: true,
  digitalcore: true,
  digitaledge: true,
  digitalglobe: true,
  digitalhub: true,
  digitallink: true,
  digitalmatrix: true,
  digitalpulse: true,
  digitalquest: true,
  digitalspark: true,
  digitaltrust: true,
  ding: true,
  dingalingts: true,
  discount10: true,
  dkongkongz: true,
  dlg: true,
  dli: true,
  dmg: true,
  dmitryivanov: true,
  dmitrytanaka: true,
  doclub: true,
  doctor: true,
  dogin: true,
  donphano: true,
  doosin: true,
  doro: true,
  dorrego: true,
  double: true,
  doubletop: true,
  dougykongz: true,
  dropsanalytics: true,
  dstech: true,
  dtcorp: true,
  dusty: true,
  dvtech: true,
  dylancoinstore16: true,
  e6ycrypto: true,
  eaglecrew: true,
  ec: true,
  eclipsefi: true,
  eg: true,
  ej: true,
  ek: true,
  el: true,
  elisa: true,
  elizabeth_elabs: true,
  elizabeth_elabs10: true,
  elizabeth_elabs11: true,
  elizabeth_elabs12: true,
  elizabeth_elabs13: true,
  elizabeth_elabs14: true,
  elizabeth_elabs15: true,
  elizabeth_elabs16: true,
  elizabeth_elabs17: true,
  elizabeth_elabs18: true,
  elizabeth_elabs19: true,
  elizabeth_elabs2: true,
  elizabeth_elabs3: true,
  elizabeth_elabs4: true,
  elizabeth_elabs5: true,
  elizabeth_elabs6: true,
  elizabeth_elabs7: true,
  elizabeth_elabs8: true,
  elizabeth_elabs9: true,
  elontrades: true,
  elyar: true,
  emilyli: true,
  emilynguyen: true,
  emmazhang: true,
  empty: true,
  enefteasemekongz: true,
  enobeikongz: true,
  enryu: true,
  enso: true,
  ericisntme: true,
  erscrypto: true,
  es: true,
  et: true,
  ethandefi: true,
  ethanwang: true,
  eurosniper: true,
  evans: true,
  everseed: true,
  exitliqcapital: true,
  explo: true,
  ezcrypto: true,
  fangwei: true,
  fatcats: true,
  fepercigo: true,
  fernkongz: true,
  figfig: true,
  financialnodes: true,
  finsight: true,
  fireant: true,
  fishmarketacad: true,
  fointcrypto: true,
  follis: true,
  fomo: true,
  fomofomo: true,
  foresightnews: true,
  frid5696: true,
  fridge: true,
  fusionseven: true,
  g3: true,
  gala8329: true,
  gallagher: true,
  gckongz: true,
  gemhi: true,
  gemhivedao: true,
  getcoin: true,
  getmoni: true,
  gg: true,
  gibsteak: true,
  giu: true,
  gjtszkbv: true,
  glassnode: true,
  gm: true,
  good77: true,
  gorochi: true,
  gp: true,
  gpuwifhat: true,
  gracenguyen: true,
  grail: true,
  gs: true,
  gts: true,
  gudpartners: true,
  guildfi: true,
  guildqb: true,
  hanasuzuki: true,
  harecrypta: true,
  haskell: true,
  hbeeee: true,
  heedan: true,
  heedan2: true,
  hellosuoha: true,
  heroly88: true,
  hinapark: true,
  hiroba: true,
  hiroshitanaka: true,
  hirototanaka: true,
  hmalviya9: true,
  holysheet: true,
  honeybottle: true,
  honeyrat: true,
  hoogagaming: true,
  house_of_crypto: true,
  hustle: true,
  hw: true,
  hyeonwoolee: true,
  hz38dherf: true,
  hzgb: true,
  'i need a code': true,
  ineedacode: true,
  iba: true,
  ice: true,
  iced: true,
  icesteam: true,
  icobeast: true,
  icodrops: true,
  icosharks: true,
  identifykongz: true,
  ik: true,
  il: true,
  ilmeaalim: true,
  imi520: true,
  in: true,
  incrypted: true,
  informdao: true,
  insalubres: true,
  insects: true,
  insightful: true,
  investruiz: true,
  invokokongz: true,
  ip: true,
  itscryptoaddict: true,
  ivan: true,
  ivanivanov: true,
  ivanpetrov: true,
  jableskongz: true,
  jack: true,
  jackbtc: true,
  jakejohnson: true,
  james: true,
  james_banter: true,
  jamesgensnote: true,
  jc: true,
  jenti: true,
  jeongnam: true,
  jero: true,
  jerryalpha: true,
  jerrys: true,
  jerryview: true,
  jg: true,
  jialiu: true,
  jiholee: true,
  jiko: true,
  jiminlee: true,
  jimmyshequ: true,
  jingliu: true,
  jinkang: true,
  jinpark: true,
  jiqqy: true,
  jiventures: true,
  jl: true,
  jm: true,
  jmr: true,
  jn: true,
  joe: true,
  joeparys: true,
  john5cripto: true,
  johnfdong: true,
  join2here: true,
  jonnyvalue: true,
  jordi: true,
  jp: true,
  jr: true,
  juan: true,
  jutro: true,
  jw: true,
  jz: true,
  k0099: true,
  k1234: true,
  kaitoyamamoto: true,
  kaizen: true,
  kartel_aethir: true,
  kartik: true,
  kbn0vc_cryptonairz: true,
  kenjiyamamoto: true,
  kentatanaka: true,
  kesler: true,
  keyur: true,
  kfcdao: true,
  kibi: true,
  killberos: true,
  kincat: true,
  kingster: true,
  kirbycrypto: true,
  kit_gpt: true,
  kobe: true,
  kolcapital: true,
  kong2love: true,
  kongy: true,
  koob: true,
  kopadze: true,
  kortankongz: true,
  kriptomessi: true,
  kuiclub: true,
  kyle: true,
  kyroh: true,
  l1020688: true,
  ladycrypto99: true,
  laibookongz: true,
  lambe_kripto99: true,
  laolu: true,
  layerhub: true,
  lc: true,
  leaf: true,
  legoleecm: true,
  leokim: true,
  lesoza1: true,
  levelnode: true,
  lexmorenoweb3: true,
  lg: true,
  lianyanshe: true,
  liaoblove: true,
  lilychen: true,
  lilynguyen: true,
  lilytoken: true,
  lilywang: true,
  limbo: true,
  linchen: true,
  lingwei: true,
  liquidace: true,
  lk: true,
  ll: true,
  lmecripto: true,
  ln: true,
  loc: true,
  loldemortkongz: true,
  loltapeskongz: true,
  lordjorx: true,
  loukongz: true,
  lovejudylee: true,
  lp: true,
  luball13: true,
  lubi: true,
  lunakim: true,
  lunawu: true,
  m6labs: true,
  mack: true,
  mad: true,
  madmax420: true,
  magic: true,
  'main/#': true,
  mario: true,
  mark888: true,
  marshall: true,
  martainss: true,
  masil: true,
  masiwei: true,
  master_d: true,
  matheusbordin: true,
  maxpump: true,
  maxwang: true,
  mb: true,
  meilin: true,
  meinft: true,
  mesgo: true,
  metaboo: true,
  metabros47: true,
  metamuffin: true,
  mff: true,
  mg: true,
  miachen: true,
  miali: true,
  mianguyen: true,
  miawu: true,
  michaelgo: true,
  michaelwang: true,
  mikhailpetrov: true,
  mingo: true,
  mini: true,
  minjikim: true,
  mintair: true,
  mintttch: true,
  mirrordao: true,
  ml: true,
  mocaverse: true,
  moer: true,
  moneygrid: true,
  monki: true,
  montekripto: true,
  moon1ightst: true,
  moonbootsdao: true,
  morrison: true,
  mrdefi: true,
  mrseanvvv: true,
  mt: true,
  murphy: true,
  murphybtc: true,
  muyi: true,
  mvp: true,
  mvst99: true,
  mw: true,
  mychelmendes: true,
  myhobby: true,
  na: true,
  naback222_: true,
  naback7366: true,
  'nansen-alpha': true,
  neotokyo: true,
  nero_001: true,
  ng: true,
  nikitaivanov: true,
  nithkongz: true,
  nk: true,
  nobs: true,
  node: true,
  node_sale: true,
  nodeops: true,
  noder: true,
  noders: true,
  nomadkongz: true,
  nomoonnobuy: true,
  none: true,
  notorius: true,
  notsezkongz: true,
  np: true,
  nt: true,
  nzresearch: true,
  oduvanchik: true,
  oignodes01: true,
  ok: true,
  olimpo: true,
  olivianguyen: true,
  omg: true,
  onelykim: true,
  onstan: true,
  orangie: true,
  os: true,
  osa3210: true,
  osushi: true,
  ot: true,
  otaku: true,
  ourocap: true,
  ow: true,
  pablo007: true,
  pagu: true,
  paladins: true,
  pandario: true,
  pangpang: true,
  panic: true,
  pareh: true,
  pastanaga: true,
  pastel: true,
  patocrypto: true,
  pegas: true,
  pengu: true,
  pepe_fonji: true,
  pgcapital: true,
  pmzcapital: true,
  pnxgrp: true,
  pokakongz: true,
  polkadotnews: true,
  pratas: true,
  praximal: true,
  prestito: true,
  primecrypto: true,
  procent: true,
  pursebox: true,
  qingqingge: true,
  raiden: true,
  rakusu148: true,
  ramoncunha: true,
  randomwalk010: true,
  real18nom: true,
  realagency: true,
  rechinballkongz: true,
  redox: true,
  refcode: true,
  rentanaka: true,
  res: true,
  retrodao: true,
  rice: true,
  richtrain: true,
  risenode: true,
  riyankongz: true,
  rk: true,
  rl: true,
  rocinante: true,
  rocky_bitcoin: true,
  'ronja88!!eberswald': true,
  rosckongz: true,
  rowna: true,
  rp: true,
  rph: true,
  rs: true,
  rui2333: true,
  runsensen: true,
  rw: true,
  ryabina: true,
  ryand: true,
  saamon: true,
  safz: true,
  sakipark: true,
  samuelwang: true,
  samurai_starter: true,
  samurai10: true,
  sanctuary: true,
  sanctuskongz: true,
  sandro: true,
  sandrocabrales: true,
  sanjay: true,
  sanyuan222: true,
  satoshisuzuki: true,
  satoshitanaka: true,
  satoshiyamamoto: true,
  satstack: true,
  savage: true,
  scaleswapua: true,
  sct: true,
  searchfi: true,
  secretodefi: true,
  seedify: true,
  sefatuba3: true,
  semko: true,
  sensei: true,
  sepower: true,
  seunghoonlee: true,
  shaimo: true,
  shcornkongz: true,
  shengart13: true,
  shinchan: true,
  shiv: true,
  shun: true,
  silverduck: true,
  sirajkongz: true,
  sixth0302: true,
  sk: true,
  ska: true,
  skid: true,
  sl: true,
  slow: true,
  smartliquidity: true,
  smartmoneycrypto: true,
  snooper: true,
  snowienftkongz: true,
  sober: true,
  sofiahernandez: true,
  softlipa: true,
  sohwak: true,
  soj: true,
  solanium: true,
  solidrockkongz: true,
  sophiachain: true,
  sophianguyen: true,
  sophy: true,
  sorapark: true,
  soso: true,
  spaaarkling: true,
  spare_gas_4_lambro: true,
  spartadex: true,
  spicycapital_2024: true,
  spike: true,
  sqrdao: true,
  squadwealth: true,
  ssibueong: true,
  st: true,
  starlaunch: true,
  stay: true,
  stc: true,
  stellapark: true,
  stitch2002621: true,
  strong: true,
  subcaptain: true,
  subijjang: true,
  sun1q84: true,
  sungjae: true,
  sungsil: true,
  sunmoon: true,
  suppoman: true,
  surgence: true,
  swissborg: true,
  symmetry: true,
  taejoonkim: true,
  taeyeonlee: true,
  takeshiyamamoto: true,
  takumitanaka: true,
  talkchain: true,
  tangerine: true,
  tanito: true,
  tanzent: true,
  tatsuyayamamoto: true,
  tc: true,
  tccapital: true,
  tebi: true,
  tedpillows: true,
  tekoki3: true,
  tempo: true,
  tg: true,
  tgtech: true,
  th: true,
  thecallmaster: true,
  theking1_: true,
  thewardensgc: true,
  thewoys: true,
  thiagomoura: true,
  thorin: true,
  tindorr: true,
  tk_ventures: true,
  tlsolutions: true,
  tnsolutions: true,
  tokenbolt: true,
  tokencore: true,
  tokenlink: true,
  tokenmatrix: true,
  tokenmore: true,
  tokennex: true,
  tokenplus: true,
  tokenpulse: true,
  tokenspark: true,
  tokentrust: true,
  tokenvault: true,
  tokenwise: true,
  tokenworks: true,
  tomoyamamoto: true,
  top7ico: true,
  tpcaethir: true,
  traderdaink: true,
  tsecrypto: true,
  tslabs: true,
  tssolutions: true,
  ttcapital: true,
  tvs: true,
  tylerhill: true,
  unclemarc: true,
  unicornverse: true,
  uppexinvestment2024: true,
  valhallakongz: true,
  valorem420: true,
  varnakongz: true,
  vcdefi_aethir2024: true,
  vijaypravin: true,
  vikkongz: true,
  viktorpetrov: true,
  vincentkongz: true,
  virtuealliance: true,
  vision: true,
  vladislavivanov: true,
  vsfg_2024: true,
  wabnode: true,
  waltersilva_criptocomfe: true,
  wangxiaolou: true,
  wanzi: true,
  waribiki: true,
  web3_honey: true,
  web3alertio: true,
  weichen: true,
  weili: true,
  weizhang: true,
  whatever: true,
  whitesocks: true,
  whitewaterlabs: true,
  whosthesmimikongz: true,
  william11chan: true,
  wilsen88: true,
  winkrypto: true,
  wise3_ventures: true,
  'wise3-venture': true,
  wiseadvice: true,
  wo168: true,
  wolvesdao: true,
  wtm: true,
  wxyz_vc: true,
  wyaethirnode: true,
  xializi: true,
  xiaomao: true,
  xiaoxin: true,
  xingxingjun: true,
  xxx: true,
  y010k1ng: true,
  yaikan: true,
  yc: true,
  yeolchoong: true,
  yeolcjoong: true,
  yeomyung: true,
  yeonwoo: true,
  yfl: true,
  yieldfarming: true,
  yiyixmb: true,
  yobeul: true,
  youbi: true,
  yourairdrop: true,
  yp: true,
  ytflz: true,
  yuayato: true,
  yudan: true,
  yuisuzuki: true,
  yukipark: true,
  yukisato: true,
  yukisuzuki: true,
  yunakim: true,
  yunapark: true,
  yunasuzuki: true,
  yunlog: true,
  yurisuzuki: true,
  yusuketanaka: true,
  yutiancoin: true,
  yutotanaka: true,
  yzaethirnode: true,
  z12345678: true,
  zephyruscap: true,
  zflab: true,
  zhuge123: true,
  zl: true,
  zn: true,
  zunahn: true,
  сapo69: true,
  if: true,
  impossiblefinance: true,
  impossible_finance: true,
  bokjisa: true,
  elibecislao: true,
  waltersilvacriptocomfe: true,
  deki: true,
  hinkok: true,
  bulka: true,
  cryptomera: true,
  '0xfoton': true,
  laskarcrypto: true,
  neowave: true,
  nick0906: true,
  saheb: true,
  vampire: true,
  rebeleth: true,
  cryptus: true,
  kmanus88: true,
  shrug: true,
  alpha: true,
  muke: true,
  aethir: true,
  aethirai: true,
  aethircloud: true,
  ath: true,
  athdao: true,
  athai: true,
  athcloud: true,
  aethirnetwork: true,
  aether: true,
  aither: true,
  aethire: true,
  aethirgaming: true,
  aethirdao: true,
  cryptophile: true,
  athkev: true,
  'apoorv.anand': true,
  chaincatchers: true,
  bammbamm: true,
  gmclub: true,
  fiona: true,
  eureka: true,
  aethirxamber: true,
  bb: true,
  tfl2024: true,
  oldfashion: true,
  cg: true,
  nebula: true,
  metabros: true,
  m6: true,
  wizards: true,
  escobar: true,
  capo69: true,
  fomocatcher: true,
  h168: true,
  farmorsss: true,
  crm666: true,
  daddy_69: true,
  techfanatic: true,
  aphone: true,
  hodler0xalpha: true,
  goldy: true,
  tudd_tutulabs: true,
  water119: true,
  eazer: true,
  drgems: true,
  'nansen alpha': true,
  nansenalpha: true,
  cleverse: true,
  ash: true,
  cape: true,
  ronja88_eberswald: true,
  luganodes: true,
  coworkers: true,
  thortrades8: true,
  malto: true,
  tuna_gg: true,
  keshu: true,
  moa: true,
  kay: true,
  rabbit: true,
  earn: true,
  tkpro654: true,
  gacrypto: true,
  kryptonates: true,
  easycrypto: true,
  cryptopedia: true,
  hrc: true,
  '65station': true,
  monis: true,
  proofoftalk: true,
  defiverse_01: true,
  atamert: true,
  vivanlive: true,
  yourfriendandy: true,
  jordan23: true,
  '8f': true,
  moon_capital: true,
  easeflow: true,
  ayaysee: true,
  hotspotty: true,
  mythos: true,
  savas: true,
  cryptogeek2024: true,
  du09btc_: true,
  mgg: true,
  sevacryptostart: true,
  '53club': true,
  shenz: true,
  cloud7: true,
  quantum: true,
  selected: true,
  onlygains: true,
  blocktalks_02: true,
  alliet: true,
  abc: true,
  if1: true,
  if2: true,
  if3: true,
  aiif: true,
  if4: true,
  if5: true,
  ashraf705: true,
  jongdong78: true,
  cryptovel: true,
  '0xnen01': true,
  advcapitalacademy: true,
  mc: true,
  nousi: true,
  jakawin: true,
  wilson: true,
  tanawatc: true,
  'crescendo ': true,
  'blaze420_nodes ': true,
  'moonbootsdao ': true,
  'alphax ': true,
  kondrashov: true,
  hybriddao: true,
  xaingames: true,
  crypt0_kakarot: true,
  romano_rnr: true,
  odailyaethirnode: true,
  chengziaethirnode: true,
  punkdao: true,
  animocabrands2024: true,
}

export const SOPHON_CODES = {
  sandro: true,
  coso: true,
  informdao: true,
  pagu: true,
  defigen: true,
  kong2love: true,
  jm: true,
  ouro: true,
  tradeparty: true,
  hoc: true,
  crypton: true,
  cryptoearn: true,
  degen: true,
  node: true,
  gagarin_crypto: true,
  eazer: true,
  cryptotelugu: true,
  andy: true,
  fomowiki: true,
  sepower: true,
  pengu: true,
  yeonwoo: true,
  icodrops: true,
  sophon_th: true,
  xingpt123: true,
  gg: true,
  sophon_gcrod: true,
  jutro2024: true,
  sophon_jiko: true,
  bean: true,
  magic: true,
  alexae_001: true,
  pang: true,
  cheongchun: true,
  gamingtokens: true,
  good77: true,
  rich: true,
  jerryv: true,
  sophon_retrodao: true,
  jammin: true,
  bitcoach: true,
  sunmoon: true,
  montekriptokontu: true,
  kfclovesophon: true,
  coach: true,
  cryptodiffer: true,
  chainbroker: true,
  cryptogics: true,
  chosun: true,
  mini: true,
  shaimo: true,
  joinhere: true,
  jiajia: true,
  incrypted: true,
  alphador: true,
  oduvanchik: true,
  discount10: true,
  arcanum_ventures: true,
  selected: true,
  a2finance: true,
  bokjisa: true,
  layerhub: true,
  coinmuhendisi: true,
  hmalviya9: true,
  kmanus88: true,
  whitelist1: true,
  stc: true,
  sophon_cartel: true,
  catcher: true,
  swiper: true,
  w3coins: true,
  heedan: true,
  sohwak: true,
  choonsik: true,
  seroy: true,
  if: true,
  impossible: true,
  impssiblefinance: true,
  core_9999: true,
  koreaalphadegen: true,
  cygnoides: true,
  gemhivedao: true,
  legoleecm: true,
  tebi: true,
  yobeul: true,
  elizabeth_elabs: true,
  moonyu: true,
  rc: true,
  kesler: true,
  '02han': true,
  cryptophile: true,
  sqrdao: true,
  noders: true,
  bdinweb3: true,
  itscryptoaddict: true,
  nomoonnobuy: true,
  cryptodep: true,
  jikoba: true,
  cryptovietinfo: true,
  pionero: true,
  a01k: true,
  doctor: true,
  ga_crypto24: true,
  wisekim_eth: true,
  uejj: true,
  cryptus: true,
  sophon_igor97: true,
  doubletop: true,
  chacha: true,
  yuayato: true,
  nodeops: true,
  bmt: true,
  polkadot_news: true,
  ssibueong: true,
  praximal: true,
  sophon_max992: true,
  'lsp.eth': true,
  ilmeaalim: true,
  wabnode: true,
  jiraiya: true,
  ca: true,
  nodehodler: true,
  serp1337: true,
  bitsearch: true,
  rui: true,
  sophon_hellosuoha: true,
  ortegas: true,
  bitpan: true,
  thewoys: true,
  cobacknam: true,
  sophon000: true,
  kgodel: true,
  xjf92dc: true,
  arcadia: true,
  kong: true,
  roxinft: true,
  bananamilk: true,
  ibcig: true,
  ddadon: true,
  rowna: true,
  satoshi: true,
  wizardofsoho: true,
  leoresearch: true,
  easeflow: true,
  dbh: true,
  dropstab: true,
  dropscapital: true,
  sincero: true,
  sungsil: true,
  malto: true,
  sophon: true,
  sophon1: true,
  sophon2: true,
  sophon3: true,
  sophon4: true,
  sophon5: true,
  sophon6: true,
  sophon7: true,
  sophon8: true,
  sophon9: true,
  sophon10: true,
  nodesale1: true,
  nodesale2: true,
  nodesale3: true,
  nodesale4: true,
  nodesale5: true,
  nodesale6: true,
  nodesale7: true,
  nodesale8: true,
  nodesale9: true,
  nodesale10: true,
  nodesale11: true,
  aethir: true,
  ath: true,
  impossiblefinance: true,
  arc: true,
  wizards: true,
  parisdaox10: true,
  boolish: true,
  zentry: true,
  sct: true,
}

const CARV_CODES = {
  carvnode: true,
  doubletop: true,
  sandro: true,
  loc: true,
  yudan: true,
  coso: true,
  ourocap: true,
  house_of_crypto: true,
  pagu: true,
  cryptotelugu: true,
  beanstock: true,
  blackdragon: true,
  jm: true,
  andy: true,
  cspdao: true,
  sepower: true,
  informdao: true,
  yp: true,
  soj: true,
  cheongchun: true,
  doctor: true,
  gemhi: true,
  central: true,
  defigen: true,
  aethir2moon: true,
  sunmoon: true,
  eazer: true,
  aylo: true,
  metamuffin: true,
  defiscamcheck: true,
  degen: true,
  pengu: true,
  shrug: true,
  laolu: true,
  kong2love: true,
  tebi: true,
  crypto_earn_important: true,
  jiko: true,
  oduvanchik: true,
  alphatrace: true,
  nzresearch: true,
  kongy: true,
  cryptochasers: true,
  cyberconnect: true,
  ashcrypto: true,
  kk: true,
  jammin: true,
  dewhales: true,
  starlaunch: true,
  vision: true,
  seedify: true,
  appa: true,
  ahcventures: true,
  eclipsefi: true,
  'eclipse fi': true,
  elabs: true,
  firestarter: true,
  nodeops: true,
  arc: true,
  spartadex: true,
  swissborg: true,
  coinmuhendisi: true,
  btr: true,
  spores: true,
  slim: true,
  noder: true,
  sanjay: true,
  alphacapitalvc: true,
  aneque: true,
  gululu_carv: true,
  carv_fan7899: true,
  hiro: true,
  timetocrypto: true,
  genesis: true,
  '0xdmo': true,
  '1-9': true,
  rapidnode: true,
  bitsearch: true,
  selmanft: true,
  rvn_node: true,
  alphadrops: true,
  sgrdao: true,
  pchild: true,
  hn1fa2: true,
  seyong7777: true,
  carv_999: true,
  carbz33: true,
  tayonweb: true,
  jp: true,
  poin_team: true,
  cryptoalerts: true,
  enigma: true,
  easype: true,
  carvairdrop: true,
  carv_otejiri: true,
  charming_airdrop: true,
  carv_meta6868: true,
  a_z: true,
  coinsugar: true,
  abokicnft: true,
  patron4eg: true,
  iammsb_carv_101: true,
  smeb_y888: true,
  carv_brc001: true,
  tarab_739: true,
  midnightclubmcb: true,
  kriptoplikpauris: true,
  cryptopryanic: true,
  maniakkasur6969: true,
  jk01f0: true,
  jv3fp2: true,
  businesspepe: true,
  marvellousedu001: true,
  profit: true,
  craghack: true,
  cornishon: true,
  chacha: true,
  cryptobike: true,
  dogin: true,
  p25fdr: true,
  carv_fan888: true,
  harunguyen: true,
  bigcoin: true,
  default: true,
  gem_carv1: true,
  carv_underdog: true,
  dbz_club: true,
  grahamz: true,
  ij6fse: true,
  buy: true,
  cryptonick: true,
  carv_airdrophic: true,
  blockscriber51: true,
  alex7264g: true,
  degenslav: true,
  siddegen: true,
  mesgo: true,
  js9fpo: true,
  cryptotop: true,
  iu_fan03: true,
  defivoyager: true,
  dolook_22: true,
  dergeldmentor: true,
  cryptodvor: true,
  magd: true,
  'carv-fire': true,
  'boost or amoo': true,
  boostoramoo: true,
  doninvest: true,
  daze: true,
  coinhunters: true,
  cryptochrysler: true,
  carv_buriza: true,
  smphcarv: true,
  savanecrypto: true,
  'bestkush.bnb': true,
  asdfa: true,
  korea: true,
  coinp: true,
  honeyrat: true,
  '1min': true,
  cobacknam: true,
  legoleecm: true,
  crocy: true,
  carv_naback: true,
  cryptoowl: true,
  carv_atm: true,
  yeolchoong: true,
  ssibueong: true,
  jerryalpha: true,
  hbeeee: true,
  choonsik: true,
  dividend_93: true,
  eaglecrew: true,
  stay: true,
  doosin: true,
  heedan: true,
  morrison: true,
  cek: true,
  nodegray: true,
  carv_yunlog: true,
  kobestreams: true,
  web3stat: true,
  gg: true,
  asdfasf: true,
  seroy: true,
  rc: true,
  coach: true,
  shiyao: true,
  doosingod: true,
  wabnode: true,
  tina: true,
  bingx: true,
  mp_nodes: true,
  test: true,
  escobar: true,
  serp1337: true,
  capy: true,
  s: true,
  nomoonnobuy: true,
  stc: true,
  node10: true,
  cryptus: true,
  chainbroker: true,
  levelnode: true,
  whitelist: true,
  sincero: true,
  a2finance: true,
  satoshi: true,
  rich: true,
  good77: true,
  discount10: true,
  bitcoach: true,
  geekcrypto: true,
  kfcdao: true,
  mack: true,
  zaikenomics: true,
  top7ico: true,
  carv_retrodao: true,
  mini: true,
  mychel: true,
  carv_th: true,
  xingpt: true,
  alphador: true,
  swiper: true,
  pang: true,
  vportal: true,
  chosun: true,
  cryptodiffer: true,
  '10off': true,
  cryptophile: true,
  malto: true,
  ddadon: true,
  polykripto: true,
  spartans: true,
  cryptofanz_69: true,
  gioeth: true,
  kryptonates: true,
  ducloc: true,
  insalubres: true,
  cryptodep: true,
  nftroro: true,
  lucian: true,
  sohwak: true,
  rayray: true,
  rodrigomoura: true,
  bycoinhunter: true,
  wangxiaolou: true,
  lianyanshe: true,
  talkchain: true,
  bojo0x: true,
  sj: true,
  pandario: true,
  cwesearch: true,
  carv_n1_fans: true,
  tiny_miner: true,
  none: true,
  hustle: true,
  airdrop_rv: true,
  gamingtokens: true,
  if001: true,
  if002: true,
  if003: true,
  if004: true,
  if005: true,
  if006: true,
  if007: true,
  if008: true,
  if009: true,
  if010: true,
  mgg: true,
  moonboy: true,
  opad: true,
  fjordfoundry: true,
  tanzent: true,
  chaingpt: true,
  fomo: true,
  easeflow: true,
  jiventures: true,
  chto_to_pro: true,
  zxcv8888: true,
  zombina: true,
  zhouzhou: true,
  zeroday444: true,
  zeppor: true,
  z45: true,
  yoldoz: true,
  yobeul: true,
  yes: true,
  yana8800: true,
  yadamnis1375: true,
  y: true,
  xiaofeibtc: true,
  xgpt_team9x: true,
  wyann: true,
  winnersfarmx: true,
  ru8f7i: true,
  'wigz@carv': true,
  whiteoak: true,
  wbp90_carv: true,
  wbp90: true,
  w3coins: true,
  vidollar: true,
  vexor_gg: true,
  velanoreth: true,
  vbc999: true,
  v99z: true,
  undefined: true,
  unclecow: true,
  umutaktu_1111: true,
  umutaktu: true,
  ty13: true,
  tvs: true,
  tufa: true,
  ttventures: true,
  topgame888: true,
  together: true,
  tnventures: true,
  tiokukz: true,
  tiffan_420: true,
  tieuphu: true,
  thenftmenager: true,
  thedawn: true,
  thanos_carv: true,
  teknikcihoca: true,
  swoptoky: true,
  supers: true,
  sungsil: true,
  stay1104: true,
  stakegenix: true,
  spores_carv2024: true,
  spicy888: true,
  solpaka: true,
  soccer_strem3: true,
  slatro_carv23: true,
  sinsin_love: true,
  shevas: true,
  sherifdefi: true,
  sharkcrypto: true,
  seup2f: true,
  serg_99: true,
  serenity: true,
  seodo: true,
  sellmama: true,
  selected: true,
  seer: true,
  savage: true,
  samuraiclub: true,
  samuelvillegas: true,
  'sam.fan22': true,
  sahil0099: true,
  rvn_top: true,
  ronald_8719: true,
  roan: true,
  rikuuen: true,
  richard_d: true,
  revenant: true,
  restless: true,
  remote: true,
  rekt_guy09: true,
  redcarv: true,
  qwe: true,
  qiqapi: true,
  pumpman: true,
  psn_bubu4134: true,
  'promo-1134415': true,
  pro_blockchain123: true,
  pols: true,
  ploytalk: true,
  pewaki58: true,
  passivecrypto: true,
  pashadizel: true,
  pabloinvers_26: true,
  ozi: true,
  othiagomoura: true,
  ostenmatrix: true,
  orgasm322_carv: true,
  openpad: true,
  onur: true,
  notcisum1223: true,
  non: true,
  nohfuk: true,
  noderzz: true,
  nodeking101: true,
  nodehodler: true,
  niki_0111: true,
  nickthegrizzly: true,
  ngoclap11051995: true,
  nftsiy: true,
  neyse_carv: true,
  nekomyadev: true,
  nech: true,
  nattaponp072: true,
  natakamry: true,
  'não tenho': true,
  namlongdao: true,
  nagrfu: true,
  naga: true,
  naback: true,
  na: true,
  mythos: true,
  muni1502: true,
  munchies: true,
  muhammadind: true,
  mt888: true,
  mrmahicrypto: true,
  mrcarv0920: true,
  moneyboss: true,
  'money_1728-': true,
  modulus2020: true,
  mj_1688: true,
  mikybull: true,
  midasc: true,
  michaelbaota: true,
  mian666: true,
  mian66: true,
  mia0727: true,
  mercek: true,
  meowcrypto: true,
  memory_7: true,
  'memo-jnr21': true,
  mbm: true,
  manlynft: true,
  malikatif: true,
  malglee: true,
  madar8413: true,
  m3d: true,
  lynxinvisible: true,
  lucky888: true,
  luben: true,
  lord_30: true,
  lobird_5455: true,
  llilei: true,
  lgis2f: true,
  lfg: true,
  lets: true,
  leshka0007: true,
  leostudio: true,
  leonardo: true,
  legolas_carv: true,
  lego_carv: true,
  leedogin: true,
  learner: true,
  latsan: true,
  lastmoney: true,
  laimow: true,
  laffae_lvu: true,
  kurin_85: true,
  krystal_888: true,
  kryptogamer: true,
  kryptocove: true,
  kqtp2f: true,
  koinlokal: true,
  kline: true,
  kingskuan: true,
  king_tee4real: true,
  kfcq2f: true,
  keyurvip: true,
  kevinii_420: true,
  keepcalm: true,
  katropa888: true,
  karacarv: true,
  jony0x: true,
  jollygreen: true,
  joldencrypto: true,
  jim_0425: true,
  jessie_tiktok0109: true,
  jdjd: true,
  jam: true,
  jacksong: true,
  ishi31: true,
  insider_1109: true,
  inside999: true,
  innr1f: true,
  incrypted: true,
  imranmahmudshoi: true,
  ihhf: true,
  igorizuchaetcrypty: true,
  pk5837: true,
  hyunmevn: true,
  hybridwhales: true,
  huntercalls: true,
  hothead888: true,
  horizongenx_carv: true,
  hijazy: true,
  hideer: true,
  hedda: true,
  hb: true,
  grow2_gether: true,
  gpp3fh: true,
  gorilla: true,
  'goodmedia-official': true,
  gm: true,
  gideon: true,
  george: true,
  gemsscope: true,
  gemdrop43: true,
  gem88: true,
  gc8888: true,
  gamethemarket: true,
  gala8329: true,
  gaetan_mv: true,
  gaetan_carv: true,
  g70q2f: true,
  forge: true,
  firehustle: true,
  ferry_jnr2: true,
  fergus: true,
  fedbnb: true,
  farmersdao: true,
  fanxing: true,
  fan999: true,
  fan933: true,
  fan888: true,
  fairyclub: true,
  eros_tsa: true,
  erictoken: true,
  emrex: true,
  emi0leo1: true,
  elcopttan: true,
  egich: true,
  efham: true,
  ed: true,
  ebubechigmi: true,
  earthdefire: true,
  dyor_100x: true,
  dxg_666: true,
  duola: true,
  drophunt: true,
  drekzenn: true,
  don: true,
  dokdo: true,
  dnopz: true,
  dmo: true,
  dmitriy: true,
  dlag2f: true,
  dinariyc: true,
  dgg: true,
  dezradiamond: true,
  dewhalescapital: true,
  depinspirationhub: true,
  delightcarv1: true,
  deks2f: true,
  deki: true,
  degods: true,
  defi_th88: true,
  deenmhp37: true,
  dautucrypto: true,
  darthclaire: true,
  dantespapa: true,
  danjocapital_2024: true,
  danjocapital: true,
  dagongzai: true,
  daancrypto: true,
  daairdropboss: true,
  cymm66: true,
  cygnoides: true,
  cyberdevil: true,
  cyber_fans: true,
  cryptozhuge: true,
  cryptozamok: true,
  cryptoworldnews: true,
  cryptoverse: true,
  cryptourist: true,
  cryptoticker: true,
  cryptosoft: true,
  cryptosale: true,
  cryptorun: true,
  cryptonita_nn: true,
  cryptonelly3: true,
  cryptomutant: true,
  cryptologykey: true,
  cryptolego: true,
  cryptokao: true,
  cryptojagatthu: true,
  cryptoiska: true,
  cryptohustler: true,
  cryptofirstson: true,
  cryptodna: true,
  cryptocheckervc: true,
  crypto4chun: true,
  crypto_goose: true,
  crypto_gem88: true,
  crypto_elysium: true,
  criptoleto: true,
  criptohub: true,
  cptdfi01: true,
  cowcow: true,
  copper_carv: true,
  coof54: true,
  coocolab: true,
  coinhunter: true,
  coindivasniper: true,
  coin68: true,
  codeblocklabs: true,
  cochinnom123: true,
  cnp77: true,
  cipher_airdrop: true,
  chukapi: true,
  chubbytige: true,
  chodan: true,
  charles: true,
  cemalthemm: true,
  cb: true,
  catocut: true,
  carvsmart: true,
  carvph: true,
  carvpersia: true,
  carvhunters: true,
  carve_riri: true,
  carv88: true,
  carv10: true,
  'carv.ahsan': true,
  'carv-hunters': true,
  'carv-fan1978': true,
  'carv-erictoken92': true,
  carv_yzd: true,
  carv_yyds666: true,
  carv_whalevalidator: true,
  carv_weilin: true,
  carv_wawa222: true,
  carv_vinceakcb: true,
  carv_van4768: true,
  carv_trimaims: true,
  carv_top555: true,
  carv_tnventures: true,
  carv_tiendientu: true,
  carv_the210: true,
  carv_tako: true,
  carv_suno: true,
  carv_sosdog: true,
  carv_shadliebug: true,
  carv_sfz: true,
  carv_sadi22: true,
  carv_rocks: true,
  carv_rinoa: true,
  carv_popness: true,
  carv_pedru144: true,
  carv_patoc24: true,
  carv_onder: true,
  carv_nick666: true,
  carv_naveen: true,
  carv_naback222: true,
  carv_mowgli42069: true,
  carv_monada777: true,
  carv_mishutkatut: true,
  carv_member6868: true,
  carv_me09: true,
  carv_master: true,
  carv_madmac: true,
  carv_lt261: true,
  carv_love1004: true,
  carv_lige666: true,
  carv_libapi: true,
  carv_lethe555: true,
  carv_layrads: true,
  carv_korea20240501: true,
  carv_keke123: true,
  carv_igor0403: true,
  carv_hb: true,
  carv_hao888: true,
  carv_ge0t2f: true,
  'carv_funprofit_100%': true,
  carv_flg999: true,
  carv_fei666: true,
  carv_farmersdao: true,
  carv_fanhm0066: true,
  carv_fanchoee: true,
  carv_fance666: true,
  carv_fan933: true,
  carv_fan888999111: true,
  carv_fan333721: true,
  carv_fan2912: true,
  carv_fan: true,
  carv_evm3345678: true,
  carv_eldooook77: true,
  carv_efhamcrypto: true,
  carv_eels: true,
  carv_easy: true,
  carv_ding28: true,
  carv_cyberxtremog: true,
  carv_cuanairdrop: true,
  carv_cryptohunter: true,
  carv_congge: true,
  carv_chou666: true,
  carv_chen888: true,
  carv_capx333: true,
  carv_btbyte: true,
  carv_bscmaster: true,
  carv_boy666: true,
  carv_blar13579: true,
  carv_big999: true,
  carv_amai04: true,
  carv_altava: true,
  carv_airdropfind: true,
  carv_airdrop: true,
  carv_ahamiane89: true,
  carv_abc: true,
  carv_a8: true,
  carv_42069: true,
  carv_0xsf: true,
  carv_007: true,
  'carv usdt222': true,
  carv_usdt222: true,
  usdt222: true,
  car_fan888: true,
  cam_ro456: true,
  cagy: true,
  bull_gg21: true,
  bulka: true,
  buereth: true,
  budhilvyas: true,
  btcxiaohai: true,
  boxmining: true,
  bountybay: true,
  bolt: true,
  bollie: true,
  bokjisa: true,
  bluejokers: true,
  blockail: true,
  bitexiaohu: true,
  biri: true,
  biquanhuangmu: true,
  bf9t2f: true,
  bewertrading: true,
  bestnftgame_777: true,
  basilla: true,
  bamsong22: true,
  bales0514: true,
  azuth: true,
  az: true,
  awsff786: true,
  'atoys.axb': true,
  atoys: true,
  ath_carv: true,
  ashkand1: true,
  arya: true,
  argo1: true,
  altcoinbuzz1234: true,
  altava: true,
  alrock_14: true,
  alphath: true,
  alizo: true,
  alex_777: true,
  alaraade07: true,
  airdrop: true,
  aidang_1688: true,
  abokhalil: true,
  abid2323: true,
  '6p6t2f': true,
  '5phutcrypto': true,
  '55kh1f': true,
  '50kfn3': true,
  '38fjvckkde': true,
  '2doge_carv': true,
  '21million21': true,
  '13011990ba': true,
  '0xsun': true,
  '0xmt': true,
  '035t2f': true,
  carv_pon123: true,
  carv_yukai999: true,
  t1mescrypto: true,
  fidelis: true,
  xiaoweiwanf_888: true,
  carv_fan786: true,
  carv_cryptopocket: true,
  if: true,
  heywhycarv: true,
  profitfactor: true,
  testnetnodes: true,
  jake: true,
  carv_derek888: true,
  depin: true,
  carv_vader88: true,
  carver: true,
  carv_korea: true,
  altcointurk: true,
  icodrops: true,
  dropscapital: true,
  dropstab: true,
  sophon: true,
  intellax: true,
  carv_lssu77: true,
  abubakar933: true,
  carv_node_fan: true,
  weebearn: true,
  carv_cryptoadou: true,
  needracks1: true,
  wgc: true,
  thegrind: true,
  cryptomera: true,
  gmclub: true,
  knidos250: true,
  gadroenode: true,
  f150_lariat: true,
  bqnps: true,
  kmanus88: true,
  a01k: true,
  antisakrum: true,
  web3wikis: true,
  animoca: true,
  bqyz_yeye888: true,
  carvnode_discount10: true,
  lunapr: true,
  altsteinn: true,
  crypto_team: true,
  xaxa: true,
  nobrainflip: true,
  aethir: true,
  kaybee_08: true,
  oasys: true,
  dimsumtrader: true,
  jero_99: true,
  solv: true,
  carv_lol000: true,
  coachk: true,
  topcrypto24h: true,
  leoresearch: true,
  said: true,
  fr7u2f: true,
  '0xwonderboycrypto': true,
  cheatcoiner: true,
  l2pad: true,
  infstone: true,
  coinpost: true,
  yumipeixuan: true,
  '0g': true,
  doublejumptokyo: true,
  arcadia: true,
  nebula: true,
  apebond: true,
}

export const PROMO_CODES = {
  [Brands.AETHIR]: AETHIR_CODES,
  [Brands.SOPHON]: SOPHON_CODES,
  [Brands.CARV]: CARV_CODES,
}
