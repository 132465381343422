import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { ClaimableIDO, CliffVestableIDO, LinearVestableIDO, PurchasableIDO, SubscribeableIDO } from 'state/v2_types'
import { StakingCard, StakingMissedCard, StakingStartCard } from 'componentsV2/Card'
import { isPurchaseableIDO, isClaimableIDO, isCliffVestableIDO, isLinearVestableIDO } from 'state/idos/saleUtil'
import StakingEndCard from 'componentsV2/Card/StakingEndCard'
import BigNumber from 'bignumber.js'
import { useGetAllocation } from 'hooks/useIFASale'
import { useSaleUserData } from 'state/idos/hooks'
import { convertFromWei } from 'utils/formatBalance'

const Staking: React.FC<{ sale: SubscribeableIDO }> = ({ sale }) => {
  const { token } = sale

  const { allo: userAllocationInWei } = useGetAllocation(sale.id, sale.isPrivate)
  const userData = useSaleUserData(sale.id)

  const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), token.decimals)

  const estimatedAllocation =
    userAllocation.toString().length === 0 || userAllocation.toString() === '0'
      ? userData?.userTokenAllocation || new BigNumber(0)
      : userAllocation
  const getStakingState = useCallback(() => {
    const now = new Date()
    const { subscribePeriod, stepRequirement } = sale
    const { startTime, endTime } = subscribePeriod

    if (new Date(startTime) > now) {
      return <StakingStartCard sale={sale} />
    }
    if (new Date(endTime) > now) {
      return <StakingCard sale={sale} />
    }
    if (
      new Date(endTime) < now &&
      parseFloat(estimatedAllocation) > 0 &&
      !isPurchaseableIDO(sale as unknown as PurchasableIDO) &&
      !isClaimableIDO(sale as unknown as ClaimableIDO) &&
      !isCliffVestableIDO(sale as unknown as CliffVestableIDO) &&
      !isLinearVestableIDO(sale as unknown as LinearVestableIDO)
    ) {
      return <StakingEndCard sale={sale} estimatedAllocation={estimatedAllocation} />
    }
    return <StakingMissedCard stepRequirement={stepRequirement} />
  }, [sale])

  return (
    <>
      <Box>{getStakingState()}</Box>
    </>
  )
}

export default Staking
