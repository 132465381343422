import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Box, Link, InjectedModalProps, Flex, LinkIcon } from 'uikit'
import { registerToken } from 'utils/wallet'
import { useTranslation } from 'contexts/Localization'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useNetwork } from 'wagmi'
import { Chain } from 'config/constants/types'
import { AutoColumn } from 'componentsV2/layout/Column'
import { Currency } from 'swap-sdk/entities/currency'
import { TokenClass } from 'swap-sdk/entities/token'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import CircleLoader from 'componentsV2/Loader/CircleLoader'
// import AnimatedCheckedBox from 'componentsV2/AnimatedCheckedBox'
import { AnimatedCheckedBox } from 'componentsV2'
import { getBscScanLink, getScanText } from '../../utils'

const Wrapper = styled.div`
  width: 100%;
  max-height: calc(80vh - 119px);
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`

function TransactionSubmittedContent({ chainId, hash }: { hash: string | undefined; chainId: Chain }) {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AutoColumn justify="center">
        {chainId && hash && (
          <Link external small href={getBscScanLink(hash, 'transaction', chainId)}>
            {t('View on %scanner%', { scanner: getScanText(chainId) })}
            <Flex pl="8px">
              <LinkIcon width="12px" height="12px" />
            </Flex>
          </Link>
        )}
      </AutoColumn>
      <Flex pb="27px" />
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  bottomContent,
  topContent,
}: {
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <Box>{topContent()}</Box>
      <Box>{bottomContent()}</Box>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  title: string
  customOnDismiss?: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

const TransactionConfirmationModal: React.FC<InjectedModalProps & ConfirmationModalProps> = ({
  title,
  onDismiss,
  customOnDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
}) => {
  const { chain } = useNetwork()
  const chainId = chain?.id
  const { t } = useTranslation()

  const token: TokenClass | undefined = wrappedCurrency(currencyToAdd, chainId)

  const handleDismiss = useCallback(() => {
    if (customOnDismiss) {
      customOnDismiss()
    }
    onDismiss()
  }, [customOnDismiss, onDismiss])

  const registerTokenCallback = useCallback(() => {
    if (token.symbol === 'ECLIP') {
      registerToken('0x93ca0d85837FF83158Cd14D65B169CdB223b1921', token.symbol, 6)
    } else {
      registerToken(token.address, token.symbol, token.decimals)
    }
  }, [token])
  if (!chainId) return null
  if (attemptingTxn) {
    return (
      <ModalV2
        bodyTitle={title}
        bodyImage={<CircleLoader size="36px" />}
        bodyImageMargin="0 0 41px 0"
        bodySubtitle={pendingText}
        bodyDescription={t('Confirm this transaction in your wallet')}
        bodyPadding="10px 0px"
        onDismiss={handleDismiss}
        hideCloseButton
      />
    )
  }

  if (hash) {
    return (
      <ModalV2
        bodyTitle={t('Transaction Submitted')}
        bodyImage={<AnimatedCheckedBox />}
        bodyImageWidth={60}
        bodyImageMargin="0 0 57px 0"
        bodyPadding="0px"
        onDismiss={handleDismiss}
        showConfirm={currencyToAdd && !currencyToAdd.isNative}
        onConfirm={registerTokenCallback}
        confirmText={
          currencyToAdd && !currencyToAdd.isNative
            ? t('Add %asset% to Wallet', { asset: currencyToAdd.symbol })
            : undefined
        }
        maxButtonWidth="280px"
      >
        <TransactionSubmittedContent chainId={chainId} hash={hash} />
      </ModalV2>
    )
  }
  return (
    <ModalV2 bodyPadding="8px 0px" title={!hash && title} bodyTitle={hash && title} onDismiss={handleDismiss}>
      {content()}
    </ModalV2>
  )
}

export default TransactionConfirmationModal
