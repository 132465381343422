import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styled, { useTheme } from 'styled-components'
import IFTypography from 'componentsV2/IFTypography'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { useMatchBreakpoints, CopyIcon } from 'uikit'
import { useAccount } from 'wagmi'
import copyToClipboard from 'utils/copyToClipboard'
import { useToast } from 'state/hooks'

const CodeBox = styled(Box)`
  background: ${({ theme }) => theme.colorsV2.main5};
  width: 100%;
  border-radius: 10px;
  padding: 20px;
`

const ModalBox = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.modal.background};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 90vw;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 30px 30px 40px 30px;
`

const ModalBoxMobile = styled.div`
  position: absolute;
  backdropfilter: 0;
  background: ${({ theme }) => theme.modal.background};
  top: auto;
  left: 50%;
  right: 0;
  bottom: 0;
  transform: translateX(-50%)};
  width: 100%;
  height: 50%;
  border-radius: 20px 20px 0 0;
 padding: 30px 20px 30px 20px;
`

interface ModalTypes {
  open: boolean
  setOpenModalInitialPurchaseReferral: (e: boolean) => void
}

const ModalContent = ({ handleClose, isMobile }: { isMobile: boolean; handleClose: () => void }) => {
  const { address } = useAccount()
  const { toastSuccessV2 } = useToast()
  const theme = useTheme()

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <IFTypography variant="h3" fontWeight="bold" fontSize={isMobile ? '18px' : '24px'}>
          Share to earn rewards
        </IFTypography>
        <IconButton onClick={() => handleClose()}>
          <CloseIcon sx={{ fontSize: isMobile ? '24px' : '34px', color: theme.colorsV2.text }} />
        </IconButton>
      </Box>
      <Box mt="10px">
        <IFTypography variant={'body2'} ifcolor="textSecondary">
          Congratulations! Your wallet address is eligible for referral rewards. Share the referral code to others for
          purchases. You will earn <span style={{ fontWeight: 700 }}>8%</span> of their purchase amount.
        </IFTypography>

        <CodeBox display="flex" flexDirection="column" mt="25px">
          <IFTypography fontSize="14px" color={theme.colorsV2.referral.textMain2 || '#ffffff'}>
            Your referral code
          </IFTypography>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <IFTypography fontSize="14px" fontWeight="bold" color={theme.colorsV2.referral.textMain2 || '#ffffff'}>
              {isMobile ? (address.slice(0, 10) + '....' + address.slice(33, 42)).toLowerCase() : address.toLowerCase()}
            </IFTypography>
            <IconButton
              type="button"
              aria-label="copy"
              onClick={() => {
                handleClose()
                copyToClipboard(toastSuccessV2, address.toLowerCase(), 'Copied referral code to clipboard')
              }}
            >
              <CopyIcon color={theme.colorsV2.referral.textMain2 || '#ffffff'} />
            </IconButton>
          </Box>
        </CodeBox>
        <IFTypography fontSize="14px" mt={2} ifcolor="textSecondary">
          The referral code is only valid for purchases made within the same chain which you initially bought the node.
        </IFTypography>
      </Box>
    </>
  )
}

export default function ModalInitialPurchaseReferral({ open, setOpenModalInitialPurchaseReferral }: ModalTypes) {
  const { isXl } = useMatchBreakpoints()

  const isMobile = isXl === false

  const handleClose = () => {
    setOpenModalInitialPurchaseReferral(false)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      {isMobile ? (
        <ModalBoxMobile>
          <ModalContent handleClose={handleClose} isMobile={isMobile} />
        </ModalBoxMobile>
      ) : (
        <ModalBox>
          <ModalContent handleClose={handleClose} isMobile={isMobile} />
        </ModalBox>
      )}
    </Modal>
  )
}
