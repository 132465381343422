import { Box, SxProps } from '@mui/material'
import Countdown from 'componentsV2/Countdown/Countdown'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import React, { useContext, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { Button, useDrawer, useMatchBreakpoints } from 'uikit'
import ColorBorderBlankCard from '../ColorBorderBlankCard/ColorBorderBlankCard'
import PostSaleInfo from './PostSaleInfo'
import { StyleCardProps } from './type'
import { CompanyContext, Brands } from 'contexts/CompanyContext'

const Container = styled(Box)<{
  isBeam: boolean
  isMoonveil: boolean
  isNodeSale: boolean
  isCampus: boolean
  isMobile: boolean
}>`
  display: flex;
  background: ${({ isCampus, theme }) => isCampus && theme.colorsV2.background};
  flex-direction: column;
  align-items: center;
  box-shadow: ${({ isMoonveil }) => (isMoonveil ? '0px 4px 25px 0px rgba(65, 65, 65, 0.15)' : '')};
  border-radius: 20px;
  padding: ${({ isNodeSale, isMobile }) => (isMobile && isNodeSale ? '20px 0 30px 0' : '35px 20px')};
  border: ${({ theme, isBeam }) => (isBeam ? `1px solid ${theme.common.styleCardBorder}` : '')};
  border: ${({ theme }) => theme.mediaQueries?.md} {
    box-shadow: ${({ isMoonveil }) => (isMoonveil ? '0px 4px 25px 0px rgba(65, 65, 65, 0.15)' : '')};
    padding: 35px 40px;
  }
`

const LinkBtnIcon = styled.div`
  margin-left: 8px;
  width: 10px;
  svg {
    fill: ${({ theme }) => (theme.isColorOverride ? theme.colorsV2.button.linkText : theme.common?.kycGetTokensBtn)};
  }
`

const Subtitle = styled(Box)`
  span {
    font-family: Roboto;
  }
`

const TitleSection: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Box textAlign="center" letterSpacing="0.5px">
      <IFTypography variant="body1" ifcolor="text" fontWeight="bold">
        {title}
      </IFTypography>
    </Box>
  )
}

const SubtitleSection: React.FC<{ subtitle: string; lineHeight: string; style: SxProps }> = ({
  subtitle,
  lineHeight,
  style,
}) => {
  return (
    <Subtitle sx={{ ...style }}>
      <IFTypography lineHeight={lineHeight} variant="body2" ifcolor="textSecondary">
        {subtitle}
      </IFTypography>
    </Subtitle>
  )
}

const StyledCard: React.FC<StyleCardProps> = ({
  style,
  containerStyle,
  countdownInfo = null,
  imageInfo = null,
  title = null,
  subtitleInfo = null,
  children,
  footer,
  ctaInfo = null,
  linkBtnInfo = null,
  showBorder = true,
  className,
  hidePostSaleInfo = false,
}) => {
  const { brand, isNodeSale } = useContext(CompanyContext)
  const isBeam = brand === Brands.BEAM
  const isMoonveil = brand === Brands.MOONVEIL
  const isCampus = brand === Brands.CAMPUS
  const theme = useTheme()
  const { isSm, isXs } = useMatchBreakpoints()
  const isMobile = isSm || isXs
  const countDownElement = useMemo(() => {
    if (countdownInfo == null) {
      return null
    }
    const { seconds: countDownSeconds, descriptionStyle: descStyle, ...rest } = countdownInfo
    if (countDownSeconds <= 0 || !countDownSeconds) {
      return null
    }
    const descriptionStyle: SxProps = {
      ...descStyle,
      textAlign: 'center',
    }
    return (
      <Countdown
        seconds={countDownSeconds}
        descriptionStyle={{ color: theme.colorsV2.textSecondary, ...descriptionStyle }}
        {...rest}
      />
    )
  }, [countdownInfo])

  const imageElement = useMemo(() => {
    const defaultStyle = {
      margin: '40px 0 10px',
    }
    if (imageInfo == null) return null
    const { image, style: imgStyle } = imageInfo
    const imageStyle = {
      ...defaultStyle,
      ...imgStyle,
    }
    return (
      <Box {...imageStyle}>{React.isValidElement(image) ? image : <img src={image.toString()} alt="card_image" />}</Box>
    )
  }, [imageInfo])

  const titleElement = useMemo(() => {
    if (title == null) return null
    return <TitleSection title={title} />
  }, [title])

  const subtitleElement = useMemo(() => {
    const defaultStyle = {
      marginTop: '10px',
      textAlign: 'center',
    }
    if (subtitleInfo == null) return null
    const { style: stStyle, subtitle } = subtitleInfo
    const subtitleStyle = {
      ...defaultStyle,
      ...stStyle,
    } as SxProps
    return (
      <SubtitleSection
        style={{ ...subtitleStyle }}
        lineHeight={(stStyle?.lineHeight ?? '20px') as string}
        subtitle={subtitle}
      />
    )
  }, [subtitleInfo])

  const ctaElement = useMemo(() => {
    const defaultStyle = {
      margin: isNodeSale ? '0' : '20px 0 0',
      color: theme.isColorOverride ? theme.colorsV2.main4 : theme.common.kycStatusLink,
    }
    if (ctaInfo == null) return null
    const { text, btnProps, handler, button } = ctaInfo
    if (button != null) {
      return button
    }
    const ctaBtnProps = {
      ...defaultStyle,
      ...btnProps,
    }
    return (
      <Button {...ctaBtnProps} onClick={handler}>
        {text}
      </Button>
    )
  }, [ctaInfo])

  const linkBtnElement = useMemo(() => {
    if (linkBtnInfo == null) return null
    const { text, btnProps, handler, button, href, icon = null } = linkBtnInfo
    if (button != null) return button
    const linkBtnProps = {
      color: theme.isColorOverride ? theme.colorsV2.main4 : theme.common.kycStatusLink,
      margin: isNodeSale ? '0' : '20px 0 0',
      ...btnProps,
    }
    const content = (
      <>
        {text}
        {icon == null ? null : <LinkBtnIcon>{icon}</LinkBtnIcon>}
      </>
    )
    if (href) {
      return (
        <Button
          as="a"
          href={href}
          style={{
            color: theme.isColorOverride ? theme.colorsV2.main4 : theme.common.kycStatusLink,
            textDecoration: 'none',
          }}
          variant="text"
          external
          {...linkBtnProps}
        >
          {content}
        </Button>
      )
    }
    return (
      <>
        <Button
          variant="text"
          style={{ color: theme.isColorOverride ? theme.colorsV2.main4 : theme.common.kycStatusLink }}
          {...linkBtnProps}
          onClick={handler}
        >
          {content}
        </Button>
      </>
    )
  }, [linkBtnInfo])

  const renderBody = useMemo(() => {
    return (
      <>
        {imageElement}
        {titleElement}
        {subtitleElement}
        {children}
        {ctaElement}
      </>
    )
  }, [imageElement, titleElement, subtitleElement, children, ctaElement])

  const renderFooter = useMemo(() => {
    return <>{footer}</>
  }, [footer])

  const [, , isOpen] = useDrawer({
    drawer: <div />,
  })

  return (
    <ColorBorderBlankCard
      hideShadow={isOpen}
      showBorder={showBorder}
      className={className}
      containerStyle={{ ...containerStyle }}
    >
      <Container
        isMobile={isMobile}
        isBeam={isBeam}
        isMoonveil={isMoonveil}
        isNodeSale={isNodeSale}
        isCampus={isCampus}
        sx={{ ...style }}
      >
        {countDownElement}
        {renderBody}
        {linkBtnElement}
        {renderFooter}
      </Container>
      <PostSaleInfo hide={hidePostSaleInfo} />
    </ColorBorderBlankCard>
  )
}

export default StyledCard
