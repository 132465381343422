import { Toast } from 'uikit'
import BigNumber from 'bignumber.js'
// eslint-disable-next-line import/no-cycle
import { Chain, FarmConfig, Nft, Team } from 'config/constants/types'
// eslint-disable-next-line import/no-cycle
import { Banner, FarmsState, GetQuizResponse, Project, Quiz, QuizResult, RequestStatus, VIDIAState } from './v2_types'

export type TranslatableText =
  | string
  | {
      id: number
      fallback: string
      data?: {
        [key: string]: string | number
      }
    }

// Block
export interface BlockStateMap {
  [key: number]: BlockState
  initialised: boolean
  lastUpdated: number
}
export interface BlockState {
  currentBlock: number
  initialBlock: number
}

export type Language = {
  code: string
  language: string
  locale: string
}

export interface Farm extends FarmConfig {
  tokenAmount?: BigNumber
  quoteTokenAmount?: BigNumber
  lpTotalInQuoteToken?: BigNumber
  tokenPriceVsQuote?: BigNumber
  poolWeight?: BigNumber
  userData?: {
    allowance: BigNumber
    tokenBalance: BigNumber
    stakedBalance: BigNumber
    earnings: BigNumber
  }
}

export interface Profile {
  userId: number
  points: number
  teamId: number
  nftAddress: string
  tokenId: number
  isActive: boolean
  username: string
  nft?: Nft
  team: Team
  hasRegistered: boolean
}

// Slices states

export interface ToastsState {
  data: Toast[]
}

export interface AppState {
  isNetworkUnavailable: boolean
}

export interface IdosState {
  projects: Project[]
  projectsWithAllocations: Project[]
  projectsInitialized: boolean
  projectsSynced: boolean
  initialised: boolean
  syncedOnchain: boolean
  isSaleUserDataLoading: boolean
  saleUserDataMap?: any
  isSaleUserDataFetched: boolean
}

export interface BannerState {
  banners: Banner[]
}

export interface QuestState {
  quizzes: Quiz[]
  detailQuizzes: DetailQuizzes
  quizResults: QuizResults
  resultStatus: RequestStatus
  previewQuiz: Quiz
}

export interface DetailQuizzes {
  [quizID: number]: GetQuizResponse
}

export interface QuizResults {
  [quizID: number]: QuizResult
}

// API Price State
export interface PriceList {
  [key: string]: number
}

export interface PriceApiResponse {
  /* eslint-disable camelcase */
  update_at: string
  prices: PriceList
}

export interface PriceState {
  isLoading: boolean
  lastUpdated: string
  data: PriceList
}

export interface ChainIdState {
  chainId: Chain
}

// Global state

export interface State {
  chainId: ChainIdState
  farms: FarmsState
  idos: IdosState
  toasts: ToastsState
  prices: PriceState
  blocks: BlockStateMap
  banners: BannerState
  quests: QuestState
  app: AppState
  vidia: VIDIAState
}

// IDOs

export enum KYCStatus {
  'NOT_STARTED' = 'NOT_STARTED',
  'NOT_FOUND' = 'NOT_FOUND',
  'VERIFIED' = 'VERIFIED',
  'PENDING' = 'PENDING',
  'REJECTED' = 'REJECTED',
  'CANCELLED' = 'CANCELLED',
  'FINAL_REJECTED' = 'FINAL_REJECTED',
  'BLACKLISTED' = 'BLACKLISTED',
  'REVOKED' = 'REVOKED',
  'UNKNOWN' = 'UNKNOWN',

  'APPROVED' = 'APPROVED',
  'RESUBMISSION_REQUIRED' = 'RESUBMISSION_REQUIRED',
  'SUBMISSION_REQUIRED' = 'SUBMISSION_REQUIRED',
  'PENDING_VERIFICATION' = 'PENDING_VERIFICATION',

  'PENDING_POA' = 'PENDING_POA',
  'SUBMISSION_REQUIRED_POA' = 'SUBMISSION_REQUIRED_POA',
}

export enum StepRequirementEnum {
  'KYC' = 'KYC',
}

export enum IDOType {
  PUBLIC_SALE = 'IDO1',
  BONUS_SALE = 'IDO2',
}

export type PublicSaleUserDataType = {
  stakingTokenAllowanceInWei: BigNumber
  paymentTokenAllowanceInWei: BigNumber
  hasWithdrawn: boolean
  paymentReceivedInWei: BigNumber
  paymentTokenBalanceInWei: BigNumber
  stakingTokenBalanceInWei: BigNumber
  userStakeWeight: number
  // TODO: Move this out when we have BonusSaleUserDataType
  solvPaymentTokenAllowance: BigNumber
}

export type StakeDropSaleUserDataType = {
  stakingTokenAllowanceInWei: BigNumber
  stakingTokenBalanceInWei: BigNumber
  userStakeWeight: number
}

export type FreeStakeDropUserDataType = {
  stakingTokenAllowanceInWei: BigNumber
  stakingTokenBalanceInWei: BigNumber
  userStakeWeight: number
}

export type PaidStakeDropUserDataType = {
  stakingTokenAllowanceInWei: BigNumber
  stakingTokenBalanceInWei: BigNumber
  userStakeWeight: number
  paymentTokenAllowanceInWei: BigNumber
  paymentReceivedInWei: BigNumber
  paymentTokenBalanceInWei: BigNumber
}

export type BonusSaleInfoType = {
  tokenId: number
  startTime: number
  seller: string
  totalInWei: BigNumber
  unitsInWei: BigNumber
  minInWei: BigNumber
  maxInWei: BigNumber
  icToken: string
  currency: string
  useAllowList: boolean
}

// Currently cannot got this data from SC
export type BonusSaleDetail = {
  highest?: number
  lowest?: number
  isDeclining?: boolean
  durationInSeconds?: number
  intervalInSeconds?: number

  intervalUnit?: INTERVAL_UNIT
  intervalValue?: number
}

export enum INTERVAL_UNIT {
  'SECOND' = 'second',
  'MINUTE' = 'minute',
  'HOUR' = 'hour',
  'DAY' = 'day',
}

export type BonusSaleUserDataType = {
  paymentTokenAllowanceInWei: BigNumber
  paymentTokenBalanceInWei: BigNumber
  // nftBalance: number
  purchasedUnits: BigNumber
  // claimableAmount: BigNumber
  // totalUnclaimedAmount: BigNumber
}
export interface PostSaleStats {
  numSales: string
  numParticipants: string
}

export type SolvMarketSale = {
  tokenId: number
  startTime: number
  seller: string
  totalInWei: BigNumber
  unitsInWei: BigNumber
  minInWei: BigNumber
  maxInWei: BigNumber
  icToken: string
  currency: string
  useAllowList: boolean
}

// Compliance
export enum ComplianceStatus {
  'EMPTY' = 'EMPTY',
  'VALID' = 'VALID',
  'EXPIRED' = 'EXPIRED',
  'BANNED' = 'BANNED',
}

export enum KYCSource {
  'SYNAPS' = 'SYNAPS',
  'AUTHENTO' = 'AUTHENTO',
  'BABT' = 'BABT',
  'ZKME' = 'ZKME',
}
