import React, { Suspense, useContext, useEffect } from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { Button, SvgProps, useIsDrawerOpen } from 'uikit'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import { useHistory } from 'react-router-dom'
import Flex from '../../components/Box/Flex'
import { useMatchBreakpoints } from '../../hooks'
import UserBlock from './components/UserBlock'
import { NavProps } from './types'
import Logo from './components/Logo'
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT } from './config'
import * as IconModule from './icons'
import Settings from './components/Settings'
import MobileBottomMenu from './components/MobileBottomMenu'
import { Brands, CompanyContext } from 'contexts/CompanyContext'
import { Box, Tab } from '@mui/material'

const TABS = [
  {
    name: 'NODE SALE',
    link: '/',
  },
  {
    name: 'REFERRAL REWARDS',
    link: '/referral',
  },
]

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`
// TODO: Update width to :  width: ${({ isDrawerOpen }) => (isDrawerOpen ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%')};
// when we use Drawer for account sidebar
const StyledNav = styled.nav<{ isDrawerOpen: boolean }>`
  position: fixed;
  left: 0;
  transition: top 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  width: 100%;
  height: ${MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.nav.background};
  box-shadow: ${({ theme }) => theme.colorsV2?.shadows.navbar};
  z-index: 20;
  transform: translate3d(0, 0, 0);
  @media (max-width: 1100px) {
    padding: 0 20px;
  }
  @media (max-width: 850px) {
    height: ${MOBILE_MENU_HEIGHT}px;
    padding: 0 20px 0 0;
  }
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const LinkSection = styled.div`
  max-width: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 80px;
  @media (max-width: 1100px) {
    margin-left: 20px;
  }

  .btnActive {
    background-color: ${({ theme }) => theme.colorsV2.dark};
  }
`

const ButtonStyled = styled(Button)`
  height: 36px;
  padding: 0 15px;
  margin-right: 26px;
  // fix
  background: ${({ ronin }) => (ronin ? 'transparent' : 'transparent')};
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;

  color: ${({ theme }) => theme.nav.linkText};
  &:hover {
    background-color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2.dark)};
    font-weight: 700;
  }
  &:hover:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: 1;
  }
  .linkActive {
    font-weight: 700;
    color: transparent;
    background: ${({ theme }) => theme.common.activeMenuTextColor};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const AccountSidebar = React.lazy(() => import('uikit/widgets/AccountSidebar'))

const Icons = IconModule as unknown as { [key: string]: React.FC<SvgProps> }

const TabBox = ({ link, name, key }: { link: string; name: string; key: any }) => {
  const history = useHistory()
  const { theme } = useTheme()
  return (
    <Box
      key={key}
      onClick={() => {
        history.push(link)
      }}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        color: location.pathname === link ? theme.colorsV2.textThirdly : theme.colorsV2.textDisabled,
        fontFamily: 'Semakin',
      }}
    >
      {location.pathname === link && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: '-150%',
            backgroundColor: theme.colorsV2.textThirdly,
            height: '5px',
          }}
        />
      )}
      {name}
    </Box>
  )
}

const Menu: React.FC<NavProps> = ({
  account,
  login,
  logout,
  links,
  children,
  useGasBalance,
  gasToken,
  balances = [],
  networks = [],
  kycInfo,
  isNetworkUnavailable,
  showNetworks,
}) => {
  const { isXl } = useMatchBreakpoints()
  const { t } = useTranslation()
  const isMobile = isXl === false
  const [isOpen] = useIsDrawerOpen()
  const { brand, hideMenu, isJustSale, hideSettings, logoUrl } = useContext(CompanyContext)
  const { isDark } = useTheme()
  const location = useLocation()

  return (
    <Wrapper>
      <StyledNav isDrawerOpen={isOpen}>
        <Logo isDark={isDark} isMobile={isMobile} href={logoUrl || '/'} />
        <LinkSection>
          {!isMobile && brand === Brands.MOONVEIL && (
            <Box display="flex" gap={10.5}>
              {TABS.map((tab) => (
                <TabBox link={tab.link} name={tab.name} key={tab.name} />
              ))}
            </Box>
          )}

          {!isMobile &&
            links
              .filter((item) => item?.label?.length > 0)
              .map(({ label, href, icon, pattern, activeIcon }) => {
                const isMainActive = location.pathname.match(pattern ?? href) != null
                const curTheme = isDark ? 'Dark' : 'Light'
                // TODO: This needs to be refactored sometime in the future
                const Icon = isMainActive
                  ? Icons[`${icon}${brand !== Brands.IF ? brand + curTheme : curTheme}ActiveIcon`] ||
                    Icons[`InvestLightActiveIcon`]
                  : Icons[`${icon}${brand !== Brands.IF ? brand + curTheme : curTheme}Icon`] || Icons[`InvestLightIcon`]
                const iconElement = <Icon width="24px" mr="5px" />

                if (href.indexOf('https://') > -1) {
                  return (
                    <a href={href} key={href} target="_blank" rel="noopener noreferrer">
                      <ButtonStyled key={label} className={isMainActive ? 'btnActive' : ''}>
                        <span className={isMainActive ? 'linkActive' : ''}>{t(label)}</span>
                      </ButtonStyled>
                    </a>
                  )
                }

                return (
                  <Link to={href} key={href}>
                    <ButtonStyled key={label} className={isMainActive ? 'btnActive' : ''}>
                      {iconElement}
                      <span className={isMainActive ? 'linkActive' : ''}>{t(label)}</span>
                    </ButtonStyled>
                  </Link>
                )
              })}
        </LinkSection>
        <Flex flexDirection="row" flex={5} justifyContent="flex-end">
          <Flex justifyContent="flex-end" alignItems="center">
            <UserBlock
              account={account}
              login={login}
              logout={logout}
              useGasBalance={useGasBalance}
              gasToken={gasToken}
              balances={balances}
              isMobile={isMobile}
              kycInfo={kycInfo}
              networks={networks}
              isNetworkUnavailable={isNetworkUnavailable}
              showAccount={brand === Brands.IF || brand === Brands.RONIN || brand === Brands.BEAM}
            />
            {!isMobile && !hideSettings && (
              <Flex alignItems="center">
                <Settings />
              </Flex>
            )}
          </Flex>
        </Flex>
      </StyledNav>
      <Suspense fallback={null}>
        <AccountSidebar />
      </Suspense>
      {isMobile && !hideMenu && !isJustSale && <MobileBottomMenu links={links} />}
      <BodyWrapper>{children}</BodyWrapper>
    </Wrapper>
  )
}

export default Menu
