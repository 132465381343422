import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Button, Text, Flex, AddIcon, CardBody, Message, useModal, PolygonRightIcon, useMatchBreakpoints } from 'uikit'
import { RouteComponentProps } from 'react-router-dom'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { useTranslation } from 'contexts/Localization'
import contracts from 'config/constants/contracts'
import { currencyEquals } from 'swap-sdk/entities/token'
import { useAccount, useNetwork } from 'wagmi'
import { useDispatch } from 'react-redux'
import { TokenAmount } from 'swap-sdk/entities/fractions/tokenAmount'
import { Currency } from 'swap-sdk/entities/currency'
import UnsupportedCurrencyFooter from 'views/Swap/components/UnsupportedCurrencyFooter'
import getGasToken from 'utils/getGasToken'
import Page from 'componentsV2/layout/Page'
import useTheme from 'hooks/useTheme'
import Row, { RowBetween } from 'componentsV2/layout/Row'
import { currencyId } from 'utils/currencyId'
import { MinimalPositionCard } from 'componentsV2/PositionCard'
import { AutoColumn, ColumnCenter } from 'componentsV2/layout/Column'
import { useRouterContract } from 'hooks/useContract'
import { ArrowWrapper } from 'views/Swap/components/styleds'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip/DefaultIFTooltip'
import { Box } from '@mui/material'
import WhiteCircleLoader from 'componentsV2/Loader/WhiteCircleLoader'
import { MainTab } from 'componentsV2'
import { SWAP_TABS_MENU } from 'config/menus/tabs'
import useSwapAvailabilityCheck from 'hooks/useSwapAvailabilityCheck'
import useWeb3 from 'hooks/useWeb3'

import { LightCard } from 'componentsV2/Card'

import { AppDispatch } from '../../state'
import CurrencyInputPanelV2 from '../../componentsV2/CurrencyInputPanel/CurrencyInputPanel'

import TransactionConfirmationModal, { ConfirmationModalContent } from '../../componentsV2/TransactionConfirmationModal'
import { DoubleCurrencyLogo } from '../../componentsV2/Logo'
import { AppHeader, AppBody } from '../../componentsV2/App'
import ConnectWalletButton from '../../componentsV2/ConnectWalletButton/ConnectWalletButton'

import { PairState } from '../../hooks/usePairs'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { Field, resetMintState } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { useIsExpertMode, useUserSlippageTolerance } from '../../state/user/hooks'
import { calculateGasMargin, calculateSlippageAmount } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import PoolPriceBar from './PoolPriceBar'
import ConfirmAddModalBottom from './ConfirmAddModalBottom'
import { Chain } from 'config/constants/types'

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  useSwapAvailabilityCheck()

  const { address: account } = useAccount()
  const web3 = useWeb3()

  const { chain } = useNetwork()
  const chainId = chain?.id
  const router = useRouterContract(chainId)
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const { theme } = useTheme()

  const currencyA = useCurrency(currencyIdA, chainId)
  const currencyB = useCurrency(currencyIdB, chainId)

  useEffect(() => {
    if (!currencyIdA && !currencyIdB) {
      dispatch(resetMintState())
    }
  }, [dispatch, currencyIdA, currencyIdB])

  const GAS = getGasToken(chainId)
  const oneCurrencyIsWETH = Boolean(
    chainId && ((currencyA && currencyEquals(currencyA, GAS)) || (currencyB && currencyEquals(currencyB, GAS))),
  )

  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field], chainId),
      }
    },
    {},
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {},
  )

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], contracts.router[chainId])
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], contracts.router[chainId])

  const addTransaction = useTransactionAdder()

  const onAdd = useCallback(async () => {
    if (!chainId || !account || !router) return
    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }

    const gasPrice = await web3.eth.getGasPrice()

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0],
    }

    let estimate
    let method: (...args: any) => Promise<TransactionResponse>
    let args: Array<string | string[] | number>
    let value: BigNumber | null
    if (currencyA.isNative || currencyB.isNative) {
      const tokenBIsETH = currencyB.isNative
      // DISINI
      // const gasAmount = await router.methods.purchase(paymentAmount).estimateGas({ from: account })
      // const tx = await IFASaleContract.methods
      //   .purchase(paymentAmount)
      //   .send({ from: account, gasPrice: gasPrice, gasLimit: gasAmount })
      estimate = router.estimateGas.addLiquidityETH
      method = router.addLiquidityETH
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B].toString(), // token min
        amountsMin[tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A].toString(), // eth min
        account,
        deadline.toHexString(),
      ]
      value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
    } else {
      estimate = router.estimateGas.addLiquidity
      method = router.addLiquidity
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadline.toHexString(),
      ]
      value = null
    }

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasPrice: gasPrice,
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${
              currencies[Field.CURRENCY_A]?.symbol
            } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
          })

          setTxHash(response.hash)
        }),
      )
      .catch((err) => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (err?.code !== 4001) {
          console.error(err)
        }
      })
  }, [
    account,
    addTransaction,
    allowedSlippage,
    chainId,
    currencies,
    currencyA,
    currencyB,
    deadline,
    noLiquidity,
    parsedAmounts,
    router,
  ])
  const modalHeader = useCallback(() => {
    return noLiquidity ? (
      <Flex alignItems="center">
        <Text fontSize="48px" marginRight="10px">
          {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`}
        </Text>
        <DoubleCurrencyLogo
          currency0={currencies[Field.CURRENCY_A]}
          currency1={currencies[Field.CURRENCY_B]}
          overlap
          size={26}
        />
      </Flex>
    ) : (
      <AutoColumn>
        <Row>
          <Flex mr="20px" mt="-20px" alignItems="flex-start">
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={26}
              margin
              overlap
            />
          </Flex>
          <AutoColumn>
            <Text fontSize="32px" marginRight="10px" bold fontFamily="Acre">
              {liquidityMinted?.toSignificant(6)}
            </Text>
            <Text small bold>
              {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} Pool Tokens`}
            </Text>
          </AutoColumn>
        </Row>

        <Text my="24px" color="textSecondary" fontSize="16px">
          {t(
            'Amount received is estimated. If the price changes by more than %slippage%% your transaction will revert.',
            {
              slippage: allowedSlippage / 100,
            },
          )}
        </Text>
      </AutoColumn>
    )
  }, [allowedSlippage, currencies, liquidityMinted, noLiquidity, t])

  const modalBottom = useCallback(() => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }, [price, currencies, parsedAmounts, noLiquidity, onAdd, poolTokenPercentage])

  const pendingText = t('Supplying %amountA% %symbolA% and %amountB% %symbolB%', {
    amountA: parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    symbolA: currencies[Field.CURRENCY_A]?.symbol ?? '',
    amountB: parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
    symbolB: currencies[Field.CURRENCY_B]?.symbol ?? '',
  })

  const handleCurrencyASelect = useCallback(
    (currencyA_: Currency) => {
      const newCurrencyIdA = currencyId(currencyA_)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/liquidity/add/${currencyIdB}/${currencyIdA}`)
      } else if (currencyIdB) {
        history.push(`/liquidity/add/${newCurrencyIdA}/${currencyIdB}`)
      } else {
        history.push(`/liquidity/add/${newCurrencyIdA}`)
      }
      onFieldAInput('')
    },
    [currencyIdB, history, currencyIdA, onFieldAInput],
  )

  const handleCurrencyBSelect = useCallback(
    (currencyB_: Currency) => {
      const newCurrencyIdB = currencyId(currencyB_)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/liquidity/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/liquidity/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/liquidity/add/${currencyIdA || 'BNB'}/${newCurrencyIdB}`)
      }

      onFieldBInput('')
    },
    [currencyIdA, history, currencyIdB, onFieldBInput],
  )

  const handleDismissConfirmation = useCallback(() => {
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)

  const modal = useMemo(() => {
    return (
      <TransactionConfirmationModal
        title={noLiquidity ? t('You are creating a pool') : t('You will receive')}
        customOnDismiss={handleDismissConfirmation}
        hash={txHash}
        content={() => <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />}
        currencyToAdd={pair?.liquidityToken}
        attemptingTxn={attemptingTxn}
        pendingText={pendingText}
      />
    )
  }, [
    attemptingTxn,
    pendingText,
    handleDismissConfirmation,
    modalBottom,
    modalHeader,
    noLiquidity,
    pair?.liquidityToken,
    t,
    txHash,
  ])
  const [onPresentAddLiquidityModal] = useModal(modal, true, true, 'addLiquidityModal')
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl
  return (
    <>
      <MainTab menu={SWAP_TABS_MENU} />
      <Page>
        <AppBody maxWidth="500px">
          <AppHeader
            title={t('Add Liquidity')}
            backTo="/pool"
            subtitleGap="30px"
            subtitlePadding="0px"
            subtitle={
              <Box justifyContent="center" marginBottom={4} display="flex">
                <IFTypography variant="body2" ifcolor="textSecondary" align="center">
                  {t('Add liquidity to receive LP tokens')}
                </IFTypography>
                <DefaultIFTooltip
                  placement="top"
                  title={`${
                    chainId !== Chain.HUMANODE_TESTNET &&
                    chainId !== Chain.HUMANODE_MAINNET &&
                    t(
                      'Users earn in 3 ways for providing liquidity: earn fees when other users trade or withdraw from the pool',
                    )
                  } ${t(
                    'Withdrawals feature a 0.49% exit fee to prevent liquidity manipulation and ensure high quality trading experience for users. ',
                  )}`}
                />
              </Box>
            }
          />
          <CardBody p={isMobile ? '10px 19px 40px' : '10px 55px 40px'}>
            <AutoColumn>
              {noLiquidity && (
                <ColumnCenter>
                  <Message variant="warning">
                    <div>
                      <Text bold mb="8px" color={theme.isDark ? '#F0AF41' : '#F5B452'}>
                        {t('You are the first liquidity provider.')}
                      </Text>
                      <Text mb="8px" color="textPrimary" fontSize="14px">
                        {t('The ratio of tokens you add will set the price of this pool.')}{' '}
                        {t('Once you are happy with the rate click supply to review.')}
                      </Text>
                    </div>
                  </Message>
                  <Flex mb="20px" />
                </ColumnCenter>
              )}
              <CurrencyInputPanelV2
                label={t('Input')}
                enableCurrencySelect
                value={formattedAmounts[Field.CURRENCY_A]}
                onUserInput={onFieldAInput}
                onMaxClicked={() => {
                  onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                }}
                onCurrencySelect={handleCurrencyASelect}
                hideMaxButton={!!atMaxAmounts[Field.CURRENCY_A]}
                currency={currencies[Field.CURRENCY_A]}
                showCommonBases={chainId !== Chain.HUMANODE_TESTNET && chainId !== Chain.HUMANODE_MAINNET}
              />
              <ColumnCenter>
                <ArrowWrapper clickable={false}>
                  <AddIcon width="13px" />
                </ArrowWrapper>
              </ColumnCenter>
              <CurrencyInputPanelV2
                label={t('Input')}
                enableCurrencySelect
                value={formattedAmounts[Field.CURRENCY_B]}
                onUserInput={onFieldBInput}
                onCurrencySelect={handleCurrencyBSelect}
                onMaxClicked={() => {
                  onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                }}
                hideMaxButton={!!atMaxAmounts[Field.CURRENCY_B]}
                currency={currencies[Field.CURRENCY_B]}
                showCommonBases={chainId !== Chain.HUMANODE_TESTNET && chainId !== Chain.HUMANODE_MAINNET}
              />
              {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
                <>
                  <RowBetween padding="15px 0 5px">
                    <Text fontSize="14px" color="textSecondary">
                      {noLiquidity ? t('Initial prices and pool share') : t('Prices and pool share')}
                    </Text>
                  </RowBetween>{' '}
                  <LightCard padding="1rem" borderRadius="5px">
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </LightCard>
                </>
              )}

              {addIsUnsupported ? (
                <Button disabled mb="4px">
                  {t('Unsupported Asset')}
                </Button>
              ) : !account ? (
                <Flex mt="24px" flexDirection="column">
                  <ConnectWalletButton />
                </Flex>
              ) : (
                <AutoColumn gap="sm">
                  <Flex mb="10px" />
                  {(approvalA === ApprovalState.NOT_APPROVED ||
                    approvalA === ApprovalState.PENDING ||
                    approvalB === ApprovalState.NOT_APPROVED ||
                    approvalB === ApprovalState.PENDING) &&
                    isValid && (
                      <>
                        <RowBetween>
                          {approvalA !== ApprovalState.APPROVED && (
                            <Button
                              onClick={approveACallback}
                              disabled={approvalA === ApprovalState.PENDING}
                              width={approvalB !== ApprovalState.APPROVED ? '48%' : '100%'}
                            >
                              {approvalA === ApprovalState.PENDING ? (
                                <Flex>
                                  <WhiteCircleLoader width="16px" />
                                  <Box marginLeft={2}>{t('Approving')}</Box>
                                </Flex>
                              ) : (
                                t('Approve %asset%', { asset: currencies[Field.CURRENCY_A]?.symbol })
                              )}
                            </Button>
                          )}
                          {approvalB !== ApprovalState.APPROVED && (
                            <Button
                              onClick={approveBCallback}
                              isLoading={approvalB === ApprovalState.PENDING}
                              disabled={approvalB === ApprovalState.PENDING}
                              width={approvalA !== ApprovalState.APPROVED ? '48%' : '100%'}
                            >
                              {approvalB === ApprovalState.PENDING ? (
                                <Flex>
                                  <WhiteCircleLoader width="16px" />
                                  <Box marginLeft={2}>{t('Approving')}</Box>
                                </Flex>
                              ) : (
                                t('Approve %asset%', { asset: currencies[Field.CURRENCY_B]?.symbol })
                              )}
                            </Button>
                          )}
                        </RowBetween>
                        <Flex justifyContent="center">
                          <PolygonRightIcon
                            color={theme.colorsV2?.textDisabled}
                            style={{ transform: 'rotate(90deg)' }}
                          />
                        </Flex>
                      </>
                    )}
                  <Box display="flex" justifyContent="center">
                    <Button
                      variant={
                        !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]
                          ? 'danger'
                          : 'primary'
                      }
                      onClick={() => {
                        if (expertMode) {
                          onAdd()
                        } else {
                          onPresentAddLiquidityModal()
                        }
                      }}
                      disabled={
                        !isValid || approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED
                      }
                    >
                      {error ?? t('Supply')}
                    </Button>
                  </Box>
                </AutoColumn>
              )}
              {chainId !== Chain.HUMANODE_TESTNET && chainId !== Chain.HUMANODE_MAINNET && (
                <Text marginTop="20px" textAlign="center" color="textThirdly" fontSize="12px">
                  {t(
                    'By adding liquidity users earn 20% of the trading fees accrued to this pair, proportional to your share of the pool. Trading fees vary on a pair by pair basis - fees are automatically accrued in real time and are claimed when you remove liquidity.',
                  )}
                </Text>
              )}
            </AutoColumn>
          </CardBody>
        </AppBody>
        {!addIsUnsupported ? (
          pair && !noLiquidity && pairState !== PairState.INVALID ? (
            <MinimalPositionCard
              showUnwrapped={oneCurrencyIsWETH && ![Chain.HUMANODE_TESTNET, Chain.HUMANODE_MAINNET].includes(chainId)}
              pair={pair}
            />
          ) : null
        ) : (
          <UnsupportedCurrencyFooter currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]} />
        )}
      </Page>
    </>
  )
}
