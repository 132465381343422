import BigNumber from 'bignumber.js'
import {
  FreeStakeDropConfig,
  PrivateIDOConfig,
  AuctionIDOConfig,
  Project,
  SubscribePeriod,
  PurchasePeriod,
  ClaimPeriod,
  SubscribeableIDO,
  PurchasableIDO,
  ClaimableIDO,
  FixedAllocationIDO,
  CliffVestInfo,
  CliffVestableIDO,
} from 'state/v2_types'
import { fetchAllocationMasterData, fetchAllocationSaleData } from './utils'
import {
  isClaimableIDO,
  isCliffVestableIDO,
  isFixedAllocationIDO,
  isPurchaseableIDO,
  isSubscribeableIDO,
} from './saleUtil'
import {
  fetchSalePurchaseHalted,
  fetchSalesBuybackClaimableNumber,
  fetchSalesMaxTotalPurchasable,
  fetchSalesPublicAllocation,
  fetchSalesPurchaserCount,
  fetchSalesTotalPaymentReceived,
  fetchTieredSales,
  fetchTieredSalesTotalPurchased,
} from './fetchSalesUser'

export const fetchTieredContract = async (projects: Project[]) => {
  const allSales = []
  for (let i = 0; i < projects.length; i++) {
    const { sales } = projects[i]
    for (let j = 0; j < sales.length; j++) {
      allSales.push(sales[j])
    }
  }

  const functions = [
    { key: 'tierData', fn: fetchTieredSales(allSales) },
    { key: 'tokenPurchased', fn: fetchTieredSalesTotalPurchased(allSales) },
  ]
  const results = await Promise.allSettled(functions.map((val) => val.fn))

  const keyedResult = results.reduce((acc, result, idx) => {
    if (result.status !== 'fulfilled') {
      acc[functions[idx].key] = {}
    }

    acc[functions[idx].key] = (result as any).value
    return acc
  }, {})

  const data = await Promise.allSettled(
    projects.map(async (project) => {
      const syncedSalesProject =
        project.sales && project.sales.length > 0
          ? await Promise.allSettled(
              project.sales.map(async (sale) => {
                const { token } = sale
                let syncedPurchasePeriod: PurchasePeriod
                let syncedSubscribePeriod: SubscribePeriod
                let syncedCliffVestInfo: CliffVestInfo
                let syncedClaimPeriod: ClaimPeriod
                const syncedSaleAmount: number = sale.saleAmount || 0
                let syncedAllocationOverride: number

                if (isPurchaseableIDO(sale as PurchasableIDO)) {
                  const { purchasePeriod } = sale as PurchasableIDO
                  syncedPurchasePeriod = purchasePeriod
                }

                if ('saleAddress' in sale && sale.saleAddress !== '') {
                  const maxTotalPurchasable = keyedResult['tierData']?.[sale.id].maxTotalPurchasable
                  const publicAllocation = keyedResult['tierData']?.[sale.id].maxAllocationPerWallet
                  const isHalted = keyedResult['tierData']?.[sale.id].isHalt

                  const totalPurchasedPerTier = keyedResult['tokenPurchased']?.[sale.id]

                  if (isPurchaseableIDO(sale as PurchasableIDO)) {
                    const { purchasePeriod } = sale as PurchasableIDO
                    const { salePrice } = purchasePeriod

                    const maxTotalPurchasableNum = new BigNumber(maxTotalPurchasable.toString()).toNumber()

                    syncedPurchasePeriod = {
                      ...syncedPurchasePeriod,
                      totalPaymentReceived: new BigNumber(totalPurchasedPerTier).multipliedBy(salePrice).toNumber(),
                      maxTotalPurchasable: maxTotalPurchasableNum,
                      publicAllocation: parseInt(publicAllocation),
                      isPurchaseHalted: isHalted,
                    }
                  }

                  if (isClaimableIDO(sale as ClaimableIDO)) {
                    syncedClaimPeriod = {
                      ...syncedClaimPeriod,
                    }
                  }
                }

                // Update these values to modify state
                // syncedSubscribePeriod = {
                //   ...syncedSubscribePeriod,
                //   startTime: new Date(new Date().getTime() - 1100 * 1000).toISOString(),
                //   endTime: new Date(new Date().getTime() - 1000 * 1000).toISOString(),
                // }

                // syncedPurchasePeriod = {
                //   ...syncedPurchasePeriod,
                //   startTime: new Date(new Date().getTime() - 1000 * 1000).toISOString(),
                //   endTime: new Date(new Date().getTime() + 1000 * 1000).toISOString(),
                // }

                // syncedClaimPeriod = {
                //   ...syncedClaimPeriod,
                //   startTime: new Date(new Date().getTime() + 800 * 1000).toISOString(),
                // }

                return {
                  ...sale,
                  token: {
                    ...token,
                    coinGeckoId: project.coinGeckoId,
                  },
                  saleAmount: syncedSaleAmount,
                  saleTokenAllocationOverride: syncedAllocationOverride,
                  purchasePeriod: syncedPurchasePeriod,
                  subscribePeriod: syncedSubscribePeriod,
                  claimPeriod: syncedClaimPeriod,
                  cliffVestInfo: syncedCliffVestInfo,
                }
              }),
            )
          : []

      return {
        ...project,
        sales: syncedSalesProject
          .filter((v) => v.status === 'fulfilled')
          .map((v) => (v as PromiseFulfilledResult<FreeStakeDropConfig | PrivateIDOConfig | AuctionIDOConfig>).value),
      }
    }),
  )

  return data.filter((v) => v.status === 'fulfilled').map((v) => (v as PromiseFulfilledResult<Project>).value)
}

export const fetchIdos = async (projects: Project[]) => {
  const allSales = []
  for (let i = 0; i < projects.length; i++) {
    const { sales } = projects[i]
    for (let j = 0; j < sales.length; j++) {
      allSales.push(sales[j])
    }
  }

  const functions = [
    { key: 'totalPaymentReceived', fn: fetchSalesTotalPaymentReceived(allSales) },
    { key: 'publicAllocation', fn: fetchSalesPublicAllocation(allSales) },
    { key: 'maxTotalPurchasable', fn: fetchSalesMaxTotalPurchasable(allSales) },
    { key: 'purchaserCount', fn: fetchSalesPurchaserCount(allSales) },
    { key: 'buybackClaimableNumber', fn: fetchSalesBuybackClaimableNumber(allSales) },
    { key: 'isPurchaseHalted', fn: fetchSalePurchaseHalted(allSales) },
  ]
  const results = await Promise.allSettled(functions.map((val) => val.fn))

  const keyedResult = results.reduce((acc, result, idx) => {
    if (result.status !== 'fulfilled') {
      acc[functions[idx].key] = {}
    }

    acc[functions[idx].key] = (result as any).value
    return acc
  }, {})

  const data = await Promise.allSettled(
    projects.map(async (project) => {
      const syncedSalesProject =
        project.sales && project.sales.length > 0
          ? await Promise.allSettled(
              project.sales.map(async (sale) => {
                const { token } = sale
                let syncedPurchasePeriod: PurchasePeriod
                let syncedSubscribePeriod: SubscribePeriod
                let syncedCliffVestInfo: CliffVestInfo
                let syncedClaimPeriod: ClaimPeriod
                const syncedSaleAmount: number = sale.saleAmount || 0
                let syncedAllocationOverride: number

                if (isFixedAllocationIDO(sale as FixedAllocationIDO)) {
                  syncedAllocationOverride = (sale as FixedAllocationIDO).saleTokenAllocationOverride
                }

                if (isPurchaseableIDO(sale as PurchasableIDO)) {
                  const { purchasePeriod } = sale as PurchasableIDO
                  syncedPurchasePeriod = purchasePeriod
                }

                if (isClaimableIDO(sale as ClaimableIDO)) {
                  const { claimPeriod } = sale as ClaimableIDO
                  syncedClaimPeriod = claimPeriod
                }

                if (isCliffVestableIDO(sale as CliffVestableIDO)) {
                  const { cliffVestInfo } = sale as CliffVestableIDO
                  syncedCliffVestInfo = cliffVestInfo
                }

                if (isSubscribeableIDO(sale as SubscribeableIDO)) {
                  const { masterAddress, trackId, chainId, subscribePeriod, stakingToken } = sale as SubscribeableIDO

                  const overrideMasterAddress =
                    sale.chainId === 421613 || sale.chainId === 59140
                      ? '0x77D60153561FCb8bB8304B3eB61f54960626e5dD'
                      : masterAddress
                  const overrideChainId = sale.chainId === 421613 || sale.chainId === 59140 ? 165 : chainId

                  const { maxTotalStake, numTrackStakers, totalStaked } = await fetchAllocationMasterData(
                    overrideMasterAddress,
                    trackId,
                    overrideChainId,
                    stakingToken.decimals,
                  )

                  syncedSubscribePeriod = {
                    ...subscribePeriod,
                    maxTotalStake,
                    numTrackStakers: numTrackStakers ? parseInt(numTrackStakers, 10) : 0,
                    totalStaked,
                  }
                }

                if ('saleAddress' in sale && sale.saleAddress !== '') {
                  const { chainId, saleChainId = chainId, saleAddress } = sale

                  const purchaserCount = keyedResult['purchaserCount']?.[sale.id]
                  const maxTotalPurchasable = keyedResult['maxTotalPurchasable']?.[sale.id]
                  const totalPaymentReceivedInWei = keyedResult['totalPaymentReceived']?.[sale.id]
                  const publicAllocation = keyedResult['publicAllocation']?.[sale.id]
                  const buybackClaimableNumber = keyedResult['buybackClaimableNumber']?.[sale.id]
                  const purchaserCountNum = parseInt(purchaserCount, 10)
                  const isPurchaseHalted = keyedResult['isPurchaseHalted']?.[sale.id]

                  if (isCliffVestableIDO(sale as CliffVestableIDO)) {
                    try {
                      const [, , , , , , , , , , , , rawCliffPeriod, ,] = await fetchAllocationSaleData(
                        saleAddress,
                        saleChainId,
                      )

                      // HOTFIX for brikken sale
                      const isBrikken = sale.token.symbol === 'BKN'
                      const cliffPeriod = rawCliffPeriod?.[0].map(([claimTime, pct], index) => ({
                        claimTime:
                          index === 0 && isBrikken
                            ? syncedCliffVestInfo.cliffPeriod[0].claimTime
                            : new Date(parseInt(claimTime) * 1000),
                        pct,
                      }))

                      syncedCliffVestInfo = {
                        ...syncedCliffVestInfo,
                        cliffPeriod,
                      }
                    } catch {
                      syncedCliffVestInfo = {
                        ...syncedCliffVestInfo,
                        cliffPeriod: [],
                      }
                    }
                  }

                  if (isPurchaseableIDO(sale as PurchasableIDO)) {
                    const { paymentToken, purchasePeriod } = sale as PurchasableIDO
                    const { salePrice } = purchasePeriod

                    const buybackClaimableNumberNum = parseInt(buybackClaimableNumber, 10)

                    const totalPaymentReceived = new BigNumber(totalPaymentReceivedInWei)
                      .div(new BigNumber(10).pow(paymentToken.decimals || 18))
                      .toNumber()

                    const maxTotalPurchasableNum = new BigNumber(maxTotalPurchasable)
                      .div(salePrice)
                      .div(new BigNumber(10).pow(paymentToken.decimals || 18))
                      .toNumber()

                    syncedPurchasePeriod = {
                      ...syncedPurchasePeriod,
                      totalPaymentReceived,
                      purchaserCount: purchaserCountNum,
                      buybackClaimableNumber: buybackClaimableNumberNum,
                      maxTotalPurchasable: maxTotalPurchasableNum,
                      publicAllocation: publicAllocation,
                      isPurchaseHalted: isPurchaseHalted,
                    }
                  }

                  if (isClaimableIDO(sale as ClaimableIDO)) {
                    syncedClaimPeriod = {
                      ...syncedClaimPeriod,
                      purchaserCount: purchaserCountNum,
                    }
                  }
                }

                // Update these values to modify state
                // syncedSubscribePeriod = {
                //   ...syncedSubscribePeriod,
                //   startTime: new Date(new Date().getTime() - 1100 * 1000).toISOString(),
                //   endTime: new Date(new Date().getTime() - 1000 * 1000).toISOString(),
                // }

                // syncedPurchasePeriod = {
                //   ...syncedPurchasePeriod,
                //   startTime: new Date(new Date().getTime() - 1000 * 1000).toISOString(),
                //   endTime: new Date(new Date().getTime() + 1000 * 1000).toISOString(),
                // }

                // syncedClaimPeriod = {
                //   ...syncedClaimPeriod,
                //   startTime: new Date(new Date().getTime() + 800 * 1000).toISOString(),
                // }

                return {
                  ...sale,
                  token: {
                    ...token,
                    coinGeckoId: project.coinGeckoId,
                  },
                  saleAmount: syncedSaleAmount,
                  saleTokenAllocationOverride: syncedAllocationOverride,
                  purchasePeriod: syncedPurchasePeriod,
                  subscribePeriod: syncedSubscribePeriod,
                  claimPeriod: syncedClaimPeriod,
                  cliffVestInfo: syncedCliffVestInfo,
                }
              }),
            )
          : []

      return {
        ...project,
        sales: syncedSalesProject
          .filter((v) => v.status === 'fulfilled')
          .map((v) => (v as PromiseFulfilledResult<FreeStakeDropConfig | PrivateIDOConfig | AuctionIDOConfig>).value),
      }
    }),
  )

  return data.filter((v) => v.status === 'fulfilled').map((v) => (v as PromiseFulfilledResult<Project>).value)
}

export default fetchIdos
