import React, { useContext } from 'react'
import styled from 'styled-components'
// @ts-ignore
import { Player } from '@lottiefiles/react-lottie-player'
import Loading from './loading.json'
import LoadingRonin from './loadingRonin.json'
import LoadingAethir from './loadingAethir.json'

import LoadingBEAM from './loadingBEAM.json'
import LoadingSophon from './loadingSophon.json'
import LoadingCarv from './loadingCarv.json'
import LoadingCampus from './loadingCampus.json'
import LoadingMoonveil from './loadingMoonveil.json'
import LoadingOg from './loadingOg.json'
import { SpinnerProps } from './types'
import { Brands, CompanyContext } from 'contexts/CompanyContext'

const Container = styled.div`
  position: relative;
`

const Spinner: React.FC<SpinnerProps> = ({ size = 128 }) => {
  const { brand } = useContext(CompanyContext)
  if (brand === Brands.AETHIR) {
    return (
      <Container>
        <Player src={LoadingAethir} autoplay loop style={{ width: size, height: size }} />
      </Container>
    )
  }
  if (brand === Brands.RONIN) {
    return (
      <Container>
        <Player src={LoadingRonin} autoplay loop style={{ width: size, height: size }} />
      </Container>
    )
  }

  if (brand === Brands.BEAM) {
    return (
      <Container>
        <Player src={LoadingBEAM} autoplay loop style={{ width: size, height: size }} />
      </Container>
    )
  }
  if (brand === Brands.SOPHON) {
    return (
      <Container>
        <Player src={LoadingSophon} autoplay loop style={{ width: size, height: size }} />
      </Container>
    )
  }
  if (brand === Brands.CARV) {
    return (
      <Container>
        <Player src={LoadingCarv} autoplay loop style={{ width: size, height: size }} />
      </Container>
    )
  }
  if (brand === Brands.CAMPUS) {
    return (
      <Container>
        <Player src={LoadingCampus} autoplay loop style={{ width: size, height: size }} />
      </Container>
    )
  }
  if (brand === Brands.MOONVEIL) {
    return (
      <Container>
        <Player src={LoadingMoonveil} autoplay loop style={{ width: size, height: size }} />
      </Container>
    )
  }
  if (brand === Brands.OG) {
    return (
      <Container>
        <Player src={LoadingOg} autoplay loop style={{ width: size, height: size }} />
      </Container>
    )
  }
  return (
    <Container>
      <Player src={Loading} autoplay loop style={{ width: size, height: size }} />
    </Container>
  )
}

export default Spinner
