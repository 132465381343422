import { useIFTieredSaleContract } from 'hooks/useContract'
import { isAddress } from 'utils'
import CONTRACTS from 'config/constants/contracts'
import { useContext } from 'react'
import { CompanyContext } from 'contexts/CompanyContext'
import { useChainId } from 'wagmi'

export const useFetchPromoCode = (contractAddress: string) => {
  const IFTieredSaleContract = useIFTieredSaleContract(contractAddress)
  const { brand } = useContext(CompanyContext)
  const chainId = useChainId()

  const supportsWalletRef = async (tierId: string) => {
    const res = await IFTieredSaleContract.methods.tiers(tierId).call()

    if (!res[7]) {
      return false
    } else {
      return true
    }
  }

  const fetchPromo = async (code: string) => {
    const walletDiscountRate =
      CONTRACTS.IFTieredSale[brand]?.contracts?.[chainId]?.[contractAddress]?.defaultWalletCodeDiscountRate || 5

    if (isAddress(code)) {
      const isValidPromo = await IFTieredSaleContract.methods.validateWalletPromoCode(code).call()

      if (!isValidPromo) {
        return false
      }

      //functions reads discount rates from res[0]
      return [walletDiscountRate]
    } else {
      const res = await IFTieredSaleContract.methods.promoCodes(code.toLowerCase()).call()

      if (res.promoCodeOwnerAddress === '0x0000000000000000000000000000000000000000') {
        return false
      }

      return res
    }
  }

  return { fetchPromo, supportsWalletRef }
}
